import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { clientRoutineTemplateRoutes } from './routine-templates/routine-template.routes';

export const clientTrainingRoutes: Route[] =
[
	{
		path: 'routine-templates',
		canActivate: [AuthGuard],
		children:
		[
			...clientRoutineTemplateRoutes
		],
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
