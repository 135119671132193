import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { MemberTrainingHistoryDetailComponent } from './detail/detail.component';
import { MemberTrainingHistoryComponent } from './history.component';

export const memberTrainingHistoryRoutes: Route[] =
[
	{
		path: '',
		canActivate: [AuthGuard],
		component: MemberTrainingHistoryComponent,
	},
	{
		path: 'detail/:workoutId',
		canActivate: [AuthGuard],
		component: MemberTrainingHistoryDetailComponent,
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
