import { Location } from '@angular/common';
import { Component, computed, input, OnChanges, signal, ViewEncapsulation, inject, output } from '@angular/core';

import { OrganizationRoutineResistanceExerciseSetResponseModel } from '@fitness-central/api/organization/training/routine-resistance-exercise-set/routine-resistance-exercise-set.response-model';
import { StatusType } from '@fitness-central/shared/enum/status-type.enum';
import { FuseVerticalNavigationComponent } from '@abp/components/navigation';
import { FuseNavigationItem } from '@abp/components/navigation/navigation.types';

@Component({
	selector: 'training-employee-routine-exercise-detail-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['navigation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	imports: [FuseVerticalNavigationComponent]
})

export class TrainingEmployeeExerciseDetailNavigationComponent implements OnChanges
{
	private _location = inject(Location);

	public allExerciseSets = input<OrganizationRoutineResistanceExerciseSetResponseModel[]>();
	public canEdit = input<boolean>(true);
	public canAdd = input<boolean>(true);

	readonly onCreateExerciseSet = output();
	readonly onEditExercise = output();
	readonly onShowActiveExerciseSets = output();
	readonly onShowInactiveExerciseSets = output();

	public menuData: FuseNavigationItem[];

	public selectedFilter = signal<StatusType>(StatusType.Active);

	private activeExerciseSetCount = computed(() => this.allExerciseSets().filter(exercise => exercise.isActive).length);
	private inactiveExerciseSetCount = computed(() => this.allExerciseSets().filter(exercise => !exercise.isActive).length);

	public ngOnChanges(): void
	{
		this.buildNavigation();
	}

	private buildNavigation(): void
	{
		this.menuData =
			[
				{
					title: 'Exercises',
					type: 'basic',
					icon: 'arrow_back',
					function: () =>
					{
						this._location.back();
					}
				}
			];

		this.addActions();
		this.addNavigationFilters();
	}

	private addActions(): void
	{
		const actionItems: FuseNavigationItem[] = [];

		if (this.canEdit())
		{
			actionItems.push
			(
				{
					title: 'Edit exercise detail',
					type: 'basic',
					icon: 'edit',
					function: () =>
					{
						this.onEditExercise.emit();
					}
				}
			);
		}

		if (this.canAdd())
		{
			actionItems.push
			(
				{
					title: 'Add set',
					type: 'basic',
					icon: 'add_circle',
					function: () =>
					{
						this.onCreateExerciseSet.emit();
					}
				}
			);
		}

		if (actionItems.length)
		{
			const actions: FuseNavigationItem =
			{
				title: 'Actions',
				type: 'group',
				children: []
			}

			actions.children.push(...actionItems);

			this.menuData.push(actions);
		}
	}


	private addNavigationFilters(): void
	{
		const navigationFilters: FuseNavigationItem =
		{
			title: 'Filters',
			type: 'group',
			children:
				[
					{
						id: '1',
						title: 'Active sets',
						type: 'basic',
						icon: 'check_circle',
						badge:
						{
							title: this.activeExerciseSetCount().toString(),
							classes: 'px-2 bg-primary text-on-primary rounded-full'
						},
						function: () =>
						{
							this.selectedFilter.set(StatusType.Active);
							this.buildNavigation();
							this.onShowActiveExerciseSets.emit();
						},
						active: this.selectedFilter() === StatusType.Active
					},
					{
						id: '2',
						title: 'Inactive sets',
						type: 'basic',
						icon: 'cancel',
						badge:
						{
							title: this.inactiveExerciseSetCount().toString(),
							classes: 'px-2 bg-primary text-on-primary rounded-full'
						},
						function: () =>
						{
							this.selectedFilter.set(StatusType.Inactive);
							this.buildNavigation();
							this.onShowInactiveExerciseSets.emit();
						},
						active: this.selectedFilter() === StatusType.Inactive
					}
				]
		};

		this.menuData.push(navigationFilters);
	}
}
