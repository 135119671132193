import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

// helper
import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { PostListResponse } from './post-list.response-model';
import { PostRequest } from './post.request-model';
import { PostResponse } from './post.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class PostService
{
	private http = inject(HttpClient);


	public create(request: PostRequest) : Observable<PostResponse>
	{
		const url = `${ environment.baseUrl  }/community/post/profile/${ request.profileId }`

		return this.http.post<PostResponse>(url, request);
	}

	public getAll(profileId: string, lastPostId: string, numberOfPosts: number) : Observable<PostListResponse>
	{
		const url = `${ environment.baseUrl  }/community/posts/profile/${ profileId }?lastPostId=${ lastPostId }&numberOfPosts=${ numberOfPosts }`;

		return this.http.get<PostListResponse>(url);
	}

	public remove(postId: string) : Observable<PostResponse>
	{
		const url = `${ environment.baseUrl  }/community/post/${ postId }`

		return this.http.delete<PostResponse>(url);
	}
}
