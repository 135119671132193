
import { Component, OnDestroy, OnInit, inject, input } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { MatButton } from '@angular/material/button';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';

import { ImageResponse } from '@fitness-central/api/community/image/image.response-model';
import { ProfileImageService } from '@fitness-central/api/community/image/image.service';
import { ProfileResponseModel } from '@fitness-central/api/community/profile/profile.response-model';
import { ProfileService } from '@fitness-central/api/community/profile/profile.service';

import { Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

import { ProfileFollowingViewModel } from './following.view-model';

@Component
(
	{
		selector: 'profile-following',
		templateUrl: './following.component.html',
		imports: [
			MatGridListModule,
			DefaultProfileImageComponent,
			MatTooltipModule,
			MatButton,
			RouterModule
		]
	}
)

export class ProfileFollowingComponent implements OnInit, OnDestroy
{
	private _profileService = inject(ProfileService);
	private _profileImageService = inject(ProfileImageService);
	private _router = inject(Router);

	public readonly profileId = input<string>(undefined);

	public profiles: ProfileResponseModel[] = [];
	public profileImages: ImageResponse[] = [];

	public followingProfiles: ProfileFollowingViewModel[] = [];

	private defaultGuid: string = '00000000-0000-0000-0000-000000000000';

	public isLoading: boolean = true;

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit()
	{
		this.getFollowedProfiles();
	}

	public routeToProfile(selectedIndex: number)
	{
		const handle = this.followingProfiles[selectedIndex].handle;
		this._router.navigateByUrl(`/community/profile/public/${ handle }`);
	}

	private getFollowedProfiles()
	{
		this._profileService
			.getFollowed(this.profileId(), this.defaultGuid, 16)
			.pipe
			(
				mergeMap
				(
					(profileList) =>
					{
						this.profiles = profileList.profiles;

						return profileList.profiles;
					}
				)
			)
			.subscribe
			(
				{
					next: (profile) =>
					{
						this.getProfileImage(profile);
					},
					complete: () =>
					{
						this.isLoading = false;
					}
				}
			)
	}

	private getProfileImage(profile: ProfileResponseModel)
	{
		this._profileImageService
			.getByProfileId(profile.profileId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (profileImage) =>
					{
						const followedProfile = new ProfileFollowingViewModel();

						followedProfile.profileId = profile.profileId;
						followedProfile.displayName = profile.displayName;
						followedProfile.handle = profile.handle;
						followedProfile.sasUri = profileImage.sasUri;

						this.followingProfiles.push(followedProfile);
					}
				}
			)
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
