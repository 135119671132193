<div class="card-container" cdkDropListGroup [ngStyle]="{ '--dragHandleTop': dragHandleTop(), '--dragHandleLeft': dragHandleLeft() }">
	<div cdkDropList (cdkDropListEntered)="onDropListEntered($event)" (cdkDropListDropped)="onDropListDropped()"></div>

	@for (card of cards(); track card) {
		<div cdkDropList (cdkDropListEntered)="onDropListEntered($event)" (cdkDropListDropped)="onDropListDropped()">
			@if (isDragDropEnabled()) {
				<div cdkDrag class="card-box" [ngStyle]="{ '--dragHandleTop': dragHandleTop(), '--dragHandleLeft': dragHandleLeft() }">
					<mat-icon cdkDragHandle>drag_indicator</mat-icon>
					<ng-container *ngTemplateOutlet="cardTemplate(); context: {$implicit: card}"></ng-container>
				</div>
			}
			@if (!isDragDropEnabled()) {
				<div class="card-box">
					<mat-icon></mat-icon>
					<ng-container *ngTemplateOutlet="cardTemplate(); context: {$implicit: card}"></ng-container>
				</div>
			}
		</div>
	}
</div>
