<div class="w-screen sm:w-90">
	<fuse-card class="flex flex-col -ml-3 mr-9 min-h-20">
		<ng-container>
			<div class="flex flex-1 h-full z-1">
				<div class="flex flex-col bg-primary p-4"></div>
				<div class="flex flex-col justify-between w-full">
					<div class="flex flex-row w-full">
						<div class="flex flex-col w-full">
							<div class="flex flex-row w-full">
								<div class="flex flex-col pl-2 p-4 w-1/8 items-center">
									<div class="flex flex-row">
										@if (routine().routineTypeId === 1) {
											<img class="w-12 h-12 rounded-full" src="/images/training/routine/strength training.svg" alt="Card cover image" />
										}
										@if (routine().routineTypeId === 2) {
											<img class="w-12 h-12 rounded-full" src="/images/training/routine/cardio.png" alt="Card cover image" />
										}
									</div>
								</div>

								<div class="flex flex-col w-full justify-center items-start">
									<div class="flex flex-row font-medium break-words">
										<span class="font-semibold text-left">
											{{ routine().name }}
										</span>
									</div>
									<div class="flex flex-row">
										<div class="flex flex-col sm:flex-row sm:items-center -ml-1.5 mt-2 sm:mt-1 space-y-1 sm:space-y-0 sm:space-x-3">
											<ng-container>
												<div class="flex items-center">
													<div class="ml-1.5 text-md text-secondary">{{ routine().version }}</div>
												</div>
											</ng-container>
										</div>
									</div>
								</div>

								<div class="flex flex-col justify-center">
									<div class="flex flex-row justify-end pr-2 mt-1">
										@if (showActionMenuButton) {
											<button mat-icon-button (click)="toggleActionBarVisibility()">
												<mat-icon class="text-gray-300"> more_vert </mat-icon>
											</button>
										}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="flex flex-row w-full h-full">
						<div class="hidden sm:flex sm:flex-col w-full min-h-20">
							<div class="flex flex-row">
								<hr class="w-11/12 border-t mx-4 my-2 place-items-center" />
							</div>
							<div class="flex flex-row">
								@if (routine().description.length) {
									<div class="text-md leading-relaxed p-2 text-left">
										{{ routine().description | truncate:90:'...' }}
									</div>
								} @else {
									<div class="text-md italic leading-relaxed p-2 text-left">No description...</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			@if (isActionBarVisible()) {
				<div class="flex flex-row w-full">
					@if (routine().isActive) {
						<div class="flex flex-col text-md font-medium bg-accent text-on-accent w-full">
							<div class="flex flex-row justify-between">
								<div class="flex flex-col">
									<div class="flex flex-row items-end">
										@if (canStartWorkout()) {
											<button mat-icon-button class="accent-fg mx-2 my-1" matTooltip="Start Routine" (click)="start()">
												<mat-icon class="text-green-400"> play_circle </mat-icon>
											</button>
										}
									</div>
								</div>
								<div class="flex flex-col">
									<div class="flex flex-row">
										<button mat-icon-button class="accent-fg mx-2 my-1" matTooltip="Routine Detail" (click)="select()">
											<mat-icon class="text-blue-400"> library_books </mat-icon>
										</button>
										@if (canEdit()) {
											<button mat-icon-button class="accent-fg mx-2 my-1" matTooltip="Edit Routine" (click)="edit()">
												<mat-icon class="text-blue-400"> edit </mat-icon>
											</button>
										}
										@if (canChangeStatus()) {
											<button mat-icon-button class="accent-fg mx-2 my-1" matTooltip="Inactivate Routine">
												<mat-icon class="text-red-400" (click)="inactivate()"> cancel </mat-icon>
											</button>
										}
									</div>
								</div>
							</div>
						</div>
					} @else {
						<div class="flex flex-col items text-md font-medium bg-accent text-on-accent w-full">
							<div class="flex flex-row justify-between">
								<div class="flex flex-col">
									<div class="flex flex-row items-end"></div>
								</div>
								<div class="flex flex-col">
									<div class="flex flex-row">
										@if (canChangeStatus()) {
											<button mat-icon-button class="accent-fg mx-2 my-1" matTooltip="Reactivate Routine" (click)="reactivate()">
												<mat-icon class="text-green-400">check_circle</mat-icon>
											</button>
										}
									</div>
								</div>
							</div>
						</div>
					}
				</div>
			}
		</ng-container>
	</fuse-card>
</div>
