import { Location } from '@angular/common';
import { Component, effect, inject, OnDestroy, OnInit, Signal, viewChild, ViewEncapsulation } from '@angular/core';

import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { ClientDetailNavigationMenuItemType } from 'app/employee/clients/detail/_components/navigation/models/menu-item-types.enum';
import { ClientDetailMessageService } from 'app/employee/clients/detail/_message-services/detail.message-service';

import { ClientBodyWeightResponseModel } from '@fitness-central/api/client/body-composition/body-weight/body-weight-response.model';
import { ClientBodyWeightService } from '@fitness-central/api/client/body-composition/body-weight/body-weight.service';
import { ClientResponseModel } from '@fitness-central/api/client/client/client-response.model';
import { ContentHeaderComponent } from '@fitness-central/shared/components/ui/content-header/content-header.component';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ClientDetailBodyCompositionBodyWeightSidebarCreateComponent } from '../_components/sidebars/body-weight/create/create.component';
import { ClientDetailBodyCompositionBodyWeightSidebarDeleteComponent } from '../_components/sidebars/body-weight/delete/delete.component';
import { ClientDetailBodyCompositionBodyWeightSidebarEditComponent } from '../_components/sidebars/body-weight/edit/edit.component';
import { ClientDetailBodyCompositionMessageService } from '../body-composition.message-service';
import { ClientDetailBodyCompositionBodyWeightCardComponent } from './_components/card/card.component';
import { ClientDetailBodyCompositionBodyWeightNavigationComponent } from './_components/navigation/navigation.component';

@Component({
	selector: 'client-detail-body-composition-body-weight',
	templateUrl: './body-weight.component.html',
	encapsulation: ViewEncapsulation.None,
	imports: [
		SidebarViewComponent,
		ContentHeaderComponent,
		ClientDetailBodyCompositionBodyWeightNavigationComponent,
		ClientDetailBodyCompositionBodyWeightSidebarCreateComponent,
		ClientDetailBodyCompositionBodyWeightSidebarEditComponent,
		ClientDetailBodyCompositionBodyWeightSidebarDeleteComponent,
		ClientDetailBodyCompositionBodyWeightCardComponent
	]
})
export class ClientDetailBodyCompositionBodyWeightComponent implements OnInit, OnDestroy
{
	private _bodyWeightMeasurementService = inject(ClientBodyWeightService);
	private _bodyCompositionMessageService = inject(ClientDetailBodyCompositionMessageService);
	private readonly _clientDetailMessageService = inject(ClientDetailMessageService);
	private readonly _location = inject(Location);

	public sidebarView = viewChild(SidebarViewComponent);

	public client: Signal<ClientResponseModel>;

	public isLoading: boolean = true;

	public selectedBodyWeight: ClientBodyWeightResponseModel = new ClientBodyWeightResponseModel();

	public bodyWeightMeasurements: ClientBodyWeightResponseModel[] = [];

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public constructor
	()
	{
		this._clientDetailMessageService.selectedNavigationItemType.set(ClientDetailNavigationMenuItemType.BodyComposition);
		this.client = this._clientDetailMessageService.client;

		effect(() => this.getBodyWeightMeasurements(this._bodyCompositionMessageService.dateFilterStartDate(), this._bodyCompositionMessageService.dateFilterEndDate()));
	}

	public ngOnInit(): void
	{
		this.subscribeToNavigationChange();
	}

	public routeToBack()
	{
		this._location.back();
	}

	public create()
	{
		this.sidebarView().openCreateSidebar();
	}

	public edit(bodyWeightMeasurement: ClientBodyWeightResponseModel)
	{
		this.selectedBodyWeight = bodyWeightMeasurement;
		this.sidebarView().openEditSidebar();
	}

	public delete(bodyWeightMeasurement: ClientBodyWeightResponseModel)
	{
		this.selectedBodyWeight = bodyWeightMeasurement;
		this.sidebarView().openDeleteSidebar();
	}

	public cancel()
	{
		this.sidebarView().closeSidebar();
	}

	public toggleNavigation(isOpen: boolean)
	{
		if (isOpen)
		{
			this.sidebarView().openNavigation();
		}
		else
		{
			this.sidebarView().closeNavigation();
		}
	}

	public created(createdBodyWeight: ClientBodyWeightResponseModel)
	{
		this.bodyWeightMeasurements.unshift(createdBodyWeight);

		this.bodyWeightMeasurements = this.bodyWeightMeasurements.sort((a, b) => 0 - (a.measurementDate < b.measurementDate ? -1 : 1));

		this.sidebarView().closeSidebar();
	}

	public updated(updatedBodyWeight: ClientBodyWeightResponseModel)
	{
		const bodyWeightMeasurementIndex = this.bodyWeightMeasurements.findIndex(bodyWeightMeasurement => bodyWeightMeasurement.bodyWeightMeasurementId === updatedBodyWeight.bodyWeightMeasurementId);
		this.bodyWeightMeasurements.splice(bodyWeightMeasurementIndex, 1, updatedBodyWeight);

		this.sidebarView().closeSidebar();
	}

	public deleted(deletedBodyWeight: ClientBodyWeightResponseModel)
	{
		const bodyWeightMeasurementIndex = this.bodyWeightMeasurements.findIndex(bodyWeightMeasurement => bodyWeightMeasurement.bodyWeightMeasurementId === deletedBodyWeight.bodyWeightMeasurementId);
		this.bodyWeightMeasurements.splice(bodyWeightMeasurementIndex, 1);

		this.sidebarView().closeSidebar();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private subscribeToNavigationChange()
	{
		this._clientDetailMessageService
			.navigationOpenAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						this.sidebarView().toggleNavigation();
					}
				}
			)
	}

	private getBodyWeightMeasurements(startDate: Date, endDate: Date)
	{
		this._bodyWeightMeasurementService
			.getByClientId(this.client().clientId, startDate, endDate)
			.pipe
			(
				takeUntil(this._unsubscribeAll)
			)
			.subscribe
			(
				{
					next: (bodyWeightMeasurementValues) =>
					{
						this.bodyWeightMeasurements = bodyWeightMeasurementValues.sort((a, b) => 0 - (a.measurementDate < b.measurementDate ? -1 : 1));

						this.isLoading = false;
					}
				}
			);
	}
}
