import { Subject, takeUntil } from 'rxjs';

import { FuseCardComponent } from '@abp/components/card';

import { Component, inject, input, OnInit, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ClientContactResponseModel } from '@fitness-central/api/client/contact/contact-response.model';

import { ContactCardMessageService } from './_message_services/contact-card.message-service';

@Component({
	selector: 'client-detail-home-contact-card',
	templateUrl: './contact-card.component.html',
	imports: [FuseCardComponent, MatButtonModule, MatIconModule]
})
export class ClientDetailHomeContactCardComponent implements OnInit
{
	private readonly _contactCardMessageService = inject(ContactCardMessageService);

	readonly clientContact = input<ClientContactResponseModel>(undefined);
	readonly onEdit = output<ClientContactResponseModel>();
	readonly onDelete = output<ClientContactResponseModel>();

	public isActionBarVisible: boolean = false;

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit()
	{
		this.subscribeToTrainingWorkoutManualMessageService();
	}

	public editClientContact()
	{
		this.isActionBarVisible = false;
		this.onEdit.emit(this.clientContact());
	}

	public deleteClientContact()
	{
		this.isActionBarVisible = false;
		this.onDelete.emit(this.clientContact());
	}

	public toggleActionBarVisibility()
	{
		this.isActionBarVisible = !this.isActionBarVisible;
		this._contactCardMessageService.displayContactCardActionBar(this.clientContact().contactId);
	}

	private subscribeToTrainingWorkoutManualMessageService()
	{
		this._contactCardMessageService
			.displayContactCardActionBarAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (clientContactId) =>
					{
						if (this.clientContact().contactId != clientContactId)
						{
							this.isActionBarVisible = false;
						}
					}
				}
			)
	}
}
