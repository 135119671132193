@if (isLoading()) {
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
} @else {
	<ui-sidebar [title]="'Edit Routine Template'" [isFormValid]="editForm.valid" [isFormDisabled]="editForm.disabled" (onCancel)="cancel()" (onSave)="update()">
		<ng-container content>
			<div class="relative flex flex-col flex-auto items-center">
				<div class="w-full max-w-3xl">
					<form [formGroup]="editForm">
						<div class="flex flex-col space-y-4 mt-8 px-6">
							<div>
								<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
									<mat-label>Routine Name</mat-label>
									<input matInput [formControlName]="'name'" [placeholder]="'The Name of your Routine'" [spellcheck]="false" />
								</mat-form-field>
								@if (editForm.get('name').invalid && editForm.get('name').touched) {
									<div class="mb-3 mt-[-20px] pl-3 text-sm">
										@if (editForm.get('name')?.hasError('required')) {
											<mat-error> Routine Name is required </mat-error>
										}
									</div>
								}
							</div>
							<div>
								<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
									<mat-label>Version</mat-label>
									<input matInput [formControlName]="'version'" [placeholder]="'The Version of the Routine'" [spellcheck]="false" />
								</mat-form-field>
							</div>
							<div>
								<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
									<mat-label>Description</mat-label>
									<input matInput [formControlName]="'description'" [placeholder]="'The Description of the Routine'" [spellcheck]="false" />
								</mat-form-field>
							</div>
						</div>
					</form>
				</div>
			</div>
		</ng-container>
	</ui-sidebar>
}
