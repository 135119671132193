import { Subject } from 'rxjs';

import { FuseLoadingBarComponent } from '@abp/components/loading-bar';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component
(
	{
		selector: 'empty-layout',
		templateUrl: './empty.component.html',
		encapsulation: ViewEncapsulation.None,
		imports: [FuseLoadingBarComponent, RouterOutlet]
	}
)

export class EmptyLayoutComponent implements OnDestroy
{
	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
