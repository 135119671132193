import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const clientBodyMeasurementRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'biceps'
	},
	{
		path: 'biceps',
		canActivate: [AuthGuard],
		loadComponent: () => import('./biceps/biceps.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementBicepsComponent),
	},
	{
		path: 'butt',
		canActivate: [AuthGuard],
		loadComponent: () => import('./butt/butt.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementButtComponent),
	},
	{
		path: 'calves',
		canActivate: [AuthGuard],
		loadComponent: () => import('./calves/calves.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementCalvesComponent),
	},
	{
		path: 'chest',
		canActivate: [AuthGuard],
		loadComponent: () => import('./chest/chest.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementChestComponent),
	},
	{
		path: 'forearms',
		canActivate: [AuthGuard],
		loadComponent: () => import('./forearms/forearms.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementForearmsComponent),
	},
	{
		path: 'hips',
		canActivate: [AuthGuard],
		loadComponent: () => import('./hips/hips.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementHipComponent),
	},
	{
		path: 'neck',
		canActivate: [AuthGuard],
		loadComponent: () => import('./neck/neck.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementNeckComponent),
	},
	{
		path: 'shoulders',
		canActivate: [AuthGuard],
		loadComponent: () => import('./shoulders/shoulders.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementShoulderComponent),
	},
	{
		path: 'thighs',
		canActivate: [AuthGuard],
		loadComponent: () => import('./thighs/thighs.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementThighsComponent),
	},
	{
		path: 'waist',
		canActivate: [AuthGuard],
		loadComponent: () => import('./waist/waist.component').then(c => c.ClientDetailBodyCompositionBodyMeasurementWaistComponent),
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
