import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { ProfilePrivateComponent } from './private.component';

export const profilePrivateRoutes: Route[] =
[
	{
		path: '',
		canActivate: [AuthGuard],
		component: ProfilePrivateComponent,
	},
	{
		path: 'following',
		canActivate: [AuthGuard],
		loadComponent: () => import('./profiles/following/following.component').then(c => c.ProfilePrivateFollowingComponent),
	},
	{
		path: 'followers',
		canActivate: [AuthGuard],
		loadComponent: () => import('./profiles/follower/follower.component').then(c => c.ProfilePrivateFollowerComponent),
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
