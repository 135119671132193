import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { AfterViewInit, Component, ElementRef, inject, input, OnDestroy, OnInit, output, SecurityContext, viewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';
import { PostCommentResponse } from '@fitness-central/api/community/post-comment/post-comment.response-model';
import { PostCommentService } from '@fitness-central/api/community/post-comment/post-comment.service';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';

@Component
(
	{
		selector: 'profile-post-comment-edit',
		templateUrl: './post-comment-edit.component.html',
		imports: [
			DefaultProfileImageComponent,
			FormsModule,
			ReactiveFormsModule,
			MatFormFieldModule,
			MatInputModule,
			TextFieldModule,
			MatButtonModule,
			MatIconModule
		]
	}
)

export class CommunityPostCommentEditComponent implements OnInit, OnDestroy, AfterViewInit
{
	private _sessionStorageHelper = inject(SessionStorageHelper);
	private _formBuilder = inject(FormBuilder);
	private _sanitizer = inject(DomSanitizer);
	private _postCommentService = inject(PostCommentService);

	readonly comment = viewChild<ElementRef>('comment');

	readonly postId = input<string>(undefined);
	readonly postCommentParentId = input<string>('00000000-0000-0000-0000-000000000000');

	readonly postCommentOutput = output<PostCommentResponse>();

	public profileImageUrl: string;
	public displayName: string;
	public isSubmitVisible: boolean = false;

	public postCommentForm: FormGroup;

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit()
	{
		this.buildPostCommentForm();
		this.profileImageUrl = this._sessionStorageHelper.profileImageUrl();
		this.displayName = this._sessionStorageHelper.displayName();
	}

	public ngAfterViewInit(): void
	{
		this.checkInput();
	}

	public checkInput()
	{
		const commentBlur: Observable<Event> = fromEvent<Event>(this.comment().nativeElement, 'blur');

		merge(this.postCommentForm.valueChanges, commentBlur)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				() =>
				{
					if (this.postCommentForm.controls['comment'].value)
					{
						this.isSubmitVisible = true;
					}
					else
					{
						this.isSubmitVisible = false;
					}
				}
			);
	}

	public CreatePostComment()
	{
		this.postCommentForm.value['comment'] = this._sanitizer.sanitize(SecurityContext.HTML, this.postCommentForm.value['comment']);

		this._postCommentService
			.create(this.postCommentForm.value)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (postComment) =>
					{
						this.postCommentOutput.emit(postComment);

						this.buildPostCommentForm();
						this.checkInput();
						this.comment().nativeElement.blur();
					}
				}
			)
	}

	private buildPostCommentForm()
	{
		this.postCommentForm = this._formBuilder.group
		(
			{
				postId: [this.postId(), [Validators.required]],
				postCommentParentId: [this.postCommentParentId(), [Validators.required]],
				isActive: [true, [Validators.required]],
				comment: ['']
			}
		);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}