<div class="absolute inset-0 z-9999 sm:relative">
	<div class="flex flex-row justify-between px-6 bg-primary">
		<div class="flex flex-col pt-2 text-xl text-on-primary justify-center">Edit Client Details</div>
		<div class="flex flex-col justify-center">
			<button (click)="cancel()" class="bg-accent text-on-primary my-2" mat-icon-button>
				<mat-icon class="text-on-accent">close</mat-icon>
			</button>
		</div>
	</div>

	<div class="relative flex flex-col flex-auto items-center">
		<div class="w-full max-w-3xl">
			<form [formGroup]="clientForm">
				@if (showAlert) {
					<fuse-alert class="pb-8" [appearance]="'outline'" [showIcon]="false" [type]="alertClient.type" [@shake]="alertClient.type === 'error'">
						{{ alertClient.message }}
					</fuse-alert>
				}
				<div class="flex flex-col space-y-4 mt-8 px-6">
					<div>
						<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full" focused="false">
							<mat-label>First Name</mat-label>
							<input matInput formControlName="firstName" required />
							<mat-error>First Name is required!</mat-error>
						</mat-form-field>
					</div>
					<div>
						<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
							<mat-label>Last Name</mat-label>
							<input matInput formControlName="lastName" required />
							<mat-error>Last Name is required!</mat-error>
						</mat-form-field>
					</div>
					<div>
						<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
							<mat-label>Gender</mat-label>
							<mat-select formControlName="genderId" required>
								<mat-option value="0" disabled>Choose a Gender</mat-option>
								@for (gender of genders; track gender) {
									<mat-option [value]="gender.genderTypeId">{{ gender.name }}</mat-option>
								}
							</mat-select>
							<mat-error>Gender is required!</mat-error>
						</mat-form-field>
					</div>

					<div>
						<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
							<mat-label>Date Of Birth</mat-label>
							<input matInput formControlName="dateOfBirth" [matDatepicker]="dateOfBirthCreate" placeholder="Choose a date of birth" />
							<mat-datepicker-toggle matSuffix [for]="dateOfBirthCreate"></mat-datepicker-toggle>
							<mat-datepicker #dateOfBirthCreate [startAt]="dateOfBirthCreate" disabled="false"></mat-datepicker>
							<mat-error>Date of Birth is required!</mat-error>
						</mat-form-field>
					</div>

					<div>
						<mat-form-field appearance="outline" class="abp-mat-no-subscript w-full">
							<mat-label>Description</mat-label>
							<input matInput formControlName="description" />
						</mat-form-field>
					</div>

					<div>
						<mat-checkbox formControlName="isActive"> Is Active </mat-checkbox>
					</div>
				</div>

				<!-- Actions -->
				<div class="flex items-center w-full mt-10 px-6 py-4 border-t bg-gray-50">
					<button class="ml-auto" mat-button [matTooltip]="'Cancel'" (click)="cancel()">Cancel</button>
					<button class="ml-2" mat-flat-button [color]="'primary'" [disabled]="clientForm.invalid" [matTooltip]="'Save'" (click)="update()">Save</button>
				</div>
			</form>
		</div>
	</div>
</div>
