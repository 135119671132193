import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';

import { ImageResponse } from '@fitness-central/api/community/image/image.response-model';
import { ProfileImageService } from '@fitness-central/api/community/image/image.service';
import { ProfileResponseModel } from '@fitness-central/api/community/profile/profile.response-model';
import { ProfileService } from '@fitness-central/api/community/profile/profile.service';

import { Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';

import { ProfileFollowerViewModel } from './follower.view-model';
import { MatButton } from '@angular/material/button';

@Component
(
	{
		selector: 'profile-follower',
		templateUrl: './follower.component.html',
		standalone: true,
		imports:
		[
			NgIf,
			MatGridListModule,
			NgFor,
			DefaultProfileImageComponent,
			MatTooltipModule,
			MatButton,
			RouterModule
		]
	}
)

export class ProfileFollowerComponent implements OnInit, OnDestroy
{
	@Input() public profileId: string;

	public profiles: ProfileResponseModel[] = [];
	public profileImages: ImageResponse[] = [];

	public profileFollowers: ProfileFollowerViewModel[] = [];

	public isLoading = true;

	private defaultGuid: string = "00000000-0000-0000-0000-000000000000";

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
	(
		private _profileService: ProfileService,
		private _profileImageService: ProfileImageService,
		private _router: Router
	)
	{

	}

	public ngOnInit()
	{
		this.getProfileFollowers();
	}

	public routeToProfile(selectedIndex: number)
	{
		let handle = this.profileFollowers[selectedIndex].handle;
		this._router.navigateByUrl(`/community/profile/public/${handle}`);
	}

	private getProfileFollowers()
	{
		this._profileService
			.getFollowers(this.profileId, this.defaultGuid, 16)
			.pipe
			(
				mergeMap
				(
					profileList =>
					{
						this.profiles = profileList.profiles;

						return profileList.profiles;
					}
				)
			)
			.subscribe
			(
				{
					next: profile =>
					{
						this.getProfileImage(profile);
					},
					complete: () =>
					{
						this.isLoading = false;
					}
				}
			)
	}

	private getProfileImage(profile: ProfileResponseModel)
	{
		this._profileImageService
			.getByProfileId(profile.profileId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: profileImage =>
					{
						let communtiyProfileFollower = new ProfileFollowerViewModel();

						communtiyProfileFollower.profileId = profile.profileId;
						communtiyProfileFollower.displayName = profile.displayName;
						communtiyProfileFollower.handle = profile.handle;
						communtiyProfileFollower.sasUri = profileImage.sasUri;

						this.profileFollowers.push(communtiyProfileFollower);
					}
				}
			)
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
