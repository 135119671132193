import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { PostCommentListResponse } from './post-comment-list.response-model';
import { PostCommentRequest } from './post-comment.request-model';
import { PostCommentResponse } from './post-comment.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class PostCommentService
{
	private http = inject(HttpClient);


	public create(request: PostCommentRequest) : Observable<PostCommentResponse>
	{
		const url = `${ environment.baseUrl  }/community/post-comment/post/${ request.postId }`;

		return this.http.post<PostCommentResponse>(url, request);
	}

	public getAll(postId: string, postCommentParentId: string,  lastPostCommentId: string, numberOfComments: number) : Observable<PostCommentListResponse>
	{
		const url = `${ environment.baseUrl  }/community/post-comments/post/${ postId }?lastPostCommentId=${ lastPostCommentId }&postCommentParentId=${ postCommentParentId }&numberOfComments=${ numberOfComments }`;

		return this.http.get<PostCommentListResponse>(url);
	}
}
