import { Location } from '@angular/common';
import { Component, input, OnInit, signal, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DragDropCardContainerComponent } from '@abp/components/drag-drop-card-container/drag-drop-card-container.component';
import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { Guid } from '@abp/guid';
import { EnumDescriptionPipe } from '@abp/pipes/enum/enum-description.pipe';

import { ClientRoutineResistanceExerciseSetService } from '@fitness-central/api/client/training/routine-resistance-exercise-set/routine-resistance-exercise-set.service';
import { ClientRoutineResistanceExerciseService } from '@fitness-central/api/client/training/routine-resistance-exercise/routine-resistance-exercise.service';
import { ClientRoutineService } from '@fitness-central/api/client/training/routine/routine.service';
import { MemberRoutineResistanceExerciseSetResponseModel } from '@fitness-central/api/member/training/routine-resistance-exercise-set/routine-resistance-exercise-set.response-model';
import { MemberRoutineResistanceExerciseSetService } from '@fitness-central/api/member/training/routine-resistance-exercise-set/routine-resistance-exercise-set.service';
import { MemberRoutineResistanceExerciseRequestModel } from '@fitness-central/api/member/training/routine-resistance-exercise/routine-resistance-exercise.request-model';
import { MemberRoutineResistanceExerciseService } from '@fitness-central/api/member/training/routine-resistance-exercise/routine-resistance-exercise.service';
import { MemberRoutineService } from '@fitness-central/api/member/training/routine/routine.service';
import { ResistanceExerciseResponse } from '@fitness-central/api/training/resistance-exercise/resistance-exercise.response-model';
import { ResistanceExerciseService } from '@fitness-central/api/training/resistance-exercise/resistance-exercise.service';
import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { TrainingRoutineResistanceExerciseSetCardComponent } from '@fitness-central/shared/components/training/routine-templates/cards/set/card.component';
import { TrainingRoutineResistanceExerciseEditSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/exercise/edit/edit.component';
import { TrainingRoutineResistanceExerciseSetCreateSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/set/create/create.component';
import { TrainingRoutineResistanceExerciseSetEditSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/set/edit/edit.component';
import { PageHeaderComponent } from '@fitness-central/shared/components/ui/page-header/page-header.component';
import { StatusType, StatusTypeDescriptions } from '@fitness-central/shared/enum/status-type.enum';

import { Subject, takeUntil } from 'rxjs';

import { RoutineTemplateResistanceExerciseSetViewModel } from '../../_view-models/routine-exercise-set.view-model';
import { RoutineTemplateResistanceExerciseViewModel } from '../../_view-models/routine-exercise.view-model';
import { RoutineTemplateViewModel } from '../../_view-models/routine.view-model';
import { TrainingMemberExerciseDetailNavigationComponent } from './_components/navigation/navigation.component';
import { RoutineTemplateExerciseEditSidebarActionType } from './_enums/edit-sidebar-action-type.enum';

@Component({
	selector: 'training-exercise-detail',
	templateUrl: './detail.component.html',
	standalone: true,
	imports:
		[
			DragDropCardContainerComponent,
			EnumDescriptionPipe,
			MatIconModule,
			MatButtonModule,
			MatProgressBarModule,
			MatTooltipModule,
			PageHeaderComponent,
			SidebarViewComponent,
			TrainingMemberExerciseDetailNavigationComponent,
			TrainingRoutineResistanceExerciseEditSidebarComponent,
			TrainingRoutineResistanceExerciseSetCardComponent,
			TrainingRoutineResistanceExerciseSetCreateSidebarComponent,
			TrainingRoutineResistanceExerciseSetEditSidebarComponent
		]
})

export class TrainingMemberExerciseDetailComponent implements OnInit
{
	public resistanceExerciseId = input<string>();
	public isMemberExercise = input<boolean>();

	private sidebarView = viewChild(SidebarViewComponent);

	public activeMemberId: string;

	public allExerciseSets = signal<RoutineTemplateResistanceExerciseSetViewModel[]>([]);
	public exerciseSets = signal<RoutineTemplateResistanceExerciseSetViewModel[]>([]);
	public exercises: ResistanceExerciseResponse[] = [];


	public activeExerciseSets: RoutineTemplateResistanceExerciseSetViewModel[] = [];
	public inactiveExerciseSets: RoutineTemplateResistanceExerciseSetViewModel[] = [];


	public editSidebarAction: RoutineTemplateExerciseEditSidebarActionType = RoutineTemplateExerciseEditSidebarActionType.EditResistanceExercise;
	public editSidebarActionType = RoutineTemplateExerciseEditSidebarActionType;

	public exerciseSetFilterType = signal<StatusType>(StatusType.Active);
	public statusTypeDescriptions = StatusTypeDescriptions;


	public selectedRoutine = signal<RoutineTemplateViewModel>(new RoutineTemplateViewModel());
	public selectedExercise = signal<RoutineTemplateResistanceExerciseViewModel>(new RoutineTemplateResistanceExerciseViewModel());
	public selectedExerciseSet = signal<RoutineTemplateResistanceExerciseViewModel>(undefined);

	public measurementSystem: number;

	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
		(
			private readonly _clientRoutineResistanceExerciseService: ClientRoutineResistanceExerciseService,
			private readonly _clientRoutineResistanceExerciseSetService: ClientRoutineResistanceExerciseSetService,
			private readonly _clientRoutineService: ClientRoutineService,
			private readonly _exerciseService: ResistanceExerciseService,
			private readonly _jwtHelper: JwtHelper,
			private readonly _sessionStorageHelper: SessionStorageHelper,
			private readonly _location: Location,
			private readonly _routineResistanceExerciseService: MemberRoutineResistanceExerciseService,
			private readonly _routineResistanceExerciseSetService: MemberRoutineResistanceExerciseSetService,
			private readonly _routineService: MemberRoutineService
		)
	{
	}

	public ngOnInit(): void
	{
		this.measurementSystem = this._sessionStorageHelper.measurementSystem();
		this.activeMemberId = this._jwtHelper.profileId();

		this.getExercises();

		if (this.isMemberExercise())
		{
			this.getMemberRoutineResistanceExercise();
			this.getMemberRoutineResistanceExerciseSets();
		}
		else
		{
			this.getClientRoutineResistanceExercise();
			this.getClientRoutineResistanceExerciseSets();
		}
	}

	public closeSidebar(): void
	{
		this.sidebarView().closeSidebar();
	}

	public closeNavigation(): void
	{
		this.sidebarView().closeNavigation();
	}

	public toggleNavigation(): void
	{
		this.sidebarView().toggleNavigation();
	}

	public showExerciseSetCreateSidebar(): void
	{
		this.closeNavigation();

		this.sidebarView().openCreateSidebar();
	}

	public showExerciseEditSidebar(): void
	{
		this.closeNavigation();

		this.editSidebarAction = this.editSidebarActionType.EditResistanceExercise;
		this.sidebarView().openEditSidebar();
	}

	public showExerciseSetEditSidebar(routineResistanceExerciseSet: RoutineTemplateResistanceExerciseViewModel): void
	{
		this.closeNavigation();

		this.selectedExerciseSet.set(routineResistanceExerciseSet);

		this.editSidebarAction = this.editSidebarActionType.EditResistanceExerciseSet;
		this.sidebarView().openEditSidebar();
	}

	public changeActive(): void
	{
		this.filterExerciseSets();

		this.activeExerciseSets.forEach((routineResistanceExerciseSet, index) =>
		{
			routineResistanceExerciseSet.order = index + 1;
		});

		this.inactiveExerciseSets.forEach((routineResistanceExerciseSet, index) =>
		{
			routineResistanceExerciseSet.order = index + 1;
		});

		this.updateExerciseSets();
	}

	public showActiveExerciseSets(): void
	{
		this.closeNavigation();
		this.exerciseSetFilterType.set(StatusType.Active);
		this.filterExerciseSets();
	}

	public showInactiveExerciseSets(): void
	{
		this.closeNavigation();
		this.exerciseSetFilterType.set(StatusType.Inactive);
		this.filterExerciseSets();
	}

	public routeToBack(): void
	{
		this._location.back();
	}

	public getExercises()
	{
		this._exerciseService
			.getAll()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: exercises =>
					{
						this.exercises = exercises;
					}
				}
			)
	}

	public updateExercise(resistanceExercise: ResistanceExerciseResponse): void
	{
		let routineResistanceExercise: MemberRoutineResistanceExerciseRequestModel = this.selectedExercise();
		routineResistanceExercise.resistanceExerciseId = resistanceExercise.resistanceExerciseId;

		this._routineResistanceExerciseService
			.update(routineResistanceExercise)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routineResistanceExercise =>
					{
						this.selectedExercise.update
							(
								exercise => exercise.routineResistanceExerciseId === routineResistanceExercise.routineResistanceExerciseId
									? { ...routineResistanceExercise, routineTemplateResistanceExerciseSets: exercise.routineTemplateResistanceExerciseSets }
									: exercise

							);

						this.closeSidebar();
					}
				}
			)
	}

	public createExerciseSet(exerciseSet: MemberRoutineResistanceExerciseSetResponseModel): void
	{
		exerciseSet.order = this.activeExerciseSets.length + 1;

		this._routineResistanceExerciseSetService
			.create(exerciseSet)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routineResistanceExerciseSet =>
					{
						this.allExerciseSets.update(exerciseSets => [...exerciseSets, routineResistanceExerciseSet])

						this.filterExerciseSets();
						this.closeSidebar();
					}
				}
			)
	}

	public updateExerciseSet(updatedMemberRoutineResistanceExerciseSet: MemberRoutineResistanceExerciseSetResponseModel): void
	{
		this._routineResistanceExerciseSetService
			.update(updatedMemberRoutineResistanceExerciseSet)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routineResistanceExerciseSet =>
					{
						this.allExerciseSets.update
							(
								exerciseSets => exerciseSets.map(item => item.routineResistanceExerciseSetId == routineResistanceExerciseSet.routineResistanceExerciseSetId
									? routineResistanceExerciseSet
									: item));

						this.filterExerciseSets();

						this.closeSidebar();
					}
				}
			)
	}

	public updateExerciseSets(): void
	{
		this._routineResistanceExerciseSetService
			.updateRange(this.allExerciseSets())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routineResistanceExerciseSets =>
					{
						this.allExerciseSets.set(routineResistanceExerciseSets);
						this.filterExerciseSets();
					}
				}
			)
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	private getMemberRoutineResistanceExercise()
	{
		this._routineResistanceExerciseService
			.get(this.resistanceExerciseId())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routineResistanceExercise =>
					{
						this.selectedExercise.set
							({
								...routineResistanceExercise,
								routineTemplateResistanceExerciseSets: []
							});

						this.getMemberRoutine();
						this.getMemberRoutineResistanceExerciseSets();
					}
				}
			)
	}

	private filterExerciseSets(): void
	{
		this.activeExerciseSets = this.allExerciseSets()
			.filter(routineResistanceExerciseSet => routineResistanceExerciseSet.isActive)
			.sort((first, second) => first.order - second.order);

		this.inactiveExerciseSets = this.allExerciseSets()
			.filter(routineResistanceExerciseSet => !routineResistanceExerciseSet.isActive)
			.sort((first, second) => first.order - second.order);


		if (this.exerciseSetFilterType() === StatusType.Active)
		{
			this.exerciseSets.set(this.activeExerciseSets);
		}
		else
		{
			this.exerciseSets.set(this.inactiveExerciseSets);
		}
	}

	private getMemberRoutineResistanceExerciseSets(): void
	{
		this._routineResistanceExerciseSetService
			.getByRoutineResistanceExerciseId(this.resistanceExerciseId())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routineResistanceExerciseSets =>
					{
						this.allExerciseSets.set(routineResistanceExerciseSets);
						this.filterExerciseSets();
					}
				}
			)
	}

	private getMemberRoutine(): void
	{
		this._routineService
			.get(this.selectedExercise().routineId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routine =>
					{
						this.selectedRoutine.set
							({
								...routine,
								clientId: Guid.empty,
								isMemberRoutine: true

							});

						this.isLoading.set(false);
					}
				}
			)
	}

	private getClientRoutineResistanceExercise(): void
	{
		this._clientRoutineResistanceExerciseService
			.get(this.resistanceExerciseId())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: clientRoutineResistanceExercise =>
					{
						this.selectedExercise.set
							({
								...clientRoutineResistanceExercise,
								routineTemplateResistanceExerciseSets: []
							});

						this.getClientRoutine();
						this.getClientRoutineResistanceExerciseSets();
					}
				}
			)
	}

	private getClientRoutineResistanceExerciseSets(): void
	{
		this._clientRoutineResistanceExerciseSetService
			.getByRoutineResistanceExerciseId(this.resistanceExerciseId())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: clientRoutineResistanceExerciseSets =>
					{
						this.allExerciseSets.set(clientRoutineResistanceExerciseSets);
						this.filterExerciseSets();
					}
				}
			)
	}

	private getClientRoutine(): void
	{
		this._clientRoutineService
			.get(this.selectedExercise().routineId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: clientRoutine =>
					{
						this.selectedRoutine.set
							({
								memberId: Guid.empty,
								isMemberRoutine: false,
								...clientRoutine
							});

						this.isLoading.set(false);
					}
				}
			)
	}
}
