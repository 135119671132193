<div class="w-screen sm:w-90">
	<fuse-card class="flex flex-col -ml-3 mr-9 min-h-20 sm:min-h-40">
		<ng-container>
			<div class="flex flex-1 h-full">
				<div class="flex flex-col bg-primary p-4"></div>
				<div class="flex flex-col w-full">
					<div class="flex flex-row px-2 py-4">

						<div class="flex flex-col w-full justify-center items-start">
							<div class="flex flex-row font-medium break-words">
								<span class="font-semibold text-left">
									{{ exercise().name }}
								</span>
							</div>
							<div class="flex flex-row">
								<div class="flex flex-col sm:flex-row sm:items-center -ml-1.5 mt-2 sm:mt-1 space-y-1 sm:space-y-0 sm:space-x-3">
									<ng-container>
										<div class="flex items-center">
											<div class="ml-1.5 text-md text-secondary">
												@if (exercise().routineTemplateResistanceExerciseSets.length)
												{
													<div>
														<span class="font-semibold">
															{{ exercise().routineTemplateResistanceExerciseSets.length }}
														</span>
														Exercise Sets
													</div>
												}
												@if (!exercise().routineTemplateResistanceExerciseSets.length)
												{
													<div>
														&nbsp;
													</div>
												}
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</div>

						<div class="flex flex-col justify-center">
							<div class="flex flex-row justify-end mt-1">
								@if (exercise().isActive || (canEdit() || canChangeStatus()))
								{
									<button mat-icon-button (click)="toggleActionBarVisibility()">
										<mat-icon class="text-gray-300">
											more_vert
										</mat-icon>
									</button>
								}
							</div>
						</div>
					</div>

					<div class="hidden sm:flex sm:flex-col">
						<div class="flex flex-row h-full">
							<hr class="w-11/12 border-t mx-4 my-2 place-items-center">
						</div>
						<div class="flex flex-row h-full">
							@if (exercise().description.length)
							{
								<div class="text-md leading-relaxed p-2 text-left">
									{{ exercise().description | truncate:90:'...' }}
								</div>

							}
							@else
							{
								<div class="text-md italic leading-relaxed p-2 text-left">
									No description...
								</div>
							}
						</div>
					</div>
				</div>
			</div>

			@if (isActionBarVisible())
			{
				<div class="w-full">
					@if (exercise().isActive)
					{
						<div class="flex flex-col items text-md font-medium bg-accent text-on-accent">
							<div class="flex flex-row justify-between">
								<div class="flex flex-col">
									<div class="flex flex-row items-end">
									</div>
								</div>
								<div class="flex flex-col">
									<div class="flex flex-row">
										<button mat-icon-button class="accent-fg mx-2 my-1" matTooltip="Exercise Detail" (click)="select()">
											<mat-icon class="text-blue-400">library_books</mat-icon>
										</button>
										@if (canEdit())
										{
											<button mat-icon-button class="accent-fg mx-2 my-1" matTooltip="Edit Exercise" (click)="edit()">
												<mat-icon class="text-blue-400">
													edit
												</mat-icon>
											</button>
										}
										@if (canChangeStatus())
										{
											<button mat-icon-button class="accent-fg mx-2 my-1" matTooltip="Inactivate Routine">
												<mat-icon class="text-red-400" (click)="inactivate()">
													cancel
												</mat-icon>
											</button>
										}
									</div>
								</div>
							</div>
						</div>
					}
					@else
					{
						<div class="flex flex-col items text-md font-medium bg-accent text-on-accent">
							<div class="flex flex-row justify-between">
								<div class="flex flex-col">
									<div class="flex flex-row items-end"></div>
								</div>
								<div class="flex flex-col">
									<div class="flex flex-row">
										@if (canChangeStatus())
										{
											<button mat-icon-button class="accent-fg mx-2 my-1" aa matTooltip="Reactivate Routine" (click)="reactivate()">
												<mat-icon class="text-green-400">check_circle</mat-icon>
											</button>
										}
									</div>
								</div>
							</div>
						</div>
					}
			</div>
			}
		</ng-container>
	</fuse-card>
</div>
