import { DatePipe } from '@angular/common';
import { Component, OnInit, inject, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BodyFatCalculationTypeDescription } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-calculation-type.enum';
import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';
import { FuseCardComponent } from '@abp/components/card';

import { Subject, takeUntil } from 'rxjs';

import { ClientDetailBodyCompositionBodyFatMeasurementMessageService } from '../../body-fat-measurement.message-service';

@Component({
	selector: 'client-detail-body-composition-body-weight-card',
	templateUrl: './card.component.html',
	imports: [FuseCardComponent, MatIconModule, MatButtonModule, MatTooltipModule, DatePipe]
})

export class ClientDetailBodyCompositionBodyFatMeasurementCardComponent implements OnInit
{
	private readonly _bodyCompositionBodyFatMeasurementMessageService = inject(ClientDetailBodyCompositionBodyFatMeasurementMessageService);

	readonly bodyFatMeasurement = input<ClientBodyFatMeasurementResponseModel>(undefined);

	readonly onBodyFatMeasurementEdit = output<ClientBodyFatMeasurementResponseModel>();
	readonly onBodyFatMeasurementDelete = output<ClientBodyFatMeasurementResponseModel>();

	public bodyFatCalculationTypeDescription = BodyFatCalculationTypeDescription;


	public isActionBarVisible: boolean = false;

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit()
	{
		this.subscribeToClientBodyCompositionBodyFatMeasurementDetailMessageService();
	}

	public subscribeToClientBodyCompositionBodyFatMeasurementDetailMessageService()
	{
		this._bodyCompositionBodyFatMeasurementMessageService
			.displayActionBarAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (bodyFatMeasurementId) =>
					{
						if (this.bodyFatMeasurement().bodyFatMeasurementId != bodyFatMeasurementId)
						{
							this.isActionBarVisible = false;
						}
					}
				}
			)
	}

	public toggleActionBarVisibility()
	{
		this.isActionBarVisible = !this.isActionBarVisible;
		this._bodyCompositionBodyFatMeasurementMessageService.displayActionbar(this.bodyFatMeasurement().bodyFatMeasurementId);
	}

	public editBodyFatMeasurement()
	{
		this.onBodyFatMeasurementEdit.emit(this.bodyFatMeasurement());
	}

	public deleteBodyFatMeasurement()
	{
		this.onBodyFatMeasurementDelete.emit(this.bodyFatMeasurement());
	}
}
