import { Component, OnDestroy, OnInit, signal, viewChild, ViewEncapsulation, inject, output } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SidebarComponent } from '@fitness-central/shared/components/ui/sidebar/sidebar.component';

import { Subject } from 'rxjs';

import { RoutineSidebarViewModel } from '../../view-models/routine-sidebar.view-model';

@Component
(
	{
		selector: 'training-routine-create-sidebar',
		templateUrl: './create.component.html',
		encapsulation: ViewEncapsulation.None,
		imports: [
			MatProgressBarModule,
			MatButtonModule,
			MatIconModule,
			FormsModule,
			ReactiveFormsModule,
			MatFormFieldModule,
			MatInputModule,
			MatTooltipModule,
			MatProgressSpinnerModule,
			SidebarComponent
		]
	}
)

export class TrainingRoutineCreateSidebarComponent implements OnInit, OnDestroy
{
	private _formBuilder = inject(FormBuilder);

	readonly onCreate = output<RoutineSidebarViewModel>();
	readonly onCancel = output<void>();

	public formGroupDirective = viewChild(FormGroupDirective);

	public createForm: FormGroup;

	public isLoading = signal<boolean>(false);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit()
	{
		this.buildForm();
	}

	public create()
	{
		this.createForm.disable();

		const routineSidebarViewModel: RoutineSidebarViewModel = this.createForm.value;
		this.isLoading.set(false);

		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective().resetForm();
			}
		);

		this.onCreate.emit(routineSidebarViewModel);
	}

	public cancel()
	{
		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective().resetForm();
			}
		);

		this.onCancel.emit();
	}

	private buildForm()
	{
		this.createForm = this._formBuilder.group
		(
			{
				routineId: ['00000000-0000-0000-0000-000000000000'],
				name: ['', [Validators.required, Validators.maxLength(50)]],
				version: [''],
				description: ['', [Validators.maxLength(256)]],
				routineTypeId: [1],
				isActive: true
			}
		);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
