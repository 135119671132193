
import { Component, OnChanges, OnDestroy, ViewEncapsulation, WritableSignal, inject, input, output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ClientResponseModel } from '@fitness-central/api/client/client/client-response.model';
import { ClientService } from '@fitness-central/api/client/client/client.service';
import { Gender } from '@fitness-central/api/general/gender-type/gender-type.model';
import { GenderTypeService } from '@fitness-central/api/general/gender-type/gender-type.service';
import { FuseAlertComponent, FuseAlertType } from '@abp/components/alert';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'client-detail-home-sidebar-client-edit',
	templateUrl: './client-edit.component.html',
	encapsulation: ViewEncapsulation.None,
	imports: [MatButtonModule, MatIconModule, FormsModule, ReactiveFormsModule, FuseAlertComponent, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatDatepickerModule, MatCheckboxModule, MatTooltipModule]
})

export class ClientDetailHomeSidebarClientEditComponent implements OnChanges, OnDestroy
{
	private readonly _clientService = inject(ClientService);
	private readonly _formBuilder = inject(FormBuilder);
	private readonly _genderService = inject(GenderTypeService);

	readonly client = input<WritableSignal<ClientResponseModel>>(undefined);
	readonly onUpdated = output();
	readonly onCancel = output();

	public alertClient:
		{
			type: FuseAlertType,
			message: string
		} =
			{
				type: 'success',
				message: ''
			};

	public showAlert: boolean = false;

	public clientForm: FormGroup;

	public genders: Gender[] = [];

	private _unsubscribeAll: Subject<void>  = new Subject<void>();

	public constructor()
	{
		this.getGenders();
	}

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public buildForm()
	{
		this.clientForm = this._formBuilder.group
		(
			{
				clientId: [this.client()().clientId, Validators.required],
				genderId: [this.client()().genderId, [Validators.required, Validators.pattern('^((?!0).)*$')]],
				firstName: [this.client()().firstName, Validators.required],
				lastName: [this.client()().lastName, Validators.required],
				dateOfBirth: [this.client()().dateOfBirth],
				description: [this.client()().description],
				isActive: [this.client()().isActive]
			}
		);
	}

	public update()
	{
		if (this.clientForm.valid)
		{
			this._clientService
				.update(this.clientForm.value)
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe
				(
					{
						next: (client) =>
						{
							this.client().set(client);

							this.onUpdated.emit();
						},
						error: (error) =>
						{
							// Set the alert
							this.alertClient =
							{
								type: 'error',
								message: 'There was a problem. Please try again later.'
							};

							// Show the alert
							this.showAlert = true;
							console.error(error);
						}
					}
				);
		}
	}

	public cancel()
	{
		this.buildForm();
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private getGenders()
	{
		this._genderService
			.get()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (result) =>
					{
						this.genders = result;
					},
					error: (errors) =>
					{
						console.error(errors);
					}
				}
			);
	}
}
