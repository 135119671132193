import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const trainersRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'mytrainers'
	},
	{
		path: 'mytrainers',
		canActivate: [AuthGuard],
		loadComponent: () => import('./mytrainers/mytrainers.component').then(c => c.TrainersMyTrainersComponent),
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
