<sidebar-view #sidebarView [showHeaderInContent]="true">
	<ng-container header>
		<ui-content-header [title]="'Body Fat Measurements'" [subtitle]="'Body Composition'" (onRouteToBack)="routeToBack()"> </ui-content-header>
	</ng-container>

	<ng-container navigation>
		<client-detail-body-composition-body-fat-measurement-navigation (onCreate)="create()"> </client-detail-body-composition-body-fat-measurement-navigation>
	</ng-container>

	<ng-container create-sidebar>
		<client-detail-body-composition-body-fat-measurement-sidebar-create [client]="client" (onCreated)="created($event)" (onCancel)="cancel()">
		</client-detail-body-composition-body-fat-measurement-sidebar-create>
	</ng-container>

	<ng-container edit-sidebar>
		<client-detail-body-composition-body-fat-measurement-sidebar-edit
			[client]="client"
			[bodyFatMeasurement]="selectedBodyFatMeasurement"
			(onUpdated)="updated($event)"
			(onCancel)="cancel()">
		</client-detail-body-composition-body-fat-measurement-sidebar-edit>
	</ng-container>

	<ng-container delete-sidebar>
		<client-detail-body-composition-body-fat-measurement-sidebar-delete
			[client]="client"
			[bodyFatMeasurement]="selectedBodyFatMeasurement"
			(onDeleted)="deleted($event)"
			(onCancel)="cancel()">
		</client-detail-body-composition-body-fat-measurement-sidebar-delete>
	</ng-container>

	<ng-container content>
		@if (!isLoading) {
			@if (!bodyFatMeasurements.length) {
				<div class="flex flex-col m-12 items-center">
					<div class="flex flex-row">No Body Fat Measurement entries for Date Range selected</div>
				</div>
			}
			@if (bodyFatMeasurements) {
				<div class="flex flex-col m-2">
					@for (bodyFatMeasurement of bodyFatMeasurements; track bodyFatMeasurement) {
						<client-detail-body-composition-body-weight-card
							[bodyFatMeasurement]="bodyFatMeasurement"
							(onBodyFatMeasurementEdit)="edit($event)"
							(onBodyFatMeasurementDelete)="delete($event)"
							(onCancel)="cancel()">
						</client-detail-body-composition-body-weight-card>
					}
				</div>
			}
		}
	</ng-container>
</sidebar-view>
