
import { Component, computed, EventEmitter, input, OnInit, Output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Guid } from '@abp/guid';
import { TruncateStringPipe } from '@abp/pipes/string/truncate-string';

import { ProfileType } from '@fitness-central/api/community/profile/enum/profile-type-enum';
import { FuseCardComponent } from '@abp/components/card';

import { RoutineCardViewModel } from '../view-models/routine.view-model';
import { TrainingRoutineMessageService } from './message-service';

@Component
(
	{
		selector: 'training-routine-template-card',
		templateUrl: './card.component.html',
		standalone: true,
		imports:
		[
			FuseCardComponent,
			MatButtonModule,
			MatIconModule,
			MatTooltipModule,
			TruncateStringPipe
		]
	}
)

export class TrainingRoutineTemplateCardComponent implements OnInit
{
	public routine = input<RoutineCardViewModel>();
	public canChangeStatus = input<boolean>(true);
	public canEdit = input<boolean>(true);
	public canStartWorkout = input<boolean>(true);

	@Output() onActiveChanged = new EventEmitter<RoutineCardViewModel>();
	@Output() onEdit = new EventEmitter<RoutineCardViewModel>();
	@Output() onSelect = new EventEmitter<RoutineCardViewModel>();
	@Output() onStart = new EventEmitter<RoutineCardViewModel>();

	public profileType: typeof ProfileType = ProfileType

	public showActionMenuButton = signal<boolean>(true);

	public isActionBarVisible = computed(() => this._trainingRoutineMessageService.activeId() == this.routine().routineId)

	public constructor
	(
		private readonly _trainingRoutineMessageService: TrainingRoutineMessageService
	)
	{
	}

	public ngOnInit()
	{
		if (!this.canEdit() && !this.canChangeStatus() && !this.routine().isActive)
		{
			this.showActionMenuButton.set(false);
		}
	}

	public select()
	{
		this.toggleActionBarVisibility();
		this.onSelect.emit(this.routine());
	}

	public start()
	{
		this.toggleActionBarVisibility();
		this.onStart.emit(this.routine());
	}

	public edit()
	{
		this.toggleActionBarVisibility();
		this.onEdit.emit(this.routine());
	}

	public reactivate()
	{
		this.toggleActionBarVisibility();

		let routine = this.routine();

		routine.order = 0;
		routine.isActive = true;

		this.onActiveChanged.emit(routine);
	}

	public inactivate()
	{
		this.toggleActionBarVisibility();

		let routine = this.routine();

		routine.order = 0;
		routine.isActive = false;

		this.onActiveChanged.emit(routine);
	}

	public toggleActionBarVisibility()
	{
		if (this.isActionBarVisible())
		{
			this._trainingRoutineMessageService.activeId.set(Guid.empty);
		}
		else
		{
			this._trainingRoutineMessageService.activeId.set(this.routine().routineId);
		}
	}
}
