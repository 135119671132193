import { MessageViewModel } from 'app/communication/message-center/_view-models/message.view-model';

import { FuseCardComponent } from '@abp/components/card';

import { Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component
(
	{
		selector: 'message-list-card',
		templateUrl: './list-card.component.html',
		imports: [
			FuseCardComponent,
			MatIconModule,
			MatTooltipModule,
			MatButtonModule
		]
	}
)

export class MessageListCardComponent
{
	readonly message = input<MessageViewModel>(undefined);

	readonly onMessageSelected = output<MessageViewModel>();
	readonly onMessageDeleted = output<MessageViewModel>();

	public isActionBarVisible: boolean = false;

	public toggleActionBarVisibility()
	{
		this.isActionBarVisible = !this.isActionBarVisible;
	}

	public selectMessage()
	{
		this.onMessageSelected.emit(this.message());
	}

	public deleteMessage()
	{
		this.onMessageDeleted.emit(this.message());
	}
}