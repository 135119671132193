import { Component, OnDestroy, OnInit, WritableSignal, inject, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { UserType } from '@fitness-central/shared/enum/user-type.enum';

import { Subject } from 'rxjs';

import { GlobalUserSidebarListCardComponent } from './_components/list-card/list-card.component';

@Component
(
	{
		selector: 'layout-user-sidebar',
		templateUrl: './user-sidebar.component.html',
		imports: [
			MatButtonModule,
			MatIconModule,
			GlobalUserSidebarListCardComponent
		]
	}
)

export class LayoutUserSidebarComponent implements OnInit, OnDestroy
{
	private readonly _jwtHelper = inject(JwtHelper);
	private readonly _sessionStorageHelper = inject(SessionStorageHelper);
	private readonly _router = inject(Router);

	readonly onCancel = output();

	public userName: string = '';
	public userType: UserType = UserType.Member;
	public firstName: WritableSignal<string>;
	public lastName: WritableSignal<string>;
	public displayName: WritableSignal<string>;
	public profileImageUrl: WritableSignal<string>;
	public handle: string = '';
	public isNew: boolean = false;

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public constructor
	()
	{
		this.displayName = this._sessionStorageHelper.displayName;
		this.firstName = this._sessionStorageHelper.firstName;
		this.lastName = this._sessionStorageHelper.lastName;
		this.profileImageUrl = this._sessionStorageHelper.profileImageUrl;
	}

	public ngOnInit()
	{
		this.userName = this._jwtHelper.userName();
		this.userType = this._jwtHelper.userType();
		this.handle = this._jwtHelper.handle();
		this.isNew = this._jwtHelper.isNew();
	}

	public cancel()
	{
		this.onCancel.emit();
	}

	public routeToSettings()
	{
		this.onCancel.emit();

		switch (this.userType)
		{
		case UserType.Member:
		{
			if (this.isNew)
			{
				this._router.navigate(['/member/settings/welcome']);
			}
			else
			{
				this._router.navigate(['/member/settings/account']);
			}

			break;
		}
		case UserType.Employee:
		{
			if (this.isNew)
			{
				this._router.navigate(['/employee/settings/welcome']);
			}
			else
			{
				this._router.navigate(['/employee/settings/account']);
			}

			break;
		}
		}
	}

	public signOut()
	{
		this._router.navigate(['/authentication/sign-out']);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
