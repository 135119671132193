import { Location } from '@angular/common';
import { Component, computed, input, OnChanges, signal, ViewEncapsulation, inject, output } from '@angular/core';

import { StatusType } from '@fitness-central/shared/enum/status-type.enum';
import { FuseVerticalNavigationComponent } from '@abp/components/navigation';
import { FuseNavigationItem } from '@abp/components/navigation/navigation.types';

import { RoutineTemplateResistanceExerciseViewModel } from '../../../../_view-models/routine-exercise.view-model';
import { RoutineTemplateViewModel } from '../../../../_view-models/routine.view-model';

@Component({
	selector: 'training-routine-detail-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['navigation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	imports: [FuseVerticalNavigationComponent]
})

export class MemberTrainingRoutineDetailNavigationComponent implements OnChanges
{
	private readonly _location = inject(Location);

	public routine = input<RoutineTemplateViewModel>();
	public allExercises = input<RoutineTemplateResistanceExerciseViewModel[]>([]);
	public canEdit = input<boolean>(true);
	public canAdd = input<boolean>(true);

	private activeExerciseCount = computed(() => this.allExercises().filter(exercise => exercise.isActive).length);
	private inactiveExerciseCount = computed(() => this.allExercises().filter(exercise => !exercise.isActive).length);

	readonly onStartWorkout = output();
	readonly onAddExercise = output();
	readonly onEditRoutine = output();
	readonly onShowActiveResistanceExercises = output();
	readonly onShowInactiveResistanceExercises = output();

	public menuData: FuseNavigationItem[];

	public selectedFilter = signal<StatusType>(StatusType.Active);

	public ngOnChanges(): void
	{
		this.buildNavigation();
	}

	private buildNavigation(): void
	{
		this.menuData =
			[
				{
					title: 'Routines',
					type: 'basic',
					icon: 'arrow_back',
					function: () =>
					{
						this._location.back();
					}
				}
			];

		this.addActions();
		this.addNavigationFilters();
	}

	private addActions(): void
	{
		const actions: FuseNavigationItem =
		{
			title: 'Actions',
			type: 'group',
			children: []
		};

		const actionItems: FuseNavigationItem[] = [];

		if (this.canEdit())
		{
			actionItems.push
			(
				{
					title: 'Edit routine detail',
					type: 'basic',
					icon: 'edit',
					function: () =>
					{
						this.onEditRoutine.emit();
					}
				}
			)
		};

		if (this.canAdd())
		{
			actionItems.push
			(
				{
					title: 'Add exercise',
					type: 'basic',
					icon: 'add_circle',
					function: () =>
					{
						this.onAddExercise.emit();
					}
				}
			)
		};

		actionItems.push
		(
			{
				title: 'Start routine',
				type: 'basic',
				icon: 'play_circle',
				function: () =>
				{
					this.onStartWorkout.emit();
				}
			}
		);

		actions.children.push(...actionItems);

		this.menuData.push(actions);
	}

	private addNavigationFilters(): void
	{
		const navigationFilters: FuseNavigationItem =
		{
			title: 'Filters',
			type: 'group',
			children:
				[
					{
						id: '1',
						title: 'Active exercises',
						type: 'basic',
						icon: 'check_circle',
						badge:
						{
							title: this.activeExerciseCount().toString(),
							classes: 'px-2 bg-primary text-on-primary rounded-full'
						},
						function: () =>
						{
							this.selectedFilter.set(StatusType.Active);
							this.onShowActiveResistanceExercises.emit();
							this.buildNavigation();
						},
						active: this.selectedFilter() === StatusType.Active
					},
					{
						id: '2',
						title: 'Inactive exercises',
						type: 'basic',
						icon: 'cancel',
						badge:
						{
							title: this.inactiveExerciseCount().toString(),
							classes: 'px-2 bg-primary text-on-primary rounded-full'
						},
						function: () =>
						{
							this.selectedFilter.set(StatusType.Inactive);
							this.onShowInactiveResistanceExercises.emit();
							this.buildNavigation();
						},
						active: this.selectedFilter() === StatusType.Inactive
					}
				]
		};

		this.menuData.push(navigationFilters);
	}
}
