import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';

export const JwtInterceptor: HttpInterceptorFn = (request, next) =>
{
	const router: Router = inject(Router);
	const sessionStorageHelper: SessionStorageHelper = inject(SessionStorageHelper);

	let baseHttpHeader = request.headers;
	let accessToken = sessionStorageHelper.accessToken();

	if (accessToken != '')
	{
		const authHttpHeader = baseHttpHeader.append('Authorization', 'Bearer ' + accessToken);

		request = request.clone({ headers: authHttpHeader });
	}
	else
	{
		request = request.clone({ headers: baseHttpHeader });
	}

	return next(request);
};
