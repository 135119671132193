import { Guid } from '@abp/guid';

import { TrainingStatusType } from '@fitness-central/shared/enum/training-status-type.enum';

export class WorkoutViewModel
{
	public workoutId: string = Guid.empty;
	public name: string = '';
	public version: string = '';
	public description: string = '';
	public routineTypeId: number = 0;
	public order: number = 0;
	public status: TrainingStatusType = TrainingStatusType.NotStarted;
	public isActive: boolean = true;
	public createdDate: Date = new Date();
	public modifiedDate: Date = new Date();
}
