import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BodyFatCalculationType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-calculation-type.enum';
import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';
import { ClientBodyFatSiteMeasurementType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-site-measurement/body-fat-site-measurement-type.enum';
import { GenderType } from '@fitness-central/api/general/gender-type/gender-type.enum';

import { Subject } from 'rxjs';

import { ClientBodyFatMeasurementViewModel } from '../../../../../_view-models/body-fat-measurement.view-model';

@Component({
	selector: 'client-detail-body-composition-body-fat-measurement-sidebar-delete-jackson-pollock-3-site',
	templateUrl: './jackson-pollock-3-site.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, NgSwitch, NgSwitchCase, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatButtonModule, MatTooltipModule, NgIf, MatProgressSpinnerModule]
})

export class ClientDetailBodyCompositionBodyFatMeasurementSidebarDeleteJacksonPollock3SiteComponent implements OnChanges, OnDestroy
{
	@Input() bodyFatCalculationTypeList: { [key: number]: string }
	@Input() bodyFatMeasurement: ClientBodyFatMeasurementViewModel;
	@Input() clientGender: GenderType;

	@Output() onDeleted = new EventEmitter();
	@Output() onCancel = new EventEmitter();

	@ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

	public selectedBodyFatCalculationType: BodyFatCalculationType = BodyFatCalculationType.Manual;
	public bodyFatCalculationTypes: typeof BodyFatCalculationType = BodyFatCalculationType;

	public showAlert: boolean = false;

	public bodyWeightMeasurement: ClientBodyFatMeasurementResponseModel = new ClientBodyFatMeasurementResponseModel();
	public bodyFatMeasurementForm: FormGroup;

	public genderTypes: typeof GenderType = GenderType;

	private _unsubscribeAll: Subject<any>;

	public constructor
		(
			private readonly _formBuilder: FormBuilder,
		)
	{
		this._unsubscribeAll = new Subject<any>();
	}

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public delete()
	{
		this.resetForm();
		this.onDeleted.emit(this.bodyFatMeasurement.bodyFatMeasurementId);
	}

	public cancel()
	{
		this.resetForm();
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	private buildForm()
	{
		if (this.clientGender == GenderType.Male)
		{
			let abdomen = this.bodyFatMeasurement.bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId == ClientBodyFatSiteMeasurementType.Abdomen)[0].value;
			let chest = this.bodyFatMeasurement.bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId == ClientBodyFatSiteMeasurementType.Chest)[0].value;
			let thigh = this.bodyFatMeasurement.bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId == ClientBodyFatSiteMeasurementType.Thigh)[0].value;

			this.bodyFatMeasurementForm = this._formBuilder.group
				(
					{
						bodyFatMeasurementId: [this.bodyFatMeasurement.bodyFatMeasurementId],
						abdomen: [{ value: abdomen, disabled: true }],
						chest: [{ value: chest, disabled: true }],
						thigh: [{ value: thigh, disabled: true }],
						measurementDate: [{ value: this.bodyFatMeasurement.measurementDate, disabled: true }]
					}
				);
		}

		if (this.clientGender == GenderType.Female)
		{
			let thigh = this.bodyFatMeasurement.bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId == ClientBodyFatSiteMeasurementType.Thigh)[0].value;
			let tricep = this.bodyFatMeasurement.bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId == ClientBodyFatSiteMeasurementType.Triceps)[0].value;
			let suprailiac = this.bodyFatMeasurement.bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId == ClientBodyFatSiteMeasurementType.Suprailiac)[0].value;

			this.bodyFatMeasurementForm = this._formBuilder.group
				(
					{
						bodyFatMeasurementId: [this.bodyFatMeasurement.bodyFatMeasurementId],
						thigh: [{ value: thigh, disabled: true }],
						tricep: [{ value: tricep, disabled: true }],
						suprailiac: [{ value: suprailiac, disabled: true }],
						measurementDate: [{ value: this.bodyFatMeasurement.measurementDate, disabled: true }]
					}
				);
		}
	}

	private resetForm()
	{
		setTimeout
			(
				() =>
				{
					this.buildForm();
					this.formGroupDirective.resetForm();
				}
			);
	}
}
