
import { Component, OnChanges, OnDestroy, ViewEncapsulation, inject, input, output, viewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BodyFatCalculationType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-calculation-type.enum';
import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';
import { ClientBodyFatMeasurementUpdateRequestModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-update-request.model';

import { Subject } from 'rxjs';

import { ClientBodyFatMeasurementViewModel } from '../../../../../_view-models/body-fat-measurement.view-model';
import { NumberValidators } from '@abp/validators/number-validators';

@Component({
	selector: 'client-detail-body-composition-body-fat-measurement-sidebar-edit-manual',
	templateUrl: './manual.component.html',
	encapsulation: ViewEncapsulation.None,
	imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatButtonModule, MatTooltipModule, MatProgressSpinnerModule]
})

export class ClientDetailBodyCompositionBodyFatMeasurementSidebarEditManualComponent implements OnChanges, OnDestroy
{
	private _formBuilder = inject(FormBuilder);

	readonly bodyFatMeasurement = input<ClientBodyFatMeasurementViewModel>(undefined);
	readonly bodyFatCalculationTypeList = input<Record<number, string>>(undefined);

	readonly onChangeSelectedBodyFatCalculationType = output<BodyFatCalculationType>();
	readonly onUpdated = output<ClientBodyFatMeasurementUpdateRequestModel>();
	readonly onCancel = output();

	readonly formGroupDirective = viewChild(FormGroupDirective);

	public selectedBodyFatCalculationType: BodyFatCalculationType = BodyFatCalculationType.Manual;
	public bodyFatCalculationTypes: typeof BodyFatCalculationType = BodyFatCalculationType;

	public showAlert: boolean = false;

	public bodyWeightMeasurement: ClientBodyFatMeasurementResponseModel = new ClientBodyFatMeasurementResponseModel();
	public bodyFatMeasurementForm: FormGroup;

	private _unsubscribeAll: Subject<void>  = new Subject<void>();

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public changeSelectedBodyFatCalculationType()
	{
		this.onChangeSelectedBodyFatCalculationType.emit(this.selectedBodyFatCalculationType);
	}

	public update()
	{
		let bodyFatMeasurement = new ClientBodyFatMeasurementUpdateRequestModel();

		bodyFatMeasurement = { ...this.bodyFatMeasurementForm.value };

		this.resetForm();
		this.onUpdated.emit(bodyFatMeasurement);
	}

	public cancel()
	{
		this.resetForm();
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private buildForm()
	{
		this.bodyFatMeasurementForm = this._formBuilder.group
		(
			{
				bodyFatCalculationTypeId: [BodyFatCalculationType.Manual],
				bodyFatMeasurementId: [this.bodyFatMeasurement().bodyFatMeasurementId],
				value: [this.bodyFatMeasurement().value, [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
				measurementDate: [this.bodyFatMeasurement().measurementDate]
			}
		);
	}

	private resetForm()
	{
		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective().resetForm();
			}
		);
	}
}
