<fuse-card class="flex-col min-w-80 max-w-85 justify-center">
	<div class="flex flex-col items-center text-md font-medium bg-primary text-on-primary p-4">
		<div class="flex flex-row text-lg">
			{{ resistanceExercise().name }}
		</div>
		<div class="flex flex-row text-md">
			{{ resistanceExercise().description }}
		</div>
	</div>
	<div class="p-2">
		<div class="flex-wrap sm:flex">
			@for (resistanceExerciseSet of resistanceExercise().workoutResistanceExerciseSets; track resistanceExerciseSet) {
				<div class="p-2">
					<fuse-card class="flex-col min-w-80 justify-center">
						<div class="flex flex-col items-center text-md font-medium bg-default text-on-card">
							<div class="text-md p-2">
								Set {{ resistanceExerciseSet.order }}
								@if (resistanceExerciseSet.description) {
									<span> - {{ resistanceExerciseSet.description }} </span>
								}
							</div>
						</div>

						@if (resistanceExerciseSet.repRangeStart !== 0 &&  resistanceExerciseSet.repRangeEnd !== 0) {
							<div class="flex flex-row p-1">
								<div class="flex flex-col px-2 w-1/2">Target Rep Range</div>
								<div class="flex flex-col items-start w-1/2">
									<div class="flex flex-row">{{ resistanceExerciseSet.repRangeStart }} - {{ resistanceExerciseSet.repRangeEnd }}</div>
								</div>
							</div>
						}

						<div class="flex flex-row p-1">
							<div class="flex flex-col px-2 w-1/2">Reps Performed</div>
							<div class="flex flex-col items-start w-1/2">
								<div class="flex flex-row">
									{{ resistanceExerciseSet.repsCompleted }}
								</div>
							</div>
						</div>

						@if (resistanceExerciseSet.targetWeight !== 0) {
							<div class="flex flex-row p-1">
								<div class="flex flex-col px-2 w-1/2">Target Weight</div>
								<div class="flex flex-col items-start w-1/2">
									<div class="flex flex-row">
										{{ resistanceExerciseSet.targetWeight }}
										@if (measurementSystem === 1) {
											<span class="pl-2"> Lbs</span>
										}
										@if (measurementSystem === 2) {
											<span class="pl-2"> Kg</span>
										}
									</div>
								</div>
							</div>
						}

						<div class="flex flex-row p-1">
							<div class="flex flex-col px-2 w-1/2">Weight Used</div>
							<div class="flex flex-col items-start w-1/2">
								<div class="flex flex-row">
									{{ resistanceExerciseSet.actualWeight }}
									@if (measurementSystem === 1) {
										<span class="pl-2"> Lbs</span>
									}
									@if (measurementSystem === 2) {
										<span class="pl-2"> Kg</span>
									}
								</div>
							</div>
						</div>

						<div class="flex flex-row p-1">
							<div class="flex flex-col px-2 w-1/2">Cadence</div>
							<div class="flex flex-col items-start w-1/2">
								<div class="flex flex-row">
									{{ resistanceExerciseSet.eccentricDuration }}-{{ resistanceExerciseSet.eccentricPause }}-{{ resistanceExerciseSet.concentricDuration

									}}-{{ resistanceExerciseSet.concentricPause }}
								</div>
							</div>
						</div>

						<div class="flex flex-row p-1">
							<div class="flex flex-col px-2 w-1/2">Duration</div>
							<div class="flex flex-col items-start w-1/2">
								<div class="flex flex-row">
									{{ resistanceExerciseSet.duration | elapsedTime }}
								</div>
							</div>
						</div>

						<div class="flex flex-row p-1">
							<div class="flex flex-col px-2 w-1/2">Rest</div>
							<div class="flex flex-col items-start w-1/2">
								<div class="flex flex-row">
									{{ resistanceExerciseSet.rest | elapsedTime }}
								</div>
							</div>
						</div>
					</fuse-card>
				</div>
			} @empty {
				<div class="p-2">
					<fuse-card class="flex-col min-w-80 justify-center"> No Exercise Sets </fuse-card>
				</div>
			}
		</div>
	</div>
</fuse-card>
