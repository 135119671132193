import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const ErrorInterceptor: HttpInterceptorFn = (request, next) =>
{
	const router: Router = inject(Router);

	return next(request)
		.pipe
		(
			catchError
			(
				error =>
				{
					if (error.status === 401)
					{
						router.navigate(['/authentication/sign-out']);

						return next(request);
					}
					else
					{
						console.error(error);

						return throwError(() => error);
					}
				}
			)
		);
};
