import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';
import { Guid } from '@abp/guid';
import { TimeAgoPipe } from '@abp/pipes/date-time/time-ago.pipe';
import { Component, inject, input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImageResponse } from '@fitness-central/api/community/image/image.response-model';
import { ProfileImageService } from '@fitness-central/api/community/image/image.service';
import { PostCommentResponse } from '@fitness-central/api/community/post-comment/post-comment.response-model';
import { PostCommentService } from '@fitness-central/api/community/post-comment/post-comment.service';
import { ProfileResponseModel } from '@fitness-central/api/community/profile/profile.response-model';
import { ProfileService } from '@fitness-central/api/community/profile/profile.service';

import { CommunityPostCommentEditComponent } from '../post-comment-edit/post-comment-edit.component';

@Component
(
	{
		selector: 'profile-post-comment',
		templateUrl: './post-comment.component.html',
		imports: 
		[
			DefaultProfileImageComponent, 
			CommunityPostCommentEditComponent, 
			TimeAgoPipe
		]
	}
)

export class CommunityPostCommentComponent implements OnInit, OnDestroy
{
	private _postCommentService = inject(PostCommentService);
	private _profileService = inject(ProfileService);
	private _profileImageService = inject(ProfileImageService);
	private _router = inject(Router);

	readonly postComment = input<PostCommentResponse>(undefined);

	public ownerProfile: ProfileResponseModel;
	public ownerProfileImage: ImageResponse;
	public ownerProfileImageUrl: string;

	public postComments: PostCommentResponse[] = [];
	public previousPostComments: boolean = false;

	public isReplyFormVisible: boolean = false;
	public areRepliesVisible: boolean = false;

	public isLoading: boolean = true;

	public defaultGuid: string = Guid.empty;

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit()
	{
		this.getOwnerProfile();
		this.getOwnerProfileImage();
	}

	public showReplies()
	{
		this.getPostComments(this.postComment().postCommentId, this.defaultGuid);
	}

	public hideReplies()
	{
		this.areRepliesVisible = false;
		this.isReplyFormVisible = false;
	}

	public showPreviousComments()
	{
		const lastPostCommentId = this.postComments.slice(-1)[0].postCommentId;
		this.getPostComments(this.defaultGuid, lastPostCommentId);
	}

	public showReplyForm()
	{
		this.isReplyFormVisible = true;
	}

	public onPostCommentCreated(postComment: PostCommentResponse)
	{
		this.postComments.unshift(postComment);
		this.postComment().replyCount += 1;
		this.isReplyFormVisible = false;
		this.areRepliesVisible = true;
	}

	public routeToProfile()
	{
		this._router.navigateByUrl(`/community/profile/public/${ this.ownerProfile.handle }`);
	}

	private getPostComments(postCommentParentId: string, lastPostCommentId: string)
	{
		this._postCommentService
			.getAll(this.postComment().postId, postCommentParentId, lastPostCommentId, 10)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (postCommentList) =>
					{
						if (postCommentList.postComments.length)
						{
							this.postComments = postCommentList.postComments;
							this.previousPostComments = postCommentList.previousPostComments;
						}

						this.areRepliesVisible = true;
					}
				}
			)
	}

	private getOwnerProfile()
	{
		this._profileService
			.getByProfileId(this.postComment().profileId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (profile) =>
					{
						this.ownerProfile = profile;
						this.isLoading = false;
					}
				}
			)
	}

	private getOwnerProfileImage()
	{
		this._profileImageService
			.getByProfileId(this.postComment().profileId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (profileImage) =>
					{
						if (profileImage.isPrimary)
						{
							this.ownerProfileImage = profileImage;
							this.ownerProfileImageUrl = profileImage.sasUri;
						}
					}
				}
			)
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
