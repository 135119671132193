<div class="flex flex-col h-full w-full justify-between">
	<div class="flex flex-row">
		<div class="flex flex-col w-full">
			<div class="flex flex-row justify-between px-6 bg-primary">
				<div class="flex flex-col pt-2 text-xl text-on-primary justify-center">Create Post</div>
				<div class="flex flex-col justify-center">
					<button (click)="closeSidebar()" class="bg-accent text-on-primary my-2" mat-icon-button>
						<mat-icon class="text-on-accent">close</mat-icon>
					</button>
				</div>
			</div>
			<div class="flex flex-row px-6">
				<div class="flex flex-col w-full items-start mt-8">
					<div class="flex flex-row items-center mb-6">
						<div class="h-10 w-10 text-lg mr-4">
							<default-profile-image
								[profileUrl]="postSidebarViewModel().ownerProfileImageUrl"
								[displayName]="postSidebarViewModel().ownerDisplayName"></default-profile-image>
						</div>
						<div class="flex flex-col">
							<div class="font-bold">
								{{ postSidebarViewModel().ownerDisplayName }}
							</div>
							<div>&#64;{{ postSidebarViewModel().ownerHandle }}</div>
						</div>
					</div>
					<form class="flex flex-row w-full" [formGroup]="postForm">
						<mat-form-field appearance="outline" class="abp-mat-textarea abp-mat-no-subscript w-full">
							<textarea
								matInput
								formControlName="content"
								[placeholder]="'What\'s new?'"
								[rows]="3"
								cdkTextareaAutosize
								cdkAutosizeMinRows="1"
								cdkAutosizeMaxRows="30"></textarea>
							@if (postForm.get("content").hasError("required")) {
								<mat-error>Post Text cannot be empty.</mat-error>
							}
						</mat-form-field>
					</form>
				</div>
			</div>

			<div class="flex flex-row pb-4 pt-4 px-6 h-max-scr">
				@switch (selectedPostType) {
					@case (postType.Image) {
						<div class="w-full">
							@if (!fileUrls.length) {
								<div
									abpFileDragAndDrop
									(click)="fileDrop.click()"
									(fileDropped)="onFilesDropped($event)"
									class="flex flex-col p-8 w-full rounded-md border-dashed border-2 border-gray-300 cursor-pointer bg-default">
									<input class="hidden" type="file" #fileDrop id="fileDrop" multiple (change)="onFilesSelected($event)" />
									<div class="flex flex-row justify-center">
										<div class="flex flex-col pb-2 items-center">
											<mat-icon>photo_library</mat-icon>
										</div>
									</div>
									<div class="flex flex-row justify-center text-xl font-bold">Add Photo(s)</div>
									<div class="flex flex-row justify-center text-sm font-semibold">or drag and drop</div>
								</div>
							}
							@if (fileUrls.length) {
								<div class="rounded-md w-full">
									<div class="flex flex-row-reverse p-2 w-full items-center">
										<button (click)="clearFiles()" class="p-2 bg-accent text-on-accent z-900" mat-icon-button>
											<mat-icon class="icon-size-1">close</mat-icon>
										</button>
									</div>
									<div>
										<image-grid [imageUrls]="fileUrls"></image-grid>
									</div>
								</div>
							}
						</div>
					}
				}
			</div>
		</div>
	</div>

	<div class="absolute sm:relative bottom-0 left-0 flex flex-row w-full">
		<div class="flex flex-col w-full items text-md font-medium bg-accent text-on-accent">
			<div class="flex flex-row">
				@if (postSubmitted) {
					<mat-progress-bar mode="indeterminate"></mat-progress-bar>
				}
			</div>
			<div class="flex flex-row justify-between">
				<div class="flex flex-col">
					<div class="flex flex-row">
						<button (click)="showImageContext()" class="accent-fg m-2" mat-icon-button>
							<mat-icon>photo_library</mat-icon>
							<span></span>
						</button>
					</div>
				</div>
				<div class="flex flex-col justify-center">
					@if (postSubmitted) {
						<div class="flex flex-row">
							<mat-progress-spinner class="m-2" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
						</div>
					}
					@if (!postSubmitted) {
						<div class="flex flex-row">
							<button [disabled]="postSubmitted || postForm.invalid" (click)="createPost()" class="accent-fg m-2" mat-icon-button>
								<mat-icon> send </mat-icon>
								<span></span>
							</button>
						</div>
					}
				</div>
			</div>
		</div>
	</div>
</div>
