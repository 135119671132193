import { DatePipe } from '@angular/common';
import { Component, input, OnDestroy, OnInit, signal, inject, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { WorkoutViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout.view-model';
import { RoutineType } from '@fitness-central/shared/enum/routine-type.enum';
import { TrainingStatusType } from '@fitness-central/shared/enum/training-status-type.enum';
import { FuseCardComponent } from '@abp/components/card';

import { Subject, takeUntil } from 'rxjs';

import { TrainingWorkoutHistoryMessageService } from '../../message-services/history.message-service';

@Component
(
	{
		selector: 'training-history-cards-workout',
		templateUrl: './workout.component.html',
		imports: [
			FuseCardComponent,
			MatIconModule,
			MatTooltipModule,
			MatButtonModule,
			DatePipe
		]
	}
)

export class TrainingHistoryCardComponent implements OnInit, OnDestroy
{
	private readonly _trainingWorkoutHistoryMessageService = inject(TrainingWorkoutHistoryMessageService);

	public workout = input<WorkoutViewModel>();

	readonly onContinue = output<WorkoutViewModel>();
	readonly onSelect = output<WorkoutViewModel>();

	public routineType = RoutineType;
	public trainingStatus = TrainingStatusType;
	public isActionBarVisible = signal<boolean>(false);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit()
	{
		this.subscribeToMemberTrainingWorkoutHistoryMessageService();
	}

	public subscribeToMemberTrainingWorkoutHistoryMessageService()
	{
		this._trainingWorkoutHistoryMessageService
			.displayActionBarAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (workoutId) =>
					{
						if (this.workout().workoutId != workoutId)
						{
							this.isActionBarVisible.set(false);
						}
					}
				}
			)
	}

	public toggleActionBarVisibility()
	{
		this.isActionBarVisible.set(!this.isActionBarVisible());
		this._trainingWorkoutHistoryMessageService.displayActionbar(this.workout().workoutId);
	}

	public routeToDetail(workout: WorkoutViewModel)
	{
		this.onSelect.emit(workout);
	}

	public continueWorkout(workout: WorkoutViewModel)
	{
		this.onContinue.emit(workout);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
