import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';

import { TrainingWorkoutHistoryMessageService } from '@fitness-central/shared/components/training/history/message-services/history.message-service';
import { WorkoutViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout.view-model';
import { FuseVerticalNavigationComponent } from '@abp/components/navigation';
import { FuseNavigationItem } from '@abp/components/navigation/navigation.types';

import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'client-training-history-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['navigation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports:
		[
			FuseVerticalNavigationComponent,
			FormsModule,
			ReactiveFormsModule,
			MatFormFieldModule,
			MatDatepickerModule,
			MatButtonModule
		]
})

export class ClientTrainingHistoryNavigationComponent implements OnInit, OnDestroy
{
	@Input() workouts: WorkoutViewModel[] = [];

	public menuData: FuseNavigationItem[];

	public filterForm: FormGroup;

	public todaysDate: Date = new Date(Date.now());

	public dateFilterStartDate: Date;
	public dateFilterEndDate: Date = new Date(Date.now());

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public constructor
		(
			private readonly _trainingWorkoutHistoryMessageService: TrainingWorkoutHistoryMessageService,
			private readonly _formBuilder: FormBuilder
		)
	{
	}

	public ngOnInit(): void
	{
		this.subscribeToClientTrainingWorkoutHistoryMessageService();
	}

	public changeDateFilter()
	{
		let dateFilterStartDate = this.filterForm.get('dateRangeStart').value;
		let dateFilterEndDate = this.filterForm.get('dateRangeEnd').value;

		if (dateFilterEndDate == null)
		{
			dateFilterEndDate = dateFilterStartDate;
		}

		this._trainingWorkoutHistoryMessageService.changeDateFilter([dateFilterStartDate, dateFilterEndDate]);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private buildFilterForm()
	{
		this.filterForm = this._formBuilder.group
			(
				{
					dateRangeStart: [this.dateFilterStartDate],
					dateRangeEnd: [this.dateFilterEndDate]
				}
			);
	}

	private subscribeToClientTrainingWorkoutHistoryMessageService()
	{
		this._trainingWorkoutHistoryMessageService
			.dateFilterChangedAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: dateRange =>
					{
						this.dateFilterStartDate = dateRange[0];
						this.dateFilterEndDate = dateRange[1];

						this.buildFilterForm();
						this.buildNavigation();
					}
				}
			)
	}

	private buildNavigation()
	{
		this.menuData =
			[
				{
					title: 'Filters',
					type: 'group'
				}
			];
	}
}
