import { DatePipe, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, OnInit, Signal, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { AgePipe } from '@abp/pipes/date-time/age.pipe';
import { EnumDescriptionPipe } from '@abp/pipes/enum/enum-description.pipe';

import { ClientResponseModel } from '@fitness-central/api/client/client/client-response.model';
import { ClientContactResponseModel } from '@fitness-central/api/client/contact/contact-response.model';
import { ClientContactService } from '@fitness-central/api/client/contact/contact.service';
import { GenderType, GenderTypeDescriptions } from '@fitness-central/api/general/gender-type/gender-type.enum';
import { FuseCardComponent } from '@abp/components/card';

import { Subject, takeUntil } from 'rxjs';

import { ClientDetailNavigationMenuItemType } from '../../_components/navigation/models/menu-item-types.enum';
import { ClientDetailMessageService } from '../../_message-services/detail.message-service';
import { ClientDetailHomeContactCardComponent } from './_components/contact-card/contact-card.component';
import { ClientDetailHomeNavigationComponent } from './_components/navigation/navigation.component';
import { ClientDetailHomeClientContactSidebarCreateComponent } from './_components/sidebars/client-contact-create/client-contact-create.component';
import { ClientDetailHomeClientContactSidebarEditComponent } from './_components/sidebars/client-contact-edit/client-contact-edit.component';
import { ClientDetailHomeSidebarClientEditComponent } from './_components/sidebars/client-edit/client-edit.component';

@Component({
	selector: 'client-detail-home',
	templateUrl: './home.component.html',
	standalone: true,
	imports:
		[
			SidebarViewComponent,
			ClientDetailHomeNavigationComponent,
			NgSwitch,
			NgSwitchCase,
			ClientDetailHomeSidebarClientEditComponent,
			ClientDetailHomeClientContactSidebarCreateComponent,
			ClientDetailHomeClientContactSidebarEditComponent,
			FuseCardComponent,
			NgIf,
			MatButtonModule,
			MatTooltipModule,
			MatIconModule,
			NgFor,
			ClientDetailHomeContactCardComponent,
			AgePipe,
			EnumDescriptionPipe,
			DatePipe
		]
})
export class ClientDetailHomeComponent implements OnInit
{
	public sidebarView = viewChild(SidebarViewComponent);

	public client: Signal<ClientResponseModel>;
	public clientContacts: ClientContactResponseModel[] = [];

	public selectedClientContact: ClientContactResponseModel;
	public genderType: typeof GenderType = GenderType;
	public genderTypeDescriptions = GenderTypeDescriptions;

	public sidebarAction: 'editDetail' | 'createContact' | 'editContact' | 'deleteContact' = 'editDetail';

	private _unsubscribeAll: Subject<any> = new Subject();

	constructor
		(
			private readonly _clientDetailMessageService: ClientDetailMessageService,
			private readonly _clientContactService: ClientContactService
		)
	{
		this._clientDetailMessageService.selectedNavigationItemType.set(ClientDetailNavigationMenuItemType.Home);
		this.client = this._clientDetailMessageService.client;

		this.subscribeToNavigationChange();
	}

	public ngOnInit()
	{
		this.getClientContact();
	}

	public editClientDetail()
	{
		this.sidebarAction = 'editDetail';
		this.sidebarView().openEditSidebar();
	}

	public createClientContact()
	{
		this.sidebarAction = 'createContact';
		this.sidebarView().openEditSidebar();
	}

	public editClientContact(clientContact: ClientContactResponseModel)
	{
		this.sidebarAction = 'editContact';
		this.selectedClientContact = clientContact;
		this.sidebarView().openEditSidebar();
	}

	public onClientContactAdded(clientContact: ClientContactResponseModel)
	{
		this.clientContacts.push(clientContact);
		this.sidebarView().closeSidebar();
	}

	public onClientContactUpdated(updatedClientContact: ClientContactResponseModel)
	{
		let clientContactIndex = this.clientContacts.findIndex(contact => contact.contactId == updatedClientContact.contactId);
		this.clientContacts.splice(clientContactIndex, 1, updatedClientContact);
		this.sidebarView().closeSidebar();
	}

	public closeSidebar()
	{
		this.sidebarView().closeSidebar();
	}

	public deleteClientContact(deleteClientContact: ClientContactResponseModel)
	{
		this._clientContactService
			.delete(deleteClientContact.contactId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						let clientContactIndex = this.clientContacts.findIndex(contact => contact.contactId == deleteClientContact.contactId);
						this.clientContacts.splice(clientContactIndex, 1);
					},
					error: error =>
					{
						console.error(error);
					}
				}
			);
	}

	private subscribeToNavigationChange()
	{
		this._clientDetailMessageService
			.navigationOpenAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						this.sidebarView().toggleNavigation();
					}
				}
			)
	}

	private getClientContact()
	{
		this._clientContactService
			.getByClientId(this.client().clientId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: clientContacts =>
					{
						this.clientContacts = clientContacts;
					}
				}
			);
	}
}
