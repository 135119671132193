@if (!isLoading) {
	<div>
		<mat-grid-list cols="4" rowHeight="1:1" gutterSize="10">
			@for (postImage of postImages; track postImage; let index = $index) {
				<mat-grid-tile>
					<img [src]="postImage.sasUri" class="h-full object-cover rounded-md cursor-pointer" (click)="onImageSelected(index)" />
				</mat-grid-tile>
			}
		</mat-grid-list>
		@if (!postImages.length) {
			<div class="flex flex-col items-center">
				<div class="flex flex-row">No Images in gallery.</div>
			</div>
		}
	</div>
}
