
import { Component, OnChanges, OnDestroy, ViewEncapsulation, inject, input, output, viewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BodyFatCalculationType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-calculation-type.enum';
import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';
import { ClientBodyFatMeasurementUpdateRequestModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-update-request.model';
import { ClientBodyFatSiteMeasurementRequestModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-site-measurement/body-fat-site-measurement-request.model';
import { ClientBodyFatSiteMeasurementType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-site-measurement/body-fat-site-measurement-type.enum';
import { GenderType } from '@fitness-central/api/general/gender-type/gender-type.enum';

import { Subject } from 'rxjs';

import { ClientBodyFatMeasurementViewModel } from '../../../../../_view-models/body-fat-measurement.view-model';
import { NumberValidators } from '@abp/validators/number-validators';

@Component({
	selector: 'client-detail-body-composition-body-fat-measurement-sidebar-edit-jackson-pollock-3-site',
	templateUrl: './jackson-pollock-3-site.component.html',
	encapsulation: ViewEncapsulation.None,
	imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatButtonModule, MatTooltipModule, MatProgressSpinnerModule]
})

export class ClientDetailBodyCompositionBodyFatMeasurementSidebarEditJacksonPollock3SiteComponent implements OnChanges, OnDestroy
{
	private readonly _formBuilder = inject(FormBuilder);

	readonly bodyFatCalculationTypeList = input<Record<number, string>>(undefined);
	readonly bodyFatMeasurement = input<ClientBodyFatMeasurementViewModel>(undefined);
	readonly clientGender = input<GenderType>(undefined);

	readonly onUpdated = output<ClientBodyFatMeasurementUpdateRequestModel>();
	readonly onCancel = output();

	readonly formGroupDirective = viewChild(FormGroupDirective);

	public selectedBodyFatCalculationType: BodyFatCalculationType = BodyFatCalculationType.Manual;
	public bodyFatCalculationTypes: typeof BodyFatCalculationType = BodyFatCalculationType;

	public showAlert: boolean = false;

	public bodyWeightMeasurement: ClientBodyFatMeasurementResponseModel = new ClientBodyFatMeasurementResponseModel();
	public bodyFatMeasurementForm: FormGroup;

	public genderTypes: typeof GenderType = GenderType;

	private _unsubscribeAll: Subject<void>  = new Subject<void>();

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public edit()
	{
		let bodyFatMeasurement = new ClientBodyFatMeasurementUpdateRequestModel();

		bodyFatMeasurement =
		{
			...this.bodyFatMeasurement(),
			age: 0,
			gender: this.clientGender(),
			bodyFatSiteMeasurements: []
		};

		bodyFatMeasurement.bodyFatCalculationTypeId = BodyFatCalculationType.JacksonPollock3Site;
		bodyFatMeasurement.measurementDate = this.bodyFatMeasurementForm.get('measurementDate').value;

		const clientGender = this.clientGender();
		if (clientGender === GenderType.Male)
		{
			const abdomen = new ClientBodyFatSiteMeasurementRequestModel();
			abdomen.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Abdomen;
			abdomen.value = this.bodyFatMeasurementForm.get('abdomen').value;
			bodyFatMeasurement.bodyFatSiteMeasurements.push(abdomen);

			const chest = new ClientBodyFatSiteMeasurementRequestModel();
			chest.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Chest;
			chest.value = this.bodyFatMeasurementForm.get('chest').value;
			bodyFatMeasurement.bodyFatSiteMeasurements.push(chest);

			const thigh = new ClientBodyFatSiteMeasurementRequestModel();
			thigh.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Thigh;
			thigh.value = this.bodyFatMeasurementForm.get('thigh').value;
			bodyFatMeasurement.bodyFatSiteMeasurements.push(thigh);
		}

		if (clientGender === GenderType.Female)
		{
			const thigh = new ClientBodyFatSiteMeasurementRequestModel();
			thigh.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Thigh;
			thigh.value = this.bodyFatMeasurementForm.get('thigh').value;
			bodyFatMeasurement.bodyFatSiteMeasurements.push(thigh);

			const tricep = new ClientBodyFatSiteMeasurementRequestModel();
			tricep.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Triceps;
			tricep.value = this.bodyFatMeasurementForm.get('tricep').value;
			bodyFatMeasurement.bodyFatSiteMeasurements.push(tricep);

			const suprailiac = new ClientBodyFatSiteMeasurementRequestModel();
			suprailiac.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Suprailiac;
			suprailiac.value = this.bodyFatMeasurementForm.get('suprailiac').value;
			bodyFatMeasurement.bodyFatSiteMeasurements.push(suprailiac);
		}

		this.resetForm();
		this.onUpdated.emit(bodyFatMeasurement);
	}

	public cancel()
	{
		this.resetForm();
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private buildForm()
	{
		const clientGender = this.clientGender();
		if (clientGender === GenderType.Male)
		{
			const abdomen = this.bodyFatMeasurement().bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId === ClientBodyFatSiteMeasurementType.Abdomen)[0].value;
			const chest = this.bodyFatMeasurement().bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId === ClientBodyFatSiteMeasurementType.Chest)[0].value;
			const thigh = this.bodyFatMeasurement().bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId === ClientBodyFatSiteMeasurementType.Thigh)[0].value;

			this.bodyFatMeasurementForm = this._formBuilder.group
			(
				{
					abdomen: [abdomen, [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
					chest: [chest, [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
					thigh: [thigh, [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
					measurementDate: [this.bodyFatMeasurement().measurementDate]
				}
			);
		}

		if (clientGender === GenderType.Female)
		{
			const thigh = this.bodyFatMeasurement().bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId === ClientBodyFatSiteMeasurementType.Thigh)[0].value;
			const tricep = this.bodyFatMeasurement().bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId === ClientBodyFatSiteMeasurementType.Triceps)[0].value;
			const suprailiac = this.bodyFatMeasurement().bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId === ClientBodyFatSiteMeasurementType.Suprailiac)[0].value;

			this.bodyFatMeasurementForm = this._formBuilder.group
			(
				{
					thigh: [thigh, [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
					tricep: [tricep, [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
					suprailiac: [suprailiac, [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
					measurementDate: [this.bodyFatMeasurement().measurementDate]
				}
			);
		}
	}

	private resetForm()
	{
		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective().resetForm();
			}
		);
	}
}
