import { Location } from '@angular/common';
import { Component, input, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';

import { DragDropCardContainerComponent } from '@abp/components/drag-drop-card-container/drag-drop-card-container.component';
import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { Guid } from '@abp/guid';
import { EnumDescriptionPipe } from '@abp/pipes/enum/enum-description.pipe';

import { OrganizationRoutineResistanceExerciseSetService } from '@fitness-central/api/organization/training/routine-resistance-exercise-set/routine-resistance-exercise-set.service';
import { OrganizationRoutineResistanceExerciseResponseModel } from '@fitness-central/api/organization/training/routine-resistance-exercise/routine-resistance-exercise.response-model';
import { OrganizationRoutineResistanceExerciseService } from '@fitness-central/api/organization/training/routine-resistance-exercise/routine-resistance-exercise.service';
import { OrganizationRoutineService } from '@fitness-central/api/organization/training/routine/routine.service';
import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { TrainingRoutineResistanceExerciseCardComponent } from '@fitness-central/shared/components/training/routine-templates/cards/exercise/card.component';
import { TrainingRoutineResistanceExerciseCreateSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/exercise/create/create.component';
import { TrainingRoutineResistanceExerciseEditSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/exercise/edit/edit.component';
import { TrainingRoutineEditSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/routine/edit/edit.component';
import { PageHeaderComponent } from '@fitness-central/shared/components/ui/page-header/page-header.component';
import { StatusType, StatusTypeDescriptions } from '@fitness-central/shared/enum/status-type.enum';

import { concatMap, from, map, mergeMap, Subject, takeUntil } from 'rxjs';

import { RoutineTemplateResistanceExerciseViewModel } from '../../view-models/routine-exercise.view-model';
import { RoutineTemplateViewModel } from '../../view-models/routine.view-model';
import { TrainingEmployeeRoutineDetailNavigationComponent } from './_components/navigation/navigation.component';
import { RoutineTemplateEditSidebarActionType } from './_enums/edit-sidebar-action-type.enum';

@Component({
	selector: 'training-employee-routine-detail-component',
	templateUrl: './detail.component.html',
	standalone: true,
	imports:
		[
			DragDropCardContainerComponent,
			EnumDescriptionPipe,
			MatIconModule,
			MatButtonModule,
			MatProgressBarModule,
			MatTooltipModule,
			TrainingEmployeeRoutineDetailNavigationComponent,
			PageHeaderComponent,
			SidebarViewComponent,
			TrainingRoutineEditSidebarComponent,
			TrainingRoutineResistanceExerciseCardComponent,
			TrainingRoutineResistanceExerciseCreateSidebarComponent,
			TrainingRoutineResistanceExerciseEditSidebarComponent,
		]
})

export class TrainingEmployeeRoutineDetailComponent implements OnInit, OnDestroy
{
	public routineId = input<string>();
	public sidebarView = viewChild(SidebarViewComponent);

	public routine = signal<RoutineTemplateViewModel>(new RoutineTemplateViewModel());

	public allExercises = signal<RoutineTemplateResistanceExerciseViewModel[]>([]);
	public filteredExercises = signal<RoutineTemplateResistanceExerciseViewModel[]>([]);

	public activeExercises: RoutineTemplateResistanceExerciseViewModel[] = [];
	public inactiveExercises: RoutineTemplateResistanceExerciseViewModel[] = [];

	public selectedExercise = signal<RoutineTemplateResistanceExerciseViewModel>(new RoutineTemplateResistanceExerciseViewModel());

	public exerciseFilterType = signal<StatusType>(StatusType.Active);
	public statusTypeDescriptions = StatusTypeDescriptions;

	public editSidebarAction: RoutineTemplateEditSidebarActionType;
	public editSidebarActionType = RoutineTemplateEditSidebarActionType;

	public measurementSystem: number;

	public activeEmployeeId: string = Guid.empty;

	public isEmployeeRoutine = signal<boolean>(true);
	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<any>;

	public constructor
		(
			private readonly _jwtHelper: JwtHelper,
			private readonly _sessionStorageHelper: SessionStorageHelper,
			private readonly _location: Location,
			private readonly _organizationRoutineResistanceExerciseService: OrganizationRoutineResistanceExerciseService,
			private readonly _organizationRoutineResistanceExerciseSetService: OrganizationRoutineResistanceExerciseSetService,
			private readonly _organizationRoutineService: OrganizationRoutineService,
			private readonly _router: Router
		)
	{
		this._unsubscribeAll = new Subject();
	}

	public ngOnInit(): void
	{
		this.measurementSystem = +this._sessionStorageHelper.measurementSystem();
		this.activeEmployeeId = this._jwtHelper.employeeId();

		this.getOrganizationRoutine();
	}

	public showCreateSidebar(): void
	{
		this.sidebarView().openCreateSidebar();
	}

	public showRoutineEditSidebar(): void
	{
		this.editSidebarAction = this.editSidebarActionType.EditRoutine;

		this.sidebarView().openEditSidebar();
	}

	public showExerciseEditSidebar(routineResistanceExercise: RoutineTemplateResistanceExerciseViewModel): void
	{
		this.selectedExercise.set(routineResistanceExercise);
		this.editSidebarAction = this.editSidebarActionType.EditResistanceExercise;

		this.sidebarView().openEditSidebar();
	}

	public closeSidebar(): void
	{
		this.sidebarView().closeSidebar();
	}

	public closeNavigation(): void
	{
		this.sidebarView().closeNavigation();
	}

	public toggleNavigation(): void
	{
		this.sidebarView().toggleNavigation();
	}

	public updateCardOrder(): void
	{
		this.updateRoutineResistanceExercises();
	}

	public showActiveExercises(): void
	{
		this.closeNavigation();

		this.exerciseFilterType.set(StatusType.Active);
		this.filterExercises();
	}

	public showInactiveExercises(): void
	{
		this.closeNavigation();

		this.exerciseFilterType.set(StatusType.Inactive)
		this.filterExercises();
	}

	public changeActive(): void
	{
		this.filterExercises();

		this.activeExercises.forEach((routine, index) =>
		{
			routine.order = index + 1;
		});

		this.inactiveExercises.forEach((routine, index) =>
		{
			routine.order = index + 1;
		});

		this.updateRoutineResistanceExercises();
	}

	public selectExercise(exercise: RoutineTemplateResistanceExerciseViewModel): void
	{
		this._router.navigateByUrl(`/employee/training/routine-templates/exercise/${exercise.routineResistanceExerciseId}/detail`);
	}

	public routeToBack(): void
	{
		this._location.back();
	}

	public createExercise(exercise: OrganizationRoutineResistanceExerciseResponseModel): void
	{
		exercise.order = this.activeExercises.length + 1;

		this._organizationRoutineResistanceExerciseService
			.create(exercise)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routineResistanceExercise =>
					{
						this.isLoading.set(false);

						this.closeSidebar();

						this._router.navigateByUrl(`/employee/training/routine-templates/exercise/${routineResistanceExercise.routineResistanceExerciseId}/detail`);
					}
				}
			);
	}

	public updateRoutine(routine: RoutineTemplateViewModel): void
	{
		this._organizationRoutineService
			.update(routine)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routine =>
					{
						this.routine.set
							({
								...routine,
								isEmployeeRoutine: true
							});

						this.closeSidebar();
					}
				}
			);
	}

	public updateExercise(routineResistanceExercise: OrganizationRoutineResistanceExerciseResponseModel): void
	{
		this._organizationRoutineResistanceExerciseService
			.update(routineResistanceExercise)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routineResistanceExercise =>
					{

						this.allExercises.update
							(
								exercises => exercises.map(item =>
								{
									const updatedItem = item.routineResistanceExerciseId === routineResistanceExercise.routineResistanceExerciseId
										? { ...routineResistanceExercise, routineTemplateResistanceExerciseSets: item.routineTemplateResistanceExerciseSets }
										: item;

									return updatedItem;
								})
							);

						this.filterExercises();

						this.isLoading.set(false);

						this.closeSidebar();
					}
				}
			);
	}

	public updateRoutineResistanceExercises(): void
	{
		this._organizationRoutineResistanceExerciseService
			.updateRange(this.allExercises())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routineResistanceExercises =>
					{
						this.allExercises.update
							(
								exercises => exercises.map
									(
										exercise =>
										{
											const updatedItem = routineResistanceExercises.find(updatedExercise => updatedExercise.routineResistanceExerciseId === exercise.routineResistanceExerciseId);

											return updatedItem ? { ...exercise, ...updatedItem } : exercise;
										}
									)
							);

						this.filterExercises();
					}
				}
			);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	private filterExercises(): void
	{
		this.activeExercises = this.allExercises()
			.filter(exercise => exercise.isActive)
			.sort((first, second) => first.order - second.order);

		this.inactiveExercises = this.allExercises()
			.filter(exercise => !exercise.isActive)
			.sort((first, second) => first.order - second.order);

		if (this.exerciseFilterType() === StatusType.Active)
		{
			this.filteredExercises.set(this.activeExercises);
		}
		else
		{
			this.filteredExercises.set(this.inactiveExercises);
		}
	}

	private getOrganizationRoutine(): void
	{
		this._organizationRoutineService
			.get(this.routineId())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routine =>
					{
						this.isEmployeeRoutine.set(this.activeEmployeeId == routine.employeeId);

						this.routine.set
							({
								...routine,
								isEmployeeRoutine: this.isEmployeeRoutine()
							});

						this.getOrganizationRoutineResistanceExercisesWithSets();
					}
				}
			);
	}

	private getOrganizationRoutineResistanceExercisesWithSets(): void
	{
		this.allExercises.set([]);

		this._organizationRoutineResistanceExerciseService
			.getByRoutineId(this.routineId())
			.pipe
			(
				takeUntil(this._unsubscribeAll),
				concatMap(routineResistanceExercises => from(routineResistanceExercises)),
				mergeMap
					(
						routineResistanceExercise => this._organizationRoutineResistanceExerciseSetService.getByRoutineResistanceExerciseId(routineResistanceExercise.routineResistanceExerciseId)
							.pipe
							(
								map(routineResistanceExerciseSets =>
								{
									let filteredRoutineResistanceExerciseSets = routineResistanceExerciseSets
										.filter(routineResistanceExerciseSet => routineResistanceExerciseSet.isActive)
										.sort((first, second) => first.order - second.order);

									let routineWithExercisetSet: RoutineTemplateResistanceExerciseViewModel =
									{
										...routineResistanceExercise,
										routineTemplateResistanceExerciseSets: filteredRoutineResistanceExerciseSets
									};

									return routineWithExercisetSet;
								})
							)
					)
			)
			.subscribe
			(
				{
					next: routineResistanceExerciseWithSets =>
					{
						this.allExercises.update(exercises => [...exercises, routineResistanceExerciseWithSets])
					},
					complete: () =>
					{
						this.filterExercises();
						this.isLoading.set(false);
					}
				}
			);
	}
}
