import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { TrainingEmployeeExerciseDetailComponent } from './exercise/detail/detail.component';
import { TrainingEmployeeRoutineDetailComponent } from './routine/detail/detail.component';
import { TrainingEmployeeRoutineListComponent } from './routine/list/list.component';

export const employeeRoutineTemplateRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'routine/list'
	},
	{
		path: 'routine/list',
		canActivate: [AuthGuard],
		component: TrainingEmployeeRoutineListComponent,
	},
	{
		path: 'routine/:routineId/detail',
		canActivate: [AuthGuard],
		data:
		{
			isEmployeeRoutine: true
		},
		component: TrainingEmployeeRoutineDetailComponent,
	},
	{
		path: 'trainer-routine/:routineId/detail',
		canActivate: [AuthGuard],
		data:
		{
			isEmployeeRoutine: false
		},
		component: TrainingEmployeeRoutineDetailComponent,
	},
	{
		path: 'exercise/:resistanceExerciseId/detail',
		canActivate: [AuthGuard],
		data:
		{
			isEmployeeExercise: true
		},
		component: TrainingEmployeeExerciseDetailComponent,
	},
	{
		path: 'trainer-exercise/:resistanceExerciseId/detail',
		canActivate: [AuthGuard],
		data:
		{
			isEmployeeExercise: false
		},
		component: TrainingEmployeeExerciseDetailComponent,
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
