import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { ProfilePublicComponent } from './public.component';

export const profilePublicRoutes: Route[] =
[
	{
		path: '',
		canActivate: [AuthGuard],
		component: ProfilePublicComponent,
	},
	{
		path: 'following',
		canActivate: [AuthGuard],
		loadComponent: () => import('./profiles/following/following.component').then(c => c.ProfilePublicMemberFollowingComponent),
	},
	{
		path: 'followers',
		canActivate: [AuthGuard],
		loadComponent: () => import('./profiles/follower/follower.component').then(c => c.ProfilePublicMemberFollowerComponent),
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
