
import { Subject } from 'rxjs';

import { NumberValidators } from '@abp/validators/number-validators';
import { Component, inject, input, OnChanges, OnDestroy, OnInit, output, signal, viewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { SidebarComponent } from '@fitness-central/shared/components/ui/sidebar/sidebar.component';

import { ResistanceExerciseSetSidebarViewModel } from '../../view-models/reistance-exercise-set-sidebar.view-model';

@Component
(
	{
		selector: 'training-exercise-set-create-sidebar',
		templateUrl: './create.component.html',
		imports: [
			MatProgressBarModule,
			MatButtonModule,
			MatIconModule,
			FormsModule,
			ReactiveFormsModule,
			MatFormFieldModule,
			MatInputModule,
			MatTooltipModule,
			MatProgressSpinnerModule,
			SidebarComponent
		]
	}
)

export class TrainingRoutineResistanceExerciseSetCreateSidebarComponent implements OnInit, OnChanges, OnDestroy
{
	private _formBuilder = inject(FormBuilder);
	private _sessionStorageHelper = inject(SessionStorageHelper);

	public exercise = input<ResistanceExerciseSetSidebarViewModel>();

	readonly onCreate = output<ResistanceExerciseSetSidebarViewModel>();
	readonly onCancel = output();

	public formGroupDirective = viewChild(FormGroupDirective);

	public createForm: FormGroup;

	public measurementSystem: number;

	public isLoading = signal<boolean>(false);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit(): void
	{
		this.measurementSystem = +this._sessionStorageHelper.measurementSystem();
	}

	public ngOnChanges()
	{
		this.buildForm();
	}

	public create()
	{
		this.createForm.disable();

		const resistanceExerciseSetSidebarViewModel: ResistanceExerciseSetSidebarViewModel = this.createForm.value;
		this.isLoading.set(false);

		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective().resetForm();
			}
		);

		this.onCreate.emit(resistanceExerciseSetSidebarViewModel);
	}

	public cancel()
	{
		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective().resetForm();
			}
		);

		this.onCancel.emit();
	}

	private buildForm()
	{
		this.createForm = this._formBuilder.group
		(
			{
				routineResistanceExerciseSetId: ['00000000-0000-0000-0000-000000000000'],
				routineResistanceExerciseId: [this.exercise().routineResistanceExerciseId],
				description: ['', [Validators.maxLength(256)]],
				weight: ['', [Validators.required, NumberValidators.decimal(1, 4, 0, 2)]],
				weightMeasurement: [''],
				duration: [0],
				repRangeStart: ['', [Validators.required]],
				repRangeEnd: ['', [Validators.required]],
				rest: ['', [Validators.required]],
				timeMeasurement: [this.measurementSystem],
				concentricDuration: ['1', [Validators.required]],
				concentricPause: ['0', [Validators.required]],
				eccentricDuration: ['1', [Validators.required]],
				eccentricPause: ['0', [Validators.required]],
				isActive: true
			}
		);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
