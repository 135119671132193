<div class="py-2">
	<fuse-vertical-navigation [appearance]="'classic'" [navigation]="menuData" [inner]="true" [mode]="'side'" [name]="'bodycompositon-bodyweight-navigation'" [opened]="true">
	</fuse-vertical-navigation>

	<ng-container>
		<form [formGroup]="filterForm">
			<div class="flex flex-col pl-6 pt-4 pr-4 space-y-4">
				<div class="flex flex-row">
					<mat-form-field class="w-full" appearance="outline">
						<mat-label>Date Range</mat-label>
						<mat-date-range-input [rangePicker]="picker" [max]="todaysDate">
							<input matStartDate formControlName="dateRangeStart" placeholder="Start date" />
							<input matEndDate formControlName="dateRangeEnd" placeholder="End date" />
						</mat-date-range-input>
						<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker (closed)="changeDateFilter()">
							<mat-date-range-picker-actions>
								<button mat-button matDateRangePickerCancel>Cancel</button>
								<button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
							</mat-date-range-picker-actions>
						</mat-date-range-picker>
					</mat-form-field>
				</div>
				<div class="flex flex-row justify-end pt-2">
					<button mat-flat-button class="ml-4" type="button" [color]="'primary'">Reset Filters</button>
				</div>
			</div>
		</form>
	</ng-container>
</div>
