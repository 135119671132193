<div class="py-2">
	<fuse-vertical-navigation
		[appearance]="'classic'"
		[navigation]="menuData"
		[inner]="true"
		[mode]="'side'"
		[name]="'training-routine-exercise-detail-navigation'"
		[opened]="true">
	</fuse-vertical-navigation>
</div>
