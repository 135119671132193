import { FuseCardComponent } from '@abp/components/card';
import { Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component
(
	{
		selector: 'global-user-sidebar-list-card',
		templateUrl: './list-card.component.html',
		imports: [
			FuseCardComponent,
			MatButtonModule,
			MatIconModule
		]
	}
)

export class GlobalUserSidebarListCardComponent
{
	readonly icon = input<string>(undefined);
	readonly name = input<string>(undefined);
	readonly description = input<string>(undefined);
	readonly link = input<string>(undefined);
}