import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class FuseSplashScreenService
{
	private _document = inject(DOCUMENT);
	private _router = inject(Router);

	public constructor()
	{
		// Hide it on the first NavigationEnd event
		this._router.events
			.pipe
			(
				filter((event) => event instanceof NavigationEnd),
				take(1)
			)
			.subscribe
			(
				() =>
				{
					this.hide();
				}
			);
	}

	public show(): void
	{
		this._document.body.classList.remove('fuse-splash-screen-hidden');
	}

	public hide(): void
	{
		this._document.body.classList.add('fuse-splash-screen-hidden');
	}
}
