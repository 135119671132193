@if (isLoading()) {
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

<sidebar-view #sidebarView>
	<ng-container header> </ng-container>

	<ng-container navigation>
		<client-detail-training-routine-navigation [allRoutines]="allRoutines()" (onCreateRoutine)="showCreateSidebar()" (onFilterChange)="changeRoutineFilter($event)">
		</client-detail-training-routine-navigation>
	</ng-container>

	<ng-container create-sidebar>
		<training-routine-create-sidebar (onCreate)="createRoutine($event)" (onCancel)="closeSidebar()"> </training-routine-create-sidebar>
	</ng-container>

	<ng-container edit-sidebar>
		<training-routine-edit-sidebar [routine]="selectedRoutine()" (onUpdate)="updateRoutine($event)" (onCancel)="closeSidebar()"> </training-routine-edit-sidebar>
	</ng-container>

	<ng-container content>
		@if (!isLoading()) {
			<div class="flex flex-row items-center w-full">
				<div class="w-full bg-default">
					<ui-content-header
						[title]="'Routine Templates'"
						[subtitle]="selectedRoutineFilterType | enumDescription : routineFilterTypeDescriptions"
						(onToggleNavigation)="sidebarView.toggleNavigation()"
						[hasRouteToBack]="false">
					</ui-content-header>
				</div>
			</div>
			<div>
				<div class="mb-2 overflow-x-clip">
					<ng-container>
						<drag-drop-card-container
							#dragDropCards
							[cardTemplate]="routine"
							[cards]="filteredRoutines()"
							[dragHandleTop]="'28px'"
							[dragHandleLeft]="'16px'"
							[isDragDropEnabled]="selectedRoutineFilterType() === routineFilterTypes.ActiveRoutines"
							(onCardReorder)="updateRoutines()">
							<ng-template let-card #routine>
								<training-routine-template-card
									[routine]="card"
									(onActiveChanged)="changeActive()"
									(onEdit)="showEditSidebar($event)"
									(onSelect)="selectRoutine($event)"
									(onStart)="startWorkout($event)">
								</training-routine-template-card>
							</ng-template>
						</drag-drop-card-container>
					</ng-container>

					@if (!filteredRoutines().length) {
						<div class="flex flex-col m-12 items-center">
							<div class="flex flex-row">No {{ selectedRoutineFilterType() | enumDescription:routineFilterTypeDescriptions}} Routines</div>
						</div>
					}
				</div>
			</div>
		}
	</ng-container>
</sidebar-view>
