import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';
import { ImageGridComponent } from '@abp/components/image-grid/image-grid.component';
import { TimeAgoPipe } from '@abp/pipes/date-time/time-ago.pipe';
import { FuseCardComponent } from '@abp/components/card';

import { ImageResponse } from '@fitness-central/api/community/image/image.response-model';
import { ProfileImageService } from '@fitness-central/api/community/image/image.service';
import { PostCommentResponse } from '@fitness-central/api/community/post-comment/post-comment.response-model';
import { PostCommentService } from '@fitness-central/api/community/post-comment/post-comment.service';
import { PostImageService } from '@fitness-central/api/community/post-image/post-image.service';
import { PostReactionRequest } from '@fitness-central/api/community/post-reaction/post-reaction.request-model';
import { PostReactionResponse } from '@fitness-central/api/community/post-reaction/post-reaction.response-model';
import { PostReactionService } from '@fitness-central/api/community/post-reaction/post-reaction.service';
import { PostResponse } from '@fitness-central/api/community/post/post.response-model';
import { PostService } from '@fitness-central/api/community/post/post.service';
import { ProfileResponseModel } from '@fitness-central/api/community/profile/profile.response-model';
import { ProfileService } from '@fitness-central/api/community/profile/profile.service';
import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PostReactionType } from '../../_enums/profile-reaction-type.enum';
import { CommunityPostImageDialogComponent } from '../image-dialog/image-dialog.component';
import { CommunityPostCommentEditComponent } from '../post-comment-edit/post-comment-edit.component';
import { CommunityPostCommentComponent } from '../post-comment/post-comment.component';

@Component
(
	{
		selector: 'profile-post',
		templateUrl: './post.component.html',
		styleUrls: ['./post.component.scss'],
		standalone: true,
		imports:
		[
			FuseCardComponent,
			NgIf,
			DefaultProfileImageComponent,
			MatButtonModule,
			MatMenuModule,
			MatIconModule,
			ImageGridComponent,
			MatDividerModule,
			NgSwitch,
			NgSwitchCase,
			MatTooltipModule,
			NgClass,
			CommunityPostCommentEditComponent,
			NgFor,
			CommunityPostCommentComponent,
			TimeAgoPipe
		]
	}
)

export class CommunityPostComponent implements OnInit, OnDestroy
{
	@ViewChild('comments', { static: true }) comments: FuseCardComponent;

	@Input() profileId: string;
	@Input() post: PostResponse;

	@Output() postRemovedOutput = new EventEmitter<PostResponse>();

	public ownerProfile: ProfileResponseModel = new ProfileResponseModel();
	public ownerProfileImage: ImageResponse = new ImageResponse();
	public ownerProfileImageUrl: string = '';

	public postImageIds: string[] = [];
	public postImageUrls: string[] = [];
	public postComments: PostCommentResponse[] = [];
	public previousPostComments: boolean = false;

	public postReactions: PostReactionResponse[] = [];
	public postReaction: PostReactionResponse = new PostReactionResponse();

	public motivatingReactionCount: number = 0;
	public loveReactionCount: number = 0;
	public happyReactionCount: number = 0;
	public likeReactionCount: number = 0;
	public dislikeReactionCount: number = 0;
	public sadReactionCount: number = 0;
	public angryReactionCount: number = 0;
	public demotivatingReactionCount: number = 0;

	public postCommentForm: FormGroup;

	public postReactionType: typeof PostReactionType = PostReactionType;

	public canRemovePost: boolean = false;
	public areProfileCommentsVisible: boolean = false;
	public isProfileImageLoaded: boolean = false;

	private defaultGuid: string = "00000000-0000-0000-0000-000000000000";
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
	(
		private _formBuilder: FormBuilder,
		private _jwtHelper: JwtHelper,
		private _profileService: ProfileService,
		private _profileImageService: ProfileImageService,
		private _postService: PostService,
		private _postCommentService: PostCommentService,
		private _postImageDialog: MatDialog,
		private _postImageService: PostImageService,
		private _postReactionService: PostReactionService,
		private _router: Router
	)
	{
	}

	public ngOnInit()
	{
		this.buildPostCommentForm();
		this.getOwnerProfile();
		this.getOwnerProfileImage();
		this.getPostImages();
		this.getPostReactions();
		this.setPermissions();
	}

	public onImageSelected(selectedIndex: number)
	{
		this.openPostImageDialog(selectedIndex);
	}

	public openPostImageDialog(selectedIndex: number)
	{
		const dialogRef = this._postImageDialog.open
		(
			CommunityPostImageDialogComponent,
			{
				panelClass: 'profile-post-image-dialog',
				data:
				{
					postImageIds: this.postImageIds,
					selectedIndex: selectedIndex,
					displayName: this.ownerProfile.displayName,
					postDate: this.post.createdDate,
					ownerProfileImageUrl: this.ownerProfileImage.sasUri
				},
				disableClose: true
			}
		);

		dialogRef.afterClosed().subscribe
		(
			result =>
			{
			}
		);
	}

	public onPostCommentCreated(postComment: PostCommentResponse)
	{
		this.postComments.unshift(postComment);
		this.post.commentCount += 1;
	}

	public togglePostComments()
	{
		if (!this.comments.expanded)
		{
			if (this.postComments.length == 0)
			{
				this.getPostComments(this.defaultGuid, this.defaultGuid);
			}

			this.areProfileCommentsVisible = true;
			this.comments.expanded = true;
		}
		else
		{
			this.areProfileCommentsVisible = false;
			this.comments.expanded = false;
		}
	}

	public togglePreviousPostComments()
	{
		let lastPostCommentId = this.postComments.slice(-1)[0].postCommentId;
		this.getPostComments(this.defaultGuid, lastPostCommentId);
	}

	public routeToProfile()
	{
		this._router.navigateByUrl(`/community/profile/public/${this.ownerProfile.handle}`);
	}

	public removePost()
	{
		this._postService
			.remove(this.post.postId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: post =>
					{
						this.postRemovedOutput.emit(post);
					}
				}
			)
	}

	public reactToPost(reaction: PostReactionType)
	{
		const request = new PostReactionRequest();
		request.profileId = this.profileId;
		request.postId = this.post.postId;
		request.reactionId = reaction;

		this._postReactionService
			.react(request)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: postReaction =>
					{
						this.postReaction = postReaction;
						this.postReactions.push(postReaction);

						this.updateReactionCounts();
					}
				}
			)
	}

	public removePostReaction()
	{
		const request = new PostReactionRequest();
		request.postReactionId = this.postReaction.postReactionId;
		request.postId = this.postReaction.postId;
		request.profileId = this.postReaction.profileId;
		request.reactionId = this.postReaction.reactionId;

		this._postReactionService
			.delete(request)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: postReaction =>
					{
						let deletedPostReaction = postReaction;
						let index = this.postReactions.findIndex(postReaction => postReaction.postReactionId == deletedPostReaction.postReactionId);

						this.postReaction = new PostReactionResponse();

						this.postReactions.splice(index, 1);

						this.updateReactionCounts();
					}
				}
			)
	}

	private buildPostCommentForm()
	{
		this.postCommentForm = this._formBuilder.group
		(
			{
				postId: [this.post.postId, [Validators.required]],
				postCommentParentId: [this.defaultGuid, [Validators.required]],
				profileId: [this.profileId, [Validators.required]],
				comment: ['', [Validators.required]]
			}
		);
	}

	private getOwnerProfile()
	{
		this._profileService
			.getByProfileId(this.post.ownerProfileId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: profile =>
					{
						this.ownerProfile = profile;
					}
				}
			)
	}

	private getOwnerProfileImage()
	{
		this._profileImageService
			.getByProfileId(this.post.ownerProfileId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: profileImage =>
					{
						if (profileImage.isPrimary)
						{
							this.ownerProfileImage = profileImage;
							this.ownerProfileImageUrl = profileImage.sasUri;
						}

						this.isProfileImageLoaded = true;
					}
				}
			)
	}

	private getPostImages()
	{
		const reducedImageSize = true;

		this._postImageService
			.getByPostId(this.post.postId, reducedImageSize)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: postImages =>
					{
						postImages.sort((first, second) => 0 - (first.createdDate > second.createdDate ? -1 : 1));
						postImages.forEach
						(
							postImage =>
							{
								this.postImageIds.push(postImage.postImageId);
								this.postImageUrls.push(postImage.sasUri);
							}
						)
					}
				}
			)
	}

	private getPostComments(postCommentParentId: string, lastPostCommentId: string)
	{
		this._postCommentService
			.getAll(this.post.postId, postCommentParentId, lastPostCommentId, 10)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: postCommentList =>
					{
						if (postCommentList.postComments.length)
						{
							this.postComments.push(...postCommentList.postComments);
							this.previousPostComments = postCommentList.previousPostComments;
						}
					}
				}
			)
	}

	private getPostReactions()
	{
		this._postReactionService
			.getAll(this.post.postId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: postReactions =>
					{
						this.postReactions = postReactions;
						let postReaction = postReactions.find(postReaction => postReaction.profileId == this.profileId);

						if (postReaction)
						{
							this.postReaction = postReaction;
						}

						this.updateReactionCounts();
					}
				}
			)
	}

	private updateReactionCounts()
	{
		this.motivatingReactionCount = this.postReactions.filter(postReaction => postReaction.reactionId == PostReactionType.Motivating).length;
		this.loveReactionCount = this.postReactions.filter(postReaction => postReaction.reactionId == PostReactionType.Love).length;
		this.happyReactionCount = this.postReactions.filter(postReaction => postReaction.reactionId == PostReactionType.Happy).length;
		this.likeReactionCount = this.postReactions.filter(postReaction => postReaction.reactionId == PostReactionType.Like).length;
		this.dislikeReactionCount = this.postReactions.filter(postReaction => postReaction.reactionId == PostReactionType.Dislike).length;
		this.sadReactionCount = this.postReactions.filter(postReaction => postReaction.reactionId == PostReactionType.Sad).length;
		this.angryReactionCount = this.postReactions.filter(postReaction => postReaction.reactionId == PostReactionType.Angry).length;
		this.demotivatingReactionCount = this.postReactions.filter(postReaction => postReaction.reactionId == PostReactionType.Demotivating).length;
	}

	private setPermissions()
	{
		let userProfileId = this._jwtHelper.profileId();

		if (this.post.profileId == userProfileId || this.post.ownerProfileId == userProfileId)
		{
			this.canRemovePost = true;
		}
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
