<div>
	<div class="sticky top-0 z-9999">
		<div class="flex flex-row justify-between px-6 bg-primary">
			<div class="flex flex-col text-xl text-on-primary justify-center">Messages</div>
			<div class="flex flex-col justify-center">
				<button mat-icon-button class="bg-accent text-on-primary my-2" (click)="cancel()">
					<mat-icon class="text-on-accent"> close </mat-icon>
				</button>
			</div>
		</div>
	</div>
	@if (!isLoading) {
		<div>
			<div class="flex flex-col items-start text-md font-medium bg-default text-on-default">
				<div class="flex flex-row max-w-140 items-start w-full">
					<button mat-button class="bg-default text-on-default m-2 w-full" (click)="routeToMessageCenter()">
						<div>Go to Message Center</div>
					</button>
				</div>
			</div>
			@if (!messageLists.length) {
				<div class="flex flex-col text-lg font-medium bg-default text-on-default items-center">No Messages</div>
			}
			@for (messageList of messageLists; track messageList) {
				<div>
					<div class="flex flex-col rounded-full items-center text-xl font-semibold text-on-card bg-gray-200 m-2">
						@if (messageList.messageDate === today) {
							Today -
						}
						{{ messageList.messageDate | date }}
					</div>
					@for (message of messageList.messages; track message) {
						<div class="my-4">
							<message-list-card [message]="message" (onMessageSelected)="selectMessage($event)" (onMessageDeleted)="deleteMessage($event)"></message-list-card>
						</div>
					}
				</div>
			}
		</div>
	}
</div>
