import { Subject, takeUntil } from 'rxjs';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';
import { FuseFullscreenComponent } from '@abp/components/fullscreen';
import { FuseLoadingBarComponent } from '@abp/components/loading-bar';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@abp/components/navigation';
import { FuseMediaWatcherService } from '@abp/services/media-watcher';
import { Component, inject, OnDestroy, OnInit, signal, viewChild, ViewEncapsulation, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';

import { NavigationService } from './navigation/navigation.service';
import { Navigation } from './navigation/navigation.types';
import { LayoutMessageSidebarComponent } from './sidebars/message-sidebar/message-sidebar.component';
import { LayoutUserSidebarComponent } from './sidebars/user-sidebar/user-sidebar.component';

@Component
(
	{
		selector: 'classy-layout',
		templateUrl: './classy.component.html',
		encapsulation: ViewEncapsulation.None,
		imports:
		[
			FuseLoadingBarComponent,
			FuseVerticalNavigationComponent,
			MatIconModule,
			MatButtonModule,
			MatSidenavModule,
			FuseFullscreenComponent,
			RouterOutlet,
			DefaultProfileImageComponent,
			LayoutMessageSidebarComponent,
			LayoutUserSidebarComponent
		]
	}
)

export class ClassyLayoutComponent implements OnInit, OnDestroy
{
	private readonly _navigationService = inject(NavigationService);
	private readonly _jwtHelper = inject(JwtHelper);
	private readonly _fuseMediaWatcherService = inject(FuseMediaWatcherService);
	private readonly _fuseNavigationService = inject(FuseNavigationService);
	private readonly _sessionStorageHelper = inject(SessionStorageHelper);

	public isScreenSmall: boolean = false;
	public navigation: Navigation = new Navigation();

	readonly sidebar = viewChild<MatDrawer>('sidebar');

	public displayName: WritableSignal<string>;
	public handle: string;
	public userName: string;
	public profileImageUrl: WritableSignal<string>;

	public isNew: boolean;

	public sidebarAction: 'Profile' | 'Messages' = 'Messages';
	public sidebarOpen: boolean = false;

	public unreadMessageCount: WritableSignal<number> = signal(0);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public constructor
	()
	{
		this.isNew = this._jwtHelper.isNew();
	}

	public get currentYear(): number
	{
		return new Date().getFullYear();
	}

	public ngOnInit(): void
	{
		this.navigation.default = this._navigationService.get();

		// Subscribe to media changes
		this._fuseMediaWatcherService
			.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				({ matchingAliases }) =>
				{
					// Check if the screen is small
					this.isScreenSmall = !matchingAliases.includes('md');
				}
			);

		this.displayName = this._sessionStorageHelper.displayName;
		this.handle = this._jwtHelper.handle();
		this.userName = this._jwtHelper.userName();
		this.profileImageUrl = this._sessionStorageHelper.profileImageUrl;
	}

	public toggleMessageSidebar()
	{
		const sidebar = this.sidebar();
		if (!sidebar.opened)
		{
			sidebar.open();
		}
		else if (this.sidebarAction == 'Messages')
		{
			sidebar.close();
		}

		this.sidebarAction = 'Messages';
	}

	public toggleProfileSidebar()
	{
		const sidebar = this.sidebar();
		if (!sidebar.opened)
		{
			sidebar.open();
		}
		else if (this.sidebarAction == 'Profile')
		{
			sidebar.close();
		}

		this.sidebarAction = 'Profile';
	}

	public toggleNavigation(name: string): void
	{
		// Get the navigation
		const navigation = this._fuseNavigationService
			.getComponent<FuseVerticalNavigationComponent>(name);

		if (navigation)
		{
			// Toggle the opened status
			navigation.toggle();
		}
	}

	public closeSidebar()
	{
		this.sidebar().close();
	}

	public ngOnDestroy(): void
	{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
