import { Location } from '@angular/common';
import { Component, computed, EventEmitter, input, OnChanges, Output, signal, ViewEncapsulation } from '@angular/core';

import { StatusType } from '@fitness-central/shared/enum/status-type.enum';
import { FuseVerticalNavigationComponent } from '@abp/components/navigation';
import { FuseNavigationItem } from '@abp/components/navigation/navigation.types';

import { RoutineTemplateResistanceExerciseViewModel } from '../../../../_view-models/routine-exercise.view-model';
import { RoutineTemplateViewModel } from '../../../../_view-models/routine.view-model';

@Component({
	selector: 'client-detail-training-routine-detail-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['navigation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [FuseVerticalNavigationComponent]
})

export class ClientDetailTrainingRoutineDetailNavigationComponent implements OnChanges
{
	public routine = input<RoutineTemplateViewModel>();
	public allExercises = input<RoutineTemplateResistanceExerciseViewModel[]>([]);
	public canEdit = input<boolean>(true);
	public canAdd = input<boolean>(true);

	private activeExerciseCount = computed(() => this.allExercises().filter(exercise => exercise.isActive).length);
	private inactiveExerciseCount = computed(() => this.allExercises().filter(exercise => !exercise.isActive).length);

	@Output() readonly onStartWorkout = new EventEmitter();
	@Output() readonly onAddExercise = new EventEmitter();
	@Output() readonly onEditRoutine = new EventEmitter();
	@Output() readonly onShowActiveResistanceExercises = new EventEmitter();
	@Output() readonly onShowInactiveResistanceExercises = new EventEmitter();

	public menuData: FuseNavigationItem[];

	public selectedFilter = signal<StatusType>(StatusType.Active);

	public constructor
		(
			private readonly _location: Location
		)
	{
	}

	public ngOnChanges(): void
	{
		this.buildNavigation();
	}

	private buildNavigation(): void
	{
		this.menuData =
			[
				{
					title: 'Routines',
					type: 'basic',
					icon: 'arrow_back',
					function: () =>
					{
						this._location.back();
					}
				}
			];

		this.addActions();
		this.addNavigationFilters();
	}

	private addActions(): void
	{
		let actions: FuseNavigationItem =
		{
			title: 'Actions',
			type: 'group',
			children: []
		};

		let actionItems: FuseNavigationItem[] = [];

		if (this.canEdit())
		{
			actionItems.push
				(
					{
						title: 'Edit routine detail',
						type: 'basic',
						icon: 'edit',
						function: () =>
						{
							this.onEditRoutine.emit();
						}
					}
				)
		};

		if (this.canAdd())
		{
			actionItems.push
				(
					{
						title: 'Add exercise',
						type: 'basic',
						icon: 'add_circle',
						function: () =>
						{
							this.onAddExercise.emit();
						}
					}
				)
		};

		actionItems.push
			(
				{
					title: 'Start routine',
					type: 'basic',
					icon: 'play_circle',
					function: () =>
					{
						this.onStartWorkout.emit();
					}
				}
			);

		actions.children.push(...actionItems);

		this.menuData.push(actions);
	}

	private addNavigationFilters(): void
	{
		let navigationFilters: FuseNavigationItem =
		{
			title: 'Filters',
			type: 'group',
			children:
				[
					{
						id: "1",
						title: 'Active exercises',
						type: 'basic',
						icon: 'check_circle',
						badge:
						{
							title: this.activeExerciseCount().toString(),
							classes: 'px-2 bg-primary text-on-primary rounded-full'
						},
						function: () =>
						{
							this.selectedFilter.set(StatusType.Active);
							this.onShowActiveResistanceExercises.emit();
							this.buildNavigation();
						},
						active: this.selectedFilter() === StatusType.Active
					},
					{
						id: "2",
						title: 'Inactive exercises',
						type: 'basic',
						icon: 'cancel',
						badge:
						{
							title: this.inactiveExerciseCount().toString(),
							classes: 'px-2 bg-primary text-on-primary rounded-full'
						},
						function: () =>
						{
							this.selectedFilter.set(StatusType.Inactive);
							this.onShowInactiveResistanceExercises.emit();
							this.buildNavigation();
						},
						active: this.selectedFilter() === StatusType.Inactive
					}
				]
		};

		this.menuData.push(navigationFilters);
	}
}
