import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ContactCardMessageService
{
	private displayContactCardActionBarSource = new Subject<string>();
	public displayContactCardActionBarAction$ = this.displayContactCardActionBarSource.asObservable();

	public displayContactCardActionBar(clientContactId : string)
	{
		this.displayContactCardActionBarSource.next(clientContactId);
	}
}
