import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const communicationRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'messagecenter'
	},
	{
		path: 'messagecenter',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/communication/message-center/message-center.component').then(c => c.CommunicationMessageCenterComponent),
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
