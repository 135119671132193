import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { SubscriptionStatusType } from '@fitness-central/api/billing/subscription/subscription-status-type.enum';
import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { UserType } from '@fitness-central/shared/enum/user-type.enum';

import { jwtDecode } from 'jwt-decode';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
	const router: Router = inject(Router);
	const jwtHelper: JwtHelper = inject(JwtHelper);

	const redirectUrl = state.url === '/authentication/sign-out' ? '/' : state.url;

	let isAuthorized: boolean = false;
	isAuthorized = isLoggedIn();
	isAuthorized = isVerified();

	if (isAuthorized === false)
	{
		router.navigate
		(
			['authentication/sign-in'],
			{
				queryParams:
				{
					redirectUrl
				}
			}
		);

		return false;
	}

	const isNew = jwtHelper.isNew();
	const subscriptionStatusId = jwtHelper.subscriptionStatus();

	if (isNew || (subscriptionStatusId != SubscriptionStatusType.Active && subscriptionStatusId != SubscriptionStatusType.Trialing))
	{

		redirectIfNewOrInvalidSubscription(state);

		return isAuthorized;
	}

	// Check the authentication status
	return true
};

function isLoggedIn(): boolean
{
	const sessionStorageHelper: SessionStorageHelper = inject(SessionStorageHelper);
	const accessToken = sessionStorageHelper.accessToken();

	if (accessToken === '')
	{
		return false;
	}

	return true;
}

function isVerified(): boolean
{
	const sessionStorageHelper: SessionStorageHelper = inject(SessionStorageHelper);
	const router: Router = inject(Router);
	const accessToken = sessionStorageHelper.accessToken();

	const jwtDecoded = jwtDecode(accessToken);

	if (jwtDecoded['extension_IsVerified'] === false)
	{
		router.navigateByUrl('authentication/verification');
	}

	return true;
}

function redirectIfNewOrInvalidSubscription(state: RouterStateSnapshot)
{
	const jwtHelper: JwtHelper = inject(JwtHelper);
	const router: Router = inject(Router);

	const allowedRoutes =
	[
		'/member/settings',
		'/employee/settings',
		'/authentication/sign-out'
	];

	const isAllowed = allowedRoutes.some(route => state.url.startsWith(route));
	if (isAllowed)
	{
		return true;
	}
	else
	{
		const userType = jwtHelper.userType();

		switch (userType)
		{
		case UserType.Member:
		{
			router.navigate(['/member/settings/welcome']);
			break;
		}
		case UserType.Employee:
		{
			router.navigate(['/employee/settings/welcome']);
			break;
		}
		}

		return true;
	}
}
