import { Component, OnDestroy, OnInit, signal, viewChild, inject } from '@angular/core';
import { Router } from '@angular/router';

import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';

import { MemberWorkoutService } from '@fitness-central/api/member/training/workout/workout.service';
import { TrainingHistoryCardComponent } from '@fitness-central/shared/components/training/history/cards/workout/workout.component';
import { TrainingWorkoutHistoryMessageService } from '@fitness-central/shared/components/training/history/message-services/history.message-service';
import { WorkoutViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout.view-model';
import { PageHeaderComponent } from '@fitness-central/shared/components/ui/page-header/page-header.component';
import { TrainingStatusType } from '@fitness-central/shared/enum/training-status-type.enum';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MemberTrainingHistoryNavigationComponent } from './_components/navigation/navigation.component';

@Component
(
	{
		selector: 'member-training-history',
		templateUrl: './history.component.html',
		imports: [
			SidebarViewComponent,
			PageHeaderComponent,
			MemberTrainingHistoryNavigationComponent,
			TrainingHistoryCardComponent
		]
	}
)

export class MemberTrainingHistoryComponent implements OnInit, OnDestroy
{
	private readonly _router = inject(Router);
	private readonly _workoutService = inject(MemberWorkoutService);
	private readonly _trainingWorkoutHistoryMessageService = inject(TrainingWorkoutHistoryMessageService);

	public sidebarView = viewChild(SidebarViewComponent);

	public workouts = signal<WorkoutViewModel[]>([]);

	public filterName = 'Today\'s Workouts';

	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit()
	{
		this.subscribeToMemberTrainingWorkoutHistoryMessageService();
	}

	public showAllWorkouts()
	{
		this.filterName = 'All Workouts';

		this.sidebarView().closeNavigation();
	}

	public continueWorkout(workout: WorkoutViewModel)
	{
		this._router.navigate([`./member/training/workout/${ workout.workoutId }`]);
	}

	public routeToDetail(workout: WorkoutViewModel)
	{
		this._router.navigate([`/member/training/history/detail/${ workout.workoutId }`]);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private subscribeToMemberTrainingWorkoutHistoryMessageService()
	{
		this._trainingWorkoutHistoryMessageService
			.dateFilterChangedAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (dateRange) =>
					{
						this.getWorkouts(dateRange);
					}
				}
			)
	}

	private getWorkouts(dateRange: Date[])
	{
		const startDate = dateRange[0];
		const endDate = dateRange[1];

		this._workoutService.getAll(startDate, endDate, TrainingStatusType.None)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (workouts) =>
					{
						const workoutHistory: WorkoutViewModel[] = [...workouts];

						this.workouts.set
						(
							workoutHistory.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())
						);

						this.isLoading.set(false);
					},
					error: (err) =>
					{
						console.warn(err);
					}
				}
			)
	}
}
