import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { memberBodyCompositionBodyMeasurementRoutes } from './body-measurement/body-measurement.routes';

export const memberBodyCompositionRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'body-fat-measurements'
	},
	{
		path: 'body-fat-measurements',
		canActivate: [AuthGuard],
		loadComponent: () => import('./body-fat-measurement/body-fat-measurement.component').then(c => c.MemberBodyCompositionBodyFatMeasurementComponent),
	},
	{
		path: 'body-measurements',
		canActivate: [AuthGuard],
		loadComponent: () => import('./overview/overview.component').then(c => c.MemberBodyCompositionOverviewComponent),
	},
	{
		path: 'body-measurement-detail',
		canActivate: [AuthGuard],
		children:
		[
			...memberBodyCompositionBodyMeasurementRoutes
		],
	},
	{
		path: 'body-weight-measurements',
		canActivate: [AuthGuard],
		loadComponent: () => import('./body-weight/body-weight.component').then(c => c.MemberBodyCompositionBodyWeightComponent),
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
