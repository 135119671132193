import { MessageCenterMessageService } from 'app/communication/message-center/_message-services/message-center.message-service';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';

import { DatePipe } from '@angular/common';
import { Component, inject, input, OnDestroy, OnInit, output, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { SystemMessageRequestModel } from '@fitness-central/api/communication/system-message/system-message.request-model';
import { SystemMessageResponseModel } from '@fitness-central/api/communication/system-message/system-message.response-model';
import { SystemMessageService } from '@fitness-central/api/communication/system-message/system-message.service';
import { CommunityMessageRequestModel } from '@fitness-central/api/community/message/message.request-model';
import { CommunityMessageResponseModel } from '@fitness-central/api/community/message/message.response-model';
import { CommunityMessageService } from '@fitness-central/api/community/message/message.service';
import { ProfileResponseModel } from '@fitness-central/api/community/profile/profile.response-model';
import { ProfileService } from '@fitness-central/api/community/profile/profile.service';
import { MessageCenterMessageType } from '@fitness-central/shared/enum/message-type.enum';

import { MessageListCardComponent } from './_components/list-card/list-card.component';
import { MessageListViewModel } from './_view-models/message-list.view-model';
import { MessageViewModel } from './_view-models/message.view-model';
import { MessageSidebarMessageService } from './message-sidebar.message-service';

@Component
(
	{
		selector: 'layout-message-sidebar',
		templateUrl: './message-sidebar.component.html',
		imports: [
			MatButtonModule,
			MatIconModule,
			MessageListCardComponent,
			DatePipe
		]
	}
)

export class LayoutMessageSidebarComponent implements OnInit, OnDestroy
{
	private readonly _messageCenterMessageService = inject(MessageCenterMessageService);
	private readonly _messageSidebarMessageService = inject(MessageSidebarMessageService);
	private readonly _communityMessageService = inject(CommunityMessageService);
	private readonly _profileService = inject(ProfileService);
	private readonly _router = inject(Router);
	private readonly _systemMessageService = inject(SystemMessageService);

	readonly unreadMessageCount = input<WritableSignal<number>>(undefined);
	readonly onCancel = output();

	public messageLists: MessageListViewModel[] = [];
	public today: Date = new Date(Date.now());

	private messages: MessageViewModel[] = [];

	public isLoading: boolean = true;

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit()
	{
		this.getMessages();
		this.subscribeToMessageCenterDeleteMessage();
		this.subscribeToMessageCenterMarkAsRead();
	}

	public selectMessage(selectedMessage: MessageViewModel)
	{
		this._messageSidebarMessageService.selectMessage(selectedMessage);
		this.routeToMessageCenter();
	}

	public deleteMessage(deletedMessage: MessageViewModel)
	{
		if (deletedMessage.isActive)
		{
			deletedMessage.isActive = false;

			switch (deletedMessage.messageType)
			{
			case MessageCenterMessageType.Community:
			{
				this.updateCommunityMessage(deletedMessage);
				break;
			}

			case MessageCenterMessageType.System:
			{
				this.updateSystemMessage(deletedMessage);
				break;
			}
			}

			this._messageSidebarMessageService.deleteMessage(deletedMessage);
		}
	}

	public cancel()
	{
		this.onCancel.emit();
	}

	public routeToMessageCenter()
	{
		this.onCancel.emit();
		this._router.navigateByUrl('/communication/messagecenter');
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private getMessages()
	{
		const defaultNumberOfDays: number = 7;

		const endDate = new Date(Date.now());
		const startDate = new Date();

		startDate.setDate(endDate.getDate() - defaultNumberOfDays);

		const systemMessages = this._systemMessageService.getReceived(startDate, endDate);
		const communityMessages = this._communityMessageService.getReceived(startDate, endDate);

		forkJoin
		(
			[
				systemMessages,
				communityMessages
			]
		)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next:
				(
					[
						systemMessages,
						communityMessages,
					]
				) =>
				{
					if (!systemMessages.length && !communityMessages.length)
					{
						this.isLoading = false;
					}
					else
					{
						this.buildSystemMessageModels(systemMessages);
						this.buildCommunityMessageModels(communityMessages);
						this.buildMessageLists();
					}
				}
				}
			)
	}

	private async subscribeToMessageCenterMarkAsRead()
	{
		this._messageCenterMessageService
			.markMessageAsReadAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (readMessage) =>
					{
						this.updateMessageList(readMessage);
					}
				}
			)
	}

	private subscribeToMessageCenterDeleteMessage()
	{
		this._messageCenterMessageService
			.deleteMessageAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (deletedMessage) =>
					{
						this.updateMessageList(deletedMessage);
					}
				}
			)
	}

	private updateMessageList(updatedMessage: MessageViewModel)
	{
		const messageIndex = this.messages.findIndex(message => message.messageId == updatedMessage.messageId);
		this.messages.splice(messageIndex, 1, updatedMessage);

		this.buildMessageLists();
		this.updateUnreadMessageCount();
	}

	private updateCommunityMessage(message: MessageViewModel)
	{
		const communityMessage: CommunityMessageRequestModel =
		{
			messageId: message.messageId,
			profileId: message.id,
			...message
		};

		this._communityMessageService
			.update(communityMessage)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						this.updateMessageList(message);
					}
				}
			)
	}

	private updateSystemMessage(message: MessageViewModel)
	{
		const systemMessage: SystemMessageRequestModel =
		{
			systemMessageId: message.messageId,
			...message
		};

		this._systemMessageService
			.update(systemMessage)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						this.updateMessageList(message);
					}
				}
			)
	}

	private buildSystemMessageModels(systemMessages: SystemMessageResponseModel[])
	{
		systemMessages
			.filter(message => message.isActive)
			.map
			(
				(systemMessage) =>
				{
					let message = new MessageViewModel();

					message =
					{
						messageId: systemMessage.systemMessageId,
						id: systemMessage.userId,
						senderId: systemMessage.senderUserId,
						messageType: MessageCenterMessageType.System,
						displayName: 'Fitness Central',
						imageSasUri: '',
						messageTypeId: systemMessage.systemMessageTypeId,
						...systemMessage,
					}

					this.messages.push(message);
				}
			)
	}

	private buildCommunityMessageModels(communityMessages: CommunityMessageResponseModel[])
	{
		communityMessages
			.filter(message => message.isActive)
			.forEach
			(
				(communityMessage) =>
				{
					let message = new MessageViewModel();

					message =
					{
						messageId: communityMessage.messageId,
						id: communityMessage.profileId,
						senderId: communityMessage.senderProfileId,
						messageType: MessageCenterMessageType.Community,
						messageTypeId: communityMessage.communityMessageTypeId,
						...communityMessage,
					}

					this.messages.push(message);
				}
			)
	}

	private getProfiles(senderUserIds: string[]): Observable<ProfileResponseModel[]>
	{
		const profileTasks: Observable<ProfileResponseModel>[] = []

		senderUserIds.forEach
		(
			(senderUserId) =>
			{
				profileTasks.push(this._profileService.getByUserId(senderUserId));
			}
		);

		return forkJoin(profileTasks);
	}

	private buildMessageLists()
	{
		this.messageLists = [];

		// Sort Messages
		this.messages = this.messages.filter(message => message.isActive);
		this.messages.sort((a, b) => 0 - (a.createdDate < b.createdDate ? -1 : 1));

		// Separate Messages By Date
		let messageDates = this.messages.map(message => new Date(message.createdDate))
		messageDates = messageDates.filter((n, i) => messageDates.indexOf(n) === i);

		messageDates.forEach
		(
			(messageDate) =>
			{
				const messageList = new MessageListViewModel();
				messageList.messageDate = new Date(messageDate);

				messageList.messages = this.messages.filter
				(
					message => new Date(message.createdDate) == messageDate
				);

				this.messageLists.push(messageList);
			}
		)

		this.updateUnreadMessageCount();

		this.isLoading = false;
	}

	private updateUnreadMessageCount()
	{
		const unreadMessages = this.messages.filter(message => !message.isRead);
		this.unreadMessageCount().update(() => unreadMessages.length);
	}
}
