

import { DatePipe } from '@angular/common';
import { Component, input, OnDestroy, OnInit, signal, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';

import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { Guid } from '@abp/guid';

import { MemberWorkoutResistanceExerciseSetTargetService } from '@fitness-central/api/member/training/workout-resistance-exercise-set-target/workout-resistance-exercise-set-target.service';
import { MemberWorkoutResistanceExerciseSetService } from '@fitness-central/api/member/training/workout-resistance-exercise-set/workout-resistance-exercise-set.service';
import { MemberWorkoutResistanceExerciseService } from '@fitness-central/api/member/training/workout-resistance-exercise/workout-resistance-exercise.service';
import { MemberWorkoutService } from '@fitness-central/api/member/training/workout/workout.service';
import { TrainingHistoryExerciseCardComponent } from '@fitness-central/shared/components/training/history/cards/exercise/exercise.component';
import { WorkoutResistanceExerciseSetViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout-resistance-exercise-set.view-model';
import { WorkoutResistanceExerciseViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout-resistance-exercise.view-model';
import { WorkoutViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout.view-model';
import { PageHeaderComponent } from '@fitness-central/shared/components/ui/page-header/page-header.component';
import { RoutineType } from '@fitness-central/shared/enum/routine-type.enum';
import { TrainingStatusType } from '@fitness-central/shared/enum/training-status-type.enum';

import { concatMap, forkJoin, from, map, mergeMap, Subject, takeUntil } from 'rxjs';

@Component
(
	{
		selector: 'member-training-history-detail',
		templateUrl: './detail.component.html',
		imports:
		[
			SidebarViewComponent,
			PageHeaderComponent,
			MatButtonModule,
			MatIconModule,
			MatProgressBarModule,
			TrainingHistoryExerciseCardComponent,
			DatePipe
		]
	}
)

export class MemberTrainingHistoryDetailComponent implements OnInit, OnDestroy
{
	private readonly _router = inject(Router);
	private readonly _workoutService = inject(MemberWorkoutService);
	private readonly _workoutResistanceExerciseService = inject(MemberWorkoutResistanceExerciseService);
	private readonly _workoutResistanceExerciseSetService = inject(MemberWorkoutResistanceExerciseSetService);
	private readonly _workoutResistanceExerciseSetTargetService = inject(MemberWorkoutResistanceExerciseSetTargetService);

	public workoutId = input<string>();

	public workout = signal<WorkoutViewModel>(new WorkoutViewModel());
	public resistanceExercisesWithSets = signal<WorkoutResistanceExerciseViewModel[]>([]);
	public workoutResistanceExercisesWithSetTargets = signal<WorkoutResistanceExerciseViewModel[]>([]);


	public trainingStatusType = TrainingStatusType;

	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit()
	{
		this.getWorkout();

	}

	public routeToHistory()
	{
		this._router.navigate(['/member/training/history']);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private getWorkout(): void
	{
		this._workoutService
			.get(this.workoutId())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (workout) =>
					{
						this.workout.set(workout);

						if (this.workout().routineTypeId === RoutineType.Daily)
						{
							this.getDailyWorkout();
						}
						else
						{
							this.getRoutineWorkout();
						}
					}
				}
			)
	}

	private getDailyWorkout(): void
	{
		this._workoutResistanceExerciseService
			.getByWorkoutId(this.workoutId())
			.pipe
			(
				takeUntil(this._unsubscribeAll),
				concatMap(resistanceExercises => from(resistanceExercises)),
				mergeMap
				(
					workoutResistanceExercise => this._workoutResistanceExerciseSetService.getByWorkoutResistanceExerciseId(workoutResistanceExercise.workoutResistanceExerciseId)
						.pipe
						(
							map
							(
								(workoutResistanceExerciseSets) =>
								{
									const filteredWorkoutResistanceExerciseSets = workoutResistanceExerciseSets
										.filter(workoutResistanceExerciseSet => workoutResistanceExerciseSet.isActive === true);

									const resistanceExerciseSets = filteredWorkoutResistanceExerciseSets.map
									(
										(resistanceExerciseSet) =>
										{
											const exerciseSet: WorkoutResistanceExerciseSetViewModel =
													{
														...resistanceExerciseSet,
														workoutResistanceExerciseSetTargetId: Guid.empty,
														targetWeight: resistanceExerciseSet.weight,
														actualWeight: resistanceExerciseSet.weight,
														repRangeStart: resistanceExerciseSet.repsCompleted,
														repRangeEnd: resistanceExerciseSet.repsCompleted
													}

											return exerciseSet;
										}
									);

									const WorkoutWithExerciseSets: WorkoutResistanceExerciseViewModel =
										{
											workoutResistanceExerciseSets: resistanceExerciseSets,
											...workoutResistanceExercise
										};

									return WorkoutWithExerciseSets;
								}
							)
						)
				)
			)
			.subscribe
			(
				{
					next: (workoutResistanceExerciseWithSetTargets) =>
					{
						this.workoutResistanceExercisesWithSetTargets.update
						(
							exercises => ([...exercises, workoutResistanceExerciseWithSetTargets])
								.sort((a, b) => a.order - b.order)
						);
					},
					complete: () =>
					{
						this.isLoading.set(false);
					}
				}
			)
	}

	private getRoutineWorkout(): void
	{
		this._workoutResistanceExerciseService
			.getByWorkoutId(this.workoutId())
			.pipe
			(
				takeUntil(this._unsubscribeAll),
				mergeMap(workoutResistanceExercises => from(workoutResistanceExercises)),
				mergeMap(workoutResistanceExercise =>
					forkJoin({
						exerciseSets: this._workoutResistanceExerciseSetService.getByWorkoutResistanceExerciseId(workoutResistanceExercise.workoutResistanceExerciseId),
						exerciseSetTargets: this._workoutResistanceExerciseSetTargetService.getByWorkoutResistanceExerciseId(workoutResistanceExercise.workoutResistanceExerciseId)
					}).pipe
					(
						takeUntil(this._unsubscribeAll),
						map(({ exerciseSets, exerciseSetTargets }) =>
						{
							const resistanceExerciseSets = exerciseSets
								.sort((a, b) => a.order - b.order)
								.map
								(
									(exerciseSet) =>
									{
										const target = exerciseSetTargets.find(target => target.workoutResistanceExerciseSetId === exerciseSet.workoutResistanceExerciseSetId);
										const workoutResistanceExerciseSetViewModel : WorkoutResistanceExerciseSetViewModel =
											{
												...target,
												targetWeight: target.weight,
												...exerciseSet,
												actualWeight: exerciseSet.weight
											}

										return workoutResistanceExerciseSetViewModel;
									}
								);

							const WorkoutWithExerciseSets: WorkoutResistanceExerciseViewModel =
								{
									workoutResistanceExerciseSets: resistanceExerciseSets,
									...workoutResistanceExercise
								};

							return WorkoutWithExerciseSets;
						})
					))
			)
			.subscribe
			(
				{
					next: (workoutResistanceExerciseWithSetTargets) =>
					{
						this.workoutResistanceExercisesWithSetTargets.update
						(
							exercises => ([...exercises, workoutResistanceExerciseWithSetTargets])
								.sort((a, b) => a.order - b.order)
						);
					},
					complete: () =>
					{
						this.isLoading.set(false);
					}
				}
			)
	}
}
