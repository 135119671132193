import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component
(
	{
		selector: 'image-grid',
		templateUrl: './image-grid.component.html',
		standalone: true,
		imports: [NgIf, NgSwitch, NgSwitchCase, MatGridListModule, NgSwitchDefault]
	}
)

export class ImageGridComponent
{
	@Input() public imageUrls: string[] = [];
	@Output() public imageSelected: EventEmitter<number> = new EventEmitter<number>();

	public constructor
	(
	)
	{

	}

	public onImageSelected(index: number)
	{
		this.imageSelected.emit(index);
	}
}
