import { Route, Routes } from '@angular/router';

import { ClientDetailNavigationMenuItemType } from './_components/navigation/models/menu-item-types.enum';
import { clientBodyCompositionRoutes } from './tabs/body-composition/body-composition.routes';
import { clientDetailHomeRoutes } from './tabs/home/home.routes';
import { clientTrainingRoutes } from './tabs/training/training.routes';

export const clientsDetailRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'body-composition'
	},
	{
		path: 'body-composition',
		data:
		{
			selectedNavigationItem: ClientDetailNavigationMenuItemType.BodyComposition
		},
		children:
		[
			...clientBodyCompositionRoutes
		],
	},
	{
		path: 'home',
		data:
		{
			selectedNavigationItem: ClientDetailNavigationMenuItemType.Home
		},
		children:
		[
			...clientDetailHomeRoutes
		],
	},
	{
		path: 'training',
		data:
		{
			selectedNavigationItem: ClientDetailNavigationMenuItemType.Training
		},
		children:
		[
			...clientTrainingRoutes
		],
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
