@if (!isLoading) {
	<div>
		<mat-grid-list cols="4" rowHeight="1:1" gutterSize="10">
			@for (profileFollower of profileFollowers; track profileFollower; let index = $index) {
				<mat-grid-tile>
					<default-profile-image
						class="h-14 w-14 cursor-pointer"
						[profileUrl]="profileFollower.sasUri"
						[displayName]="profileFollower.displayName"
						[matTooltip]="profileFollower.displayName"
						(click)="routeToProfile(index)"></default-profile-image>
				</mat-grid-tile>
			}
		</mat-grid-list>
		@if (!profileFollowers.length) {
			<div class="flex flex-col items-center">
				<div class="flex flex-row">No Members are following</div>
			</div>
		}
		@if (profileFollowers.length > 16) {
			<div class="flex items-center mt-6 -mx-3">
				<button class="px-3" mat-button [color]="'primary'" [routerLink]="['./']">See all followers</button>
			</div>
		}
	</div>
}
