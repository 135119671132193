import { FuseNavigationItem } from '@abp/components/navigation';

const memberBodyCompositionNavigationItems: FuseNavigationItem =
{
	id: 'body-composition',
	title: 'Body Composition',
	type: 'collapsable',
	icon: 'accessibility_new',
	children:
	[
		{
			id: 'bodycomposition.bodyfat',
			title: 'Body Fat',
			type: 'basic',
			icon: 'body_fat',
			link: '/member/body-composition/body-fat-measurements'
		},
		{
			id: 'bodycomposition.bodymeasurements',
			title: 'Body Measurements',
			type: 'basic',
			icon: 'measuring_tape',
			link: '/member/body-composition/body-measurements'
		},
		{
			id: 'bodycomposition.bodyweight',
			title: 'Body Weight',
			type: 'basic',
			icon: 'monitor_weight',
			link: '/member/body-composition/body-weight-measurements'
		},
	]
};

export const memberBodyCompositionNavigation: FuseNavigationItem[] =
[
	memberBodyCompositionNavigationItems
];
