<div *ngIf="!isLoading">
	<mat-grid-list cols="4" rowHeight="1:1" gutterSize="10">
		<mat-grid-tile *ngFor="let profileFollower of profileFollowers; index as index">
			<default-profile-image class="h-14 w-14 cursor-pointer" [profileUrl]="profileFollower.sasUri" [displayName]="profileFollower.displayName" [matTooltip]="profileFollower.displayName" (click)="routeToProfile(index)"></default-profile-image>
		</mat-grid-tile>
	</mat-grid-list>

	<div class="flex flex-col items-center" *ngIf="!profileFollowers.length">
		<div class="flex flex-row">No Members are following</div>
	</div>

	<div class="flex items-center mt-6 -mx-3" *ngIf="profileFollowers.length > 16">
		<button class="px-3" mat-button [color]="'primary'" [routerLink]="['./']"> See all followers </button>
	</div>
</div>
