import { TextFieldModule } from '@angular/cdk/text-field';
import { NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SecurityContext, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';

import { PostCommentResponse } from '@fitness-central/api/community/post-comment/post-comment.response-model';
import { PostCommentService } from '@fitness-central/api/community/post-comment/post-comment.service';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';

import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component
(
	{
		selector: 'profile-post-comment-edit',
		templateUrl: './post-comment-edit.component.html',
		standalone: true,
		imports:
		[
			DefaultProfileImageComponent,
			FormsModule,
			ReactiveFormsModule,
			MatFormFieldModule,
			MatInputModule,
			TextFieldModule,
			NgIf,
			MatButtonModule,
			MatIconModule
		]
	}
)

export class CommunityPostCommentEditComponent implements OnInit, OnDestroy
{
	@ViewChild("comment", { static: true }) comment: ElementRef;

	@Input() postId: string;
	@Input() postCommentParentId: string = "00000000-0000-0000-0000-000000000000";

	@Output() postCommentOutput = new EventEmitter<PostCommentResponse>();

	public profileImageUrl: string;
	public displayName: string;
	public isSubmitVisible: boolean = false;

	public postCommentForm: FormGroup;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
	(
		private _sessionStorageHelper: SessionStorageHelper,
		private _formBuilder: FormBuilder,
		private _sanitizer: DomSanitizer,
		private _postCommentService: PostCommentService,
	)
	{
	}

	public ngOnInit()
	{
		this.buildPostCommentForm();
		this.profileImageUrl = this._sessionStorageHelper.profileImageUrl();
		this.displayName = this._sessionStorageHelper.displayName();
	}

	public ngAfterViewInit(): void
	{
		this.checkInput();
	}

	public checkInput()
	{
		let commentBlur: Observable<any> = fromEvent(this.comment.nativeElement, 'blur');

		merge(this.postCommentForm.valueChanges, commentBlur)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				() =>
				{
					if (this.postCommentForm.controls['comment'].value)
					{
						this.isSubmitVisible = true;
					}
					else
					{
						this.isSubmitVisible = false;
					}
				}
			);
	}

	public CreatePostComment()
	{
		this.postCommentForm.value['comment'] = this._sanitizer.sanitize(SecurityContext.HTML, this.postCommentForm.value['comment']);

		this._postCommentService
			.create(this.postCommentForm.value)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: postComment =>
					{
						this.postCommentOutput.emit(postComment);

						this.buildPostCommentForm();
						this.checkInput();
						this.comment.nativeElement.blur();
					}
				}
			)
	}

	private buildPostCommentForm()
	{
		this.postCommentForm = this._formBuilder.group
		(
			{
				postId: [this.postId, [Validators.required]],
				postCommentParentId: [this.postCommentParentId, [Validators.required]],
				isActive: [true, [Validators.required]],
				comment: ['']
			}
		);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
