import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { ClientDetailHomeComponent } from './home.component';

export const clientDetailHomeRoutes: Route[] =
[
	{
		path: '',
		canActivate: [AuthGuard],
		component: ClientDetailHomeComponent,
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
