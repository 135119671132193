import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { memberBodyCompositionRoutes } from './body-composition/body-composition.routes';
import { memberSettingsRoutes } from './settings/settings.routes';
import { memberTrainingRoutes } from './training/training.routes';
import { memberVitalsRoutes } from './vitals/vitals.routes';

export const memberRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'blood-glucose'
	},
	{
		path: 'body-composition',
		canActivate: [AuthGuard],
		children:
		[
			...memberBodyCompositionRoutes
		],
	},
	{
		path: 'settings',
		canActivate: [AuthGuard],
		children:
		[
			...memberSettingsRoutes
		],
	},
	{
		path: 'training',
		canActivate: [AuthGuard],
		children:
		[
			...memberTrainingRoutes
		],
	},
	{
		path: 'vitals',
		canActivate: [AuthGuard],
		children:
		[
			...memberVitalsRoutes
		],
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
