
import { Component, computed, EventEmitter, input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Guid } from '@abp/guid';
import { TruncateStringPipe } from '@abp/pipes/string/truncate-string';

import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { FuseCardComponent } from '@abp/components/card';

import { ResistanceExerciseSetCardViewModel } from '../view-models/exercise-set.view-model';
import { TrainingRoutineResistanceExerciseSetMessageService } from './message-service';

@Component
(
	{
		selector: 'training-routine-resistance-exercise-set-card',
		templateUrl: './card.component.html',
		standalone: true,
		imports:
		[
			FuseCardComponent,
			MatIconModule,
			MatButtonModule,
			MatTooltipModule,
			TruncateStringPipe
		]
	}
)

export class TrainingRoutineResistanceExerciseSetCardComponent implements OnInit
{
	public exerciseSet = input<ResistanceExerciseSetCardViewModel>();
	public canEdit = input<boolean>(true);
	public canChangeStatus = input<boolean>(true);

	@Output() onEdit = new EventEmitter<ResistanceExerciseSetCardViewModel>();
	@Output() onActiveChanged = new EventEmitter<ResistanceExerciseSetCardViewModel>();

	public activeMemberId: string;

	public isActionBarVisible = computed(() => this._trainingRoutineResistanceExerciseSetMessageService.activeId() == this.exerciseSet().routineResistanceExerciseSetId)

	public measurementSystem: number;

	public constructor
	(
		private readonly _sessionStorageHelper: SessionStorageHelper,
		private readonly _trainingRoutineResistanceExerciseSetMessageService: TrainingRoutineResistanceExerciseSetMessageService
	)
	{
	}

	public ngOnInit()
	{
		this.measurementSystem = +this._sessionStorageHelper.measurementSystem();
	}

	public edit()
	{
		this.toggleActionBarVisibility();
		this.onEdit.emit(this.exerciseSet());
	}

	public reactivate()
	{
		this.toggleActionBarVisibility();

		const exerciseSet = this.exerciseSet();

		exerciseSet.order = 0;
		exerciseSet.isActive = true;

		this.onActiveChanged.emit(exerciseSet);
	}

	public inactivate()
	{
		this.toggleActionBarVisibility();

		const exerciseSet = this.exerciseSet();

		exerciseSet.order = 0;
		exerciseSet.isActive = false;

		this.onActiveChanged.emit(exerciseSet);
	}

	public toggleActionBarVisibility()
	{
		if (this.isActionBarVisible())
		{
			this._trainingRoutineResistanceExerciseSetMessageService.activeId.set(Guid.empty);
		}
		else
		{
			this._trainingRoutineResistanceExerciseSetMessageService.activeId.set(this.exerciseSet().routineResistanceExerciseSetId);
		}
	}
}
