<sidebar-view #sidebarView>
	<ng-container header> </ng-container>

	<ng-container navigation>
		<client-detail-home-navigation (onDetailEdit)="editClientDetail()" (onContactCreate)="createClientContact()"> </client-detail-home-navigation>
	</ng-container>

	<ng-container create-sidebar> </ng-container>

	<ng-container edit-sidebar>
		<div>
			@switch (sidebarAction) {
				@case ('editDetail') {
					<div>
						<client-detail-home-sidebar-client-edit [client]="client" (onUpdated)="closeSidebar()" (onCancel)="closeSidebar()">
						</client-detail-home-sidebar-client-edit>
					</div>
				}
				@case ('createContact') {
					<div>
						<client-details-home-create-contact-sidebar [client]="client()" (onCreated)="onClientContactAdded($event)" (onCancel)="closeSidebar()">
						</client-details-home-create-contact-sidebar>
					</div>
				}
				@case ('editContact') {
					<div>
						<client-details-home-edit-contact-sidebar [clientContact]="selectedClientContact" (onUpdated)="onClientContactUpdated($event)" (onCancel)="closeSidebar()">
						</client-details-home-edit-contact-sidebar>
					</div>
				}
			}
		</div>
	</ng-container>

	<ng-container content>
		<div class="flex flex-col w-full">
			<div class="flex flex-col w-full p-4 md:py-8">
				<div class="w-full">
					<div class="w-full">
						<div class="text-lg">Details</div>
					</div>
				</div>

				<fuse-card class="rounded-lg mt-4">
					<div class="flex flex-col w-full">
						<div class="flex flex-row">
							<div class="grid sm:grid-cols-4 gap-6 w-full font-semibold text-secondary p-4">
								<div class="sm:col-span-2">
									Gender:
									<span class="font-normal pl-2"> {{ client().genderId | enumDescription : genderTypeDescriptions }} </span>
								</div>
								<div class="sm:col-span-2">
									Date of Birth:
									<span class="font-normal pl-2"> {{ client().dateOfBirth | date }} ({{ client().dateOfBirth | age }} years old)</span>
								</div>
								<div class="sm:col-span-4">
									Description:
									@if (client().description.length) {
										<span class="font-normal pl-2"> {{ client.description }} </span>
									}
									@if (!client().description.length) {
										<span class="font-normal pl-2"> No description... </span>
									}
								</div>
							</div>
						</div>

						<div class="flex flex-row">
							<div class="w-full">
								<div class="flex flex-col items text-md font-medium bg-accent text-on-accent">
									<div class="flex flex-row justify-between">
										<div class="flex flex-col">
											<div class="flex flex-row items-end"></div>
										</div>
										<div class="flex flex-col">
											<div class="flex flex-row">
												<button mat-icon-button class="accent-fg accent-bg mx-2 my-1" matTooltip="Edit Client Details" (click)="editClientDetail()">
													<mat-icon class="text-blue-400">edit</mat-icon>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</fuse-card>
			</div>

			<div class="flex flex-col w-full p-4 md:py-4">
				<div class="w-full">
					<div class="w-full">
						<div class="text-lg">Contact Information</div>
					</div>
				</div>

				<fuse-card class="rounded-lg mt-4">
					<div class="flex flex-col w-full">
						@if (!clientContacts.length) {
							<div class="flex flex-col m-8 items-center">
								<div class="flex flex-row">No Contact Information.</div>
							</div>
						}

						@if (clientContacts.length) {
							<div class="flex flex-row sm:px-2">
								<div class="flex flex-col w-11/12">
									<div class="flex flex-row px-2">
										<div class="flex flex-col items-center w-1/3 p-2">
											<div class="flex flex-row items-center text-md text-secondary font-semibold space-x-2">
												<div>Type</div>
											</div>
										</div>
										<div class="flex flex-col items-center w-1/3 p-2">
											<div class="flex flex-row items-center text-md text-secondary font-semibold space-x-2">
												<div>Value</div>
											</div>
										</div>
										<div class="flex flex-col items-center w-1/3 p-2">
											<div class="flex flex-row items-center text-md text-secondary font-semibold space-x-2">
												<div>Opted-out</div>
											</div>
										</div>
									</div>
								</div>
								<div class="flex flex-col justify-center w-1/12">
									<div class="flex flex-row justify-end px-4">&nbsp;</div>
								</div>
							</div>
							@for (clientContact of clientContacts; track clientContact) {
								<client-detail-home-contact-card [clientContact]="clientContact" (onEdit)="editClientContact($event)" (onDelete)="deleteClientContact($event)">
								</client-detail-home-contact-card>
							}
						}

						<div class="flex flex-row">
							<div class="w-full">
								<div class="flex flex-col items text-md font-medium bg-accent text-on-accent">
									<div class="flex flex-row justify-between">
										<div class="flex flex-col">
											<div class="flex flex-row items-end"></div>
										</div>
										<div class="flex flex-col">
											<div class="flex flex-row">
												<button mat-icon-button class="bg-accent mx-2 my-1" matTooltip="Create Client Contact" (click)="createClientContact()">
													<mat-icon class="text-blue-400">add</mat-icon>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</fuse-card>
			</div>
		</div>
	</ng-container>
</sidebar-view>
