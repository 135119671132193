import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { UserType } from '@fitness-central/shared/enum/user-type.enum';

import { Observable } from 'rxjs';

export const EmployeeProfileGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
	const router: Router = inject(Router);
	const jwtHelper: JwtHelper = inject(JwtHelper);

	let isEmployeeProfile: boolean;
	isEmployeeProfile = jwtHelper.userType() == UserType.Employee;

	if (isEmployeeProfile === false)
	{
		router.navigateByUrl("error/404");
	}

	return true;
};
