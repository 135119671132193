import { Pipe, PipeTransform } from '@angular/core';

@Pipe
(
	{
		name: 'age',
		pure: true,
		standalone: true
	}
)

export class AgePipe implements PipeTransform
{
	public age: number = 0;

	transform(dateOfBirth: Date): any
	{
		if (dateOfBirth)
		{
			let currentDate = new Date();
			let date = new Date(dateOfBirth);

			this.age = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) / (1000 * 60 * 60 * 24 * 365));
		}

		return this.age;
	}
}
