import { Component, input, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { Router } from '@angular/router';

import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';

import { ClientWorkoutService } from '@fitness-central/api/client/training/workout/workout.service';
import { TrainingHistoryCardComponent } from '@fitness-central/shared/components/training/history/cards/workout/workout.component';
import { TrainingWorkoutHistoryMessageService } from '@fitness-central/shared/components/training/history/message-services/history.message-service';
import { WorkoutViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout.view-model';
import { PageHeaderComponent } from '@fitness-central/shared/components/ui/page-header/page-header.component';
import { TrainingStatusType } from '@fitness-central/shared/enum/training-status-type.enum';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ClientTrainingHistoryNavigationComponent } from './_components/navigation/navigation.component';

@Component({
	selector: 'client-training-history',
	templateUrl: './history.component.html',
	standalone: true,
	imports:
		[
			SidebarViewComponent,
			PageHeaderComponent,
			ClientTrainingHistoryNavigationComponent,
			TrainingHistoryCardComponent
		]
})

export class ClientTrainingHistoryComponent implements OnInit, OnDestroy
{
	public clientId = input<string>();

	public sidebarView = viewChild(SidebarViewComponent);

	public workouts = signal<WorkoutViewModel[]>([]);

	public filterName = "Today's Workouts";

	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public constructor
		(
			private readonly _router: Router,
			private readonly _workoutService: ClientWorkoutService,
			private readonly _trainingWorkoutHistoryMessageService: TrainingWorkoutHistoryMessageService
		)
	{
	}

	public ngOnInit()
	{
		this.subscribeToClientTrainingWorkoutHistoryMessageService();
	}

	public showAllWorkouts()
	{
		this.filterName = "All Workouts";

		this.workouts = this.workouts;

		this.sidebarView().closeNavigation();
	}


	public continueWorkout(workout: WorkoutViewModel)
	{
		this._router.navigate([`./client/training/workout/${workout.workoutId}`]);
	}

	public routeToDetail(workout: WorkoutViewModel)
	{
		this._router.navigate([`/client/training/history/detail/${workout.workoutId}`]);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private subscribeToClientTrainingWorkoutHistoryMessageService()
	{
		this._trainingWorkoutHistoryMessageService
			.dateFilterChangedAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: dateRange =>
					{
						this.getWorkouts(dateRange);
					}
				}
			)
	}

	private getWorkouts(dateRange: Date[])
	{
		let startDate = dateRange[0];
		let endDate = dateRange[1];

		this._workoutService.getAllByClientId(this.clientId(), startDate, endDate, TrainingStatusType.None)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: workouts =>
					{
						let workoutHistory: WorkoutViewModel[] = [...workouts];

						this.workouts.set
							(
								workoutHistory.sort((first, second) => first.order - second.order)
							);

						this.isLoading.set(false);
					}
				}
			)
	}
}
