
import { Component, OnChanges, OnDestroy, ViewEncapsulation, inject, input, output, viewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BodyFatCalculationType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-calculation-type.enum';
import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';
import { ClientBodyFatSiteMeasurementType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-site-measurement/body-fat-site-measurement-type.enum';
import { GenderType } from '@fitness-central/api/general/gender-type/gender-type.enum';

import { Subject } from 'rxjs';

import { ClientBodyFatMeasurementViewModel } from '../../../../../_view-models/body-fat-measurement.view-model';

@Component({
	selector: 'client-detail-body-composition-body-fat-measurement-sidebar-delete-jackson-pollock-7-site',
	templateUrl: './jackson-pollock-7-site.component.html',
	encapsulation: ViewEncapsulation.None,
	imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatButtonModule, MatTooltipModule, MatProgressSpinnerModule]
})

export class ClientDetailBodyCompositionBodyFatMeasurementSidebarDeleteJacksonPollock7SiteComponent implements OnChanges, OnDestroy
{
	private readonly _formBuilder = inject(FormBuilder);

	readonly bodyFatCalculationTypeList = input<Record<number, string>>(undefined);
	readonly bodyFatMeasurement = input<ClientBodyFatMeasurementViewModel>(undefined);
	readonly clientGender = input<GenderType>(undefined);

	readonly onDeleted = output<string>();
	readonly onCancel = output();

	readonly formGroupDirective = viewChild(FormGroupDirective);

	public selectedBodyFatCalculationType: BodyFatCalculationType = BodyFatCalculationType.Manual;
	public bodyFatCalculationTypes: typeof BodyFatCalculationType = BodyFatCalculationType;

	public showAlert: boolean = false;

	public bodyWeightMeasurement: ClientBodyFatMeasurementResponseModel = new ClientBodyFatMeasurementResponseModel();
	public bodyFatMeasurementForm: FormGroup;

	public genderTypes: typeof GenderType = GenderType;

	private _unsubscribeAll: Subject<void>  = new Subject<void>();

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public delete()
	{
		this.resetForm();
		this.onDeleted.emit(this.bodyFatMeasurement().bodyFatMeasurementId);
	}

	public cancel()
	{
		this.resetForm();
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private buildForm()
	{
		const chest = this.bodyFatMeasurement().bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId === ClientBodyFatSiteMeasurementType.Chest)[0].value;
		const abdomen = this.bodyFatMeasurement().bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId === ClientBodyFatSiteMeasurementType.Abdomen)[0].value;
		const thigh = this.bodyFatMeasurement().bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId === ClientBodyFatSiteMeasurementType.Thigh)[0].value;
		const tricep = this.bodyFatMeasurement().bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId === ClientBodyFatSiteMeasurementType.Triceps)[0].value;
		const axilla = this.bodyFatMeasurement().bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId === ClientBodyFatSiteMeasurementType.Axilla)[0].value;
		const subscapula = this.bodyFatMeasurement().bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId === ClientBodyFatSiteMeasurementType.Subscapula)[0].value;
		const suprailiac = this.bodyFatMeasurement().bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId === ClientBodyFatSiteMeasurementType.Suprailiac)[0].value;

		this.bodyFatMeasurementForm = this._formBuilder.group
		(
			{
				bodyFatMeasurementId: [this.bodyFatMeasurement().bodyFatMeasurementId],
				chest: [{ value: chest, disabled: true }],
				abdomen: [{ value: abdomen, disabled: true }],
				thigh: [{ value: thigh, disabled: true }],
				tricep: [{ value: tricep, disabled: true }],
				axilla: [{ value: axilla, disabled: true }],
				subscapula: [{ value: subscapula, disabled: true }],
				suprailiac: [{ value: suprailiac, disabled: true }],
				measurementDate: [{ value: this.bodyFatMeasurement().measurementDate, disabled: true }]
			}
		);
	}

	private resetForm()
	{
		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective().resetForm();
			}
		);
	}
}
