export enum ClientRoutineTemplateFilterType
{
	ActiveRoutines = 1,
	InactiveRoutines = 2
}

export const ClientRoutineFilterTypeDescriptions = new Map<ClientRoutineTemplateFilterType, string>
(
	[
		[ClientRoutineTemplateFilterType.ActiveRoutines, 'Active Routines'],
		[ClientRoutineTemplateFilterType.InactiveRoutines, 'Inactive Routines']
	]
);