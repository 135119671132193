import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { ClientContactResponseModel } from '@fitness-central/api/client/contact/contact-response.model';
import { FuseCardComponent } from '@abp/components/card';

import { Subject, takeUntil } from 'rxjs';

import { ContactCardMessageService } from './_message_services/contact-card.message-service';

@Component({
	selector: 'client-detail-home-contact-card',
	templateUrl: './contact-card.component.html',
	standalone: true,
	imports: [FuseCardComponent, NgIf, MatButtonModule, MatIconModule]
})
export class ClientDetailHomeContactCardComponent implements OnInit
{
	@Input() clientContact: ClientContactResponseModel
	@Output() onEdit = new EventEmitter<ClientContactResponseModel>();
	@Output() onDelete = new EventEmitter<ClientContactResponseModel>();

	public isActionBarVisible: boolean = false;

	private _unsubscribeAll: Subject<any>;

	constructor
		(

			private readonly _contactCardMessageService: ContactCardMessageService
		)
	{
		this._unsubscribeAll = new Subject<any>();
	}

	public ngOnInit()
	{
		this.subscribeToTrainingWorkoutManualMessageService();
	}

	public editClientContact()
	{
		this.isActionBarVisible = false;
		this.onEdit.emit(this.clientContact);
	}

	public deleteClientContact()
	{
		this.isActionBarVisible = false;
		this.onDelete.emit(this.clientContact);
	}

	public toggleActionBarVisibility()
	{
		this.isActionBarVisible = !this.isActionBarVisible;
		this._contactCardMessageService.displayContactCardActionBar(this.clientContact.contactId);
	}

	private subscribeToTrainingWorkoutManualMessageService()
	{
		this._contactCardMessageService
			.displayContactCardActionBarAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: clientContactId =>
					{
						if (this.clientContact.contactId != clientContactId)
						{
							this.isActionBarVisible = false;
						}
					}
				}
			)
	}
}
