<div class="flex flex-col w-full">
	<div class="flex flex-row items-center w-full">
		<div class="flex flex-col mr-4">
			<default-profile-image class="w-8 h-8" [profileUrl]="profileImageUrl" [displayName]="displayName"></default-profile-image>
		</div>
		<div class="flex flex-col w-full">
			<form [formGroup]="postCommentForm">
				<mat-form-field appearance="fill" class="w-full pt-4 ">
					<textarea #comment matInput formControlName="comment" class="w-full" cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="25"></textarea>
					<div class="flex flex-col justify-end h-full" *ngIf="isSubmitVisible">
						<div class="flex flex-row justify-end">
							<button type="button" (click)="CreatePostComment()" class="accent-fg m-1 ml-2" mat-icon-button>
								<mat-icon class="icon-size-5">
									send
								</mat-icon>
							</button>
						</div>
					</div>
				</mat-form-field>
			</form>
		</div>
	</div>
	<div class="absolute sm:relative bottom-0 left-0 flex flex-row w-full">
		<div class="flex flex-col w-full items text-md font-medium text-on-default">
			<div class="flex flex-row justify-between"></div>
		</div>
	</div>
</div>
