<div class="relative flex flex-col flex-auto items-center">
	<div class="w-full max-w-3xl">
		<form [formGroup]="bodyFatMeasurementForm">
			@switch (clientGender()) {
				@case (genderTypes.NotSelected) {
					<div class="flex flex-col gap-4 mt-8 px-6">
						Gender is required in order to propertly calculate Body Fat Percentages. Please update your Gender in your Profile Settings.
					</div>
				}
				@case (genderTypes.Male) {
					<div class="flex flex-col gap-4 mt-8 px-6">
						<div>
							<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full">
								<mat-label>Chest</mat-label>
								<input type="number" matInput formControlName="chest" placeholder="Enter Chest Measurement" />
							</mat-form-field>
						</div>
						<div>
							<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full">
								<mat-label>Abdomen</mat-label>
								<input type="number" matInput formControlName="abdomen" placeholder="Enter Abdomen Measurement" />
							</mat-form-field>
						</div>
						<div>
							<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full">
								<mat-label>Thigh</mat-label>
								<input type="number" matInput formControlName="thigh" placeholder="Enter Thigh Measurement" />
							</mat-form-field>
						</div>
						<div>
							<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full">
								<mat-label>Tricep</mat-label>
								<input type="number" matInput formControlName="tricep" placeholder="Enter Tricep Measurement" />
							</mat-form-field>
						</div>
						<div>
							<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full">
								<mat-label>Axilla</mat-label>
								<input type="number" matInput formControlName="axilla" placeholder="Enter Axilla Measurement" />
							</mat-form-field>
						</div>
						<div>
							<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full">
								<mat-label>Subscapula</mat-label>
								<input type="number" matInput formControlName="subscapula" placeholder="Enter Subscapula Measurement" />
							</mat-form-field>
						</div>
						<div>
							<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full">
								<mat-label>Suprailiac</mat-label>
								<input type="number" matInput formControlName="suprailiac" placeholder="Enter Suprailiac Measurement" />
							</mat-form-field>
						</div>
						<div>
							<mat-form-field appearance="outline">
								<mat-label>Measurement Date</mat-label>
								<input matInput formControlName="measurementDate" [matDatepicker]="measurementDateEdit" placeholder="Choose a logged date" />
								<mat-datepicker-toggle matSuffix [for]="measurementDateEdit"></mat-datepicker-toggle>
								<mat-datepicker #measurementDateEdit [startAt]="measurementDateEdit" disabled="false"></mat-datepicker>
								<mat-error>Measurement Date is required!</mat-error>
							</mat-form-field>
						</div>
					</div>
				}
				@case (genderTypes.Female) {
					<div class="flex flex-col gap-4 mt-8 px-6">
						<div>
							<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full">
								<mat-label>Thigh</mat-label>
								<input type="number" matInput formControlName="thigh" placeholder="Enter Thigh Measurement" />
							</mat-form-field>
						</div>
						<div>
							<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full">
								<mat-label>Tricep</mat-label>
								<input type="number" matInput formControlName="tricep" placeholder="Enter Tricep Measurement" />
							</mat-form-field>
						</div>
						<div>
							<mat-form-field appearance="outline" class="abp-mat-no-subscript flex-auto w-full">
								<mat-label>Suprailiac</mat-label>
								<input type="number" matInput formControlName="suprailiac" placeholder="Enter Suprailiac Measurement" />
							</mat-form-field>
						</div>
					</div>
				}
			}
		</form>
	</div>
</div>
<div class="absolute bottom-0 flex items-center w-full mt-10 px-6 py-4 border-t bg-gray-50">
	<button class="ml-auto w-20" mat-button [matTooltip]="'Cancel'" (click)="cancel()">Cancel</button>
	<button class="ml-2 w-20" mat-flat-button [color]="'primary'" [disabled]="bodyFatMeasurementForm.invalid" [matTooltip]="'Save'" (click)="edit()">
		@if (!bodyFatMeasurementForm.disabled) {
			<span> Save </span>
		}
		@if (bodyFatMeasurementForm.disabled) {
			<span>
				<mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
			</span>
		}
	</button>
</div>
