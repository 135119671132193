

import { DatePipe } from '@angular/common';
import { Component, inject, input, OnDestroy, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';

import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { Guid } from '@abp/guid';

import { ClientWorkoutResistanceExerciseSetService } from '@fitness-central/api/client/training/workout-resistance-exercise-set/workout-resistance-exercise-set.service';
import { ClientWorkoutResistanceExerciseService } from '@fitness-central/api/client/training/workout-resistance-exercise/workout-resistance-exercise.service';
import { ClientWorkoutService } from '@fitness-central/api/client/training/workout/workout.service';
import { TrainingHistoryExerciseCardComponent } from '@fitness-central/shared/components/training/history/cards/exercise/exercise.component';
import { WorkoutResistanceExerciseSetViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout-resistance-exercise-set.view-model';
import { WorkoutResistanceExerciseViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout-resistance-exercise.view-model';
import { WorkoutViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout.view-model';
import { PageHeaderComponent } from '@fitness-central/shared/components/ui/page-header/page-header.component';
import { TrainingStatusType } from '@fitness-central/shared/enum/training-status-type.enum';

import { concatMap, from, map, mergeMap, Subject, takeUntil } from 'rxjs';

@Component
(
	{
		selector: 'client-training-history-detail',
		templateUrl: './detail.component.html',
		imports:
		[
			SidebarViewComponent,
			PageHeaderComponent,
			MatButtonModule,
			MatIconModule,
			MatProgressBarModule,
			TrainingHistoryExerciseCardComponent,
			DatePipe
		]
	}
)

export class ClientTrainingHistoryDetailComponent implements OnInit, OnDestroy
{
	private _router = inject(Router);
	private _workoutService = inject(ClientWorkoutService);
	private _workoutResistanceExerciseService = inject(ClientWorkoutResistanceExerciseService);
	private _workoutResistanceExerciseSetService = inject(ClientWorkoutResistanceExerciseSetService);

	public clientId = input<string>();
	public workoutId = input<string>();

	public workout = signal<WorkoutViewModel>(new WorkoutViewModel());
	public resistanceExercisesWithSets = signal<WorkoutResistanceExerciseViewModel[]>([]);

	public trainingStatusType = TrainingStatusType;

	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit()
	{
		this.getWorkout();
		this.getWorkoutResistanceExercisesWithSets();
	}

	public routeToHistory()
	{
		this._router.navigate([`/client/${ this.clientId() }/training/history`]);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private getWorkout()
	{
		this._workoutService
			.get(this.workoutId())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (workout) =>
					{
						this.workout.set(workout);
					}
				}
			)
	}

	private getWorkoutResistanceExercisesWithSets(): void
	{
		this._workoutResistanceExerciseService
			.getByWorkoutId(this.workoutId())
			.pipe
			(
				takeUntil(this._unsubscribeAll),
				concatMap(resistanceExercises => from(resistanceExercises)),
				mergeMap
				(
					workoutResistanceExercise => this._workoutResistanceExerciseSetService.getByWorkoutResistanceExerciseId(workoutResistanceExercise.workoutResistanceExerciseId)
						.pipe
						(
							map
							(
								(workoutResistanceExerciseSets) =>
								{
									const filteredWorkoutResistanceExerciseSets = workoutResistanceExerciseSets
										.filter(workoutResistanceExerciseSet => workoutResistanceExerciseSet.isActive === true)
										.sort((a, b) => a.order - b.order);

									const resistanceExerciseSets = filteredWorkoutResistanceExerciseSets.map
									(
										(resistanceExerciseSet) =>
										{
											const exerciseSet: WorkoutResistanceExerciseSetViewModel =
														{
															...resistanceExerciseSet,
															workoutResistanceExerciseSetTargetId: Guid.empty
														}

											return exerciseSet;
										}
									);

									const WorkoutWithExerciseSets: WorkoutResistanceExerciseViewModel =
											{
												workoutResistanceExerciseSets: resistanceExerciseSets,
												...workoutResistanceExercise
											};

									return WorkoutWithExerciseSets;
								}
							)
						)
				)
			)
			.subscribe
			(
				{
					next: (workoutResistanceExerciseWithSets) =>
					{
						this.resistanceExercisesWithSets.update
						(
							exercises => ([...exercises, workoutResistanceExerciseWithSets])
								.sort((a, b) => a.order - b.order)
						);
					},
					complete: () =>
					{
						this.isLoading.set(false);
					}
				}
			)
	}
}
