<div class="h-full overflow-hidden">
	<div class="flex flex-col mb-6">
		<div class="flex flex-row">
			<div class="flex flex-col w-full">
				<div class="flex flex-row justify-between ml-2 mr-2">
					<div class="flex flex-col text-xl font-semibold justify-center">
						<div class="flex items-center">
							<div class="h-10 w-10 text-lg mr-4">
								<default-profile-image [profileUrl]="ownerProfileImageUrl" [displayName]="displayName"></default-profile-image>
							</div>

							<div class="flex flex-col">
								<span class="font-semibold leading-none">
									{{ displayName }}
								</span>
								<span class="text-sm text-secondary leading-none mt-1">
									{{ postDate | timeAgo }}
								</span>
							</div>
						</div>
					</div>
					<div class="flex flex-col justify-center">
						<button mat-icon-button class="bg-accent text-on-accent" (click)="closeDialog()">
							<mat-icon>
								close
							</mat-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<ng-container *ngIf="postImages">
		<swiper-container #swiperContainer class="swiper p-4 px-8 rounded-lg" slidesPerView="1" loop="true" initialSlide="{{ selectedIndex }}" pagination="true" autoplay="true" roundLengths="true" zoom="true" cssMode="true">
			<swiper-slide swiperSlide *ngFor="let postImage of postImages">
				<img [src]="postImage.sasUri" />
			</swiper-slide>
		</swiper-container>
	</ng-container>
</div>
