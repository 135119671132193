@if (imageUrls().length) {
	<div>
		<div class="cursor-pointer">
			@switch (imageUrls().length) {
				@case (1) {
					<div>
						<mat-grid-list cols="1" rowHeight="1:1" gutterSize="8">
							<img [src]="imageUrls()[0]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(0)" />
						</mat-grid-list>
					</div>
				}
				@case (2) {
					<div>
						<mat-grid-list cols="2" rowHeight="1:1" gutterSize="8">
							<mat-grid-tile>
								<img [src]="imageUrls()[0]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(0)" />
							</mat-grid-tile>
							<mat-grid-tile>
								<img [src]="imageUrls()[1]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(1)" />
							</mat-grid-tile>
						</mat-grid-list>
					</div>
				}
				@case (3) {
					<div>
						<mat-grid-list cols="2" rowHeight="1:1" gutterSize="8">
							<mat-grid-tile colspan="2" rowspan="2">
								<img [src]="imageUrls()[0]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(0)" />
							</mat-grid-tile>
							<mat-grid-tile>
								<img [src]="imageUrls()[1]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(1)" />
							</mat-grid-tile>
							<mat-grid-tile>
								<img [src]="imageUrls()[2]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(2)" />
							</mat-grid-tile>
						</mat-grid-list>
					</div>
				}
				@case (4) {
					<div>
						<mat-grid-list cols="3" rowHeight="1:1" gutterSize="8">
							<mat-grid-tile colspan="3" rowspan="3">
								<img [src]="imageUrls()[0]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(0)" />
							</mat-grid-tile>
							<mat-grid-tile>
								<img [src]="imageUrls()[1]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(1)" />
							</mat-grid-tile>
							<mat-grid-tile>
								<img [src]="imageUrls()[2]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(2)" />
							</mat-grid-tile>
							<mat-grid-tile>
								<img [src]="imageUrls()[3]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(3)" />
							</mat-grid-tile>
						</mat-grid-list>
					</div>
				}
				@case (5) {
					<div>
						<mat-grid-list cols="6" gutterSize="8">
							<mat-grid-tile colspan="3" rowspan="3">
								<img [src]="imageUrls()[0]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(0)" />
							</mat-grid-tile>
							<mat-grid-tile colspan="3" rowspan="3">
								<img [src]="imageUrls()[1]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(1)" />
							</mat-grid-tile>
							<mat-grid-tile colspan="2" rowspan="2">
								<img [src]="imageUrls()[2]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(2)" />
							</mat-grid-tile>
							<mat-grid-tile colspan="2" rowspan="2">
								<img [src]="imageUrls()[3]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(3)" />
							</mat-grid-tile>
							<mat-grid-tile colspan="2" rowspan="2">
								<img [src]="imageUrls()[4]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(4)" />
							</mat-grid-tile>
						</mat-grid-list>
					</div>
				}
				@default {
					<div>
						<mat-grid-list cols="6" rowHeight="1:1" gutterSize="8">
							<mat-grid-tile colspan="3" rowspan="3">
								<img [src]="imageUrls()[0]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(0)" />
							</mat-grid-tile>
							<mat-grid-tile colspan="3" rowspan="3">
								<img [src]="imageUrls()[1]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(1)" />
							</mat-grid-tile>
							<mat-grid-tile colspan="2" rowspan="2">
								<img [src]="imageUrls()[2]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(2)" />
							</mat-grid-tile>
							<mat-grid-tile colspan="2" rowspan="2">
								<img [src]="imageUrls()[3]" class="h-full object-cover rounded-md" loading="lazy" (click)="onImageSelected(3)" />
							</mat-grid-tile>
							<mat-grid-tile colspan="2" rowspan="2" class="bg-black">
								<img [src]="imageUrls()[4]" class="h-full object-cover opacity-50 justify-center rounded-md" loading="lazy" (click)="onImageSelected(4)" />
								<div class="absolute flex flex-row">
									<div class="flex flex-col">
										<div class="h-full w-full z-10 text-white text-5xl font-medium">+ {{ imageUrls().length - 4 }}</div>
									</div>
								</div>
							</mat-grid-tile>
						</mat-grid-list>
					</div>
				}
			}
		</div>
	</div>
}
