@if (isLoading()) {
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

<sidebar-view #sidebarView>
	<ng-container header>
		<ui-page-header
			[title]="'Routine Templates'"
			[subtitle]="selectedRoutineFilterType | enumDescription : routineFilterTypeDescriptions"
			(onToggleNavigation)="toggleNavigation()">
		</ui-page-header>
	</ng-container>

	<ng-container navigation>
		<training-employee-routine-navigation [allRoutines]="allRoutines()" (onCreateRoutine)="showCreateSidebar()" (onFilterChange)="changeRoutineFilter($event)">
		</training-employee-routine-navigation>
	</ng-container>

	<ng-container create-sidebar>
		<training-routine-create-sidebar (onCreate)="createRoutine($event)" (onCancel)="closeSidebar()"> </training-routine-create-sidebar>
	</ng-container>

	<ng-container edit-sidebar>
		<training-routine-edit-sidebar [routine]="selectedRoutine()" (onUpdate)="updateRoutine($event)" (onCancel)="closeSidebar()"> </training-routine-edit-sidebar>
	</ng-container>

	<ng-container content>
		@if (!isLoading()) {
			<div>
				<div class="mb-2 overflow-x-clip">
					<ng-container>
						<drag-drop-card-container
							#dragDropCards
							[cardTemplate]="routine"
							[cards]="filteredRoutines()"
							[dragHandleTop]="'28px'"
							[dragHandleLeft]="'16px'"
							[isDragDropEnabled]="selectedRoutineFilterType() === routineFilterTypes.MyRoutines"
							(onCardReorder)="updateRoutines()">
							<ng-template let-card #routine>
								<training-routine-template-card
									[routine]="card"
									[canChangeStatus]="card.isEmployeeRoutine"
									[canEdit]="card.isEmployeeRoutine"
									[canStartWorkout]="'false'"
									(onActiveChanged)="changeActive()"
									(onEdit)="showEditSidebar($event)"
									(onSelect)="selectRoutine($event)">
								</training-routine-template-card>
							</ng-template>
						</drag-drop-card-container>
					</ng-container>

					@if (!filteredRoutines().length) {
						<div class="flex flex-col m-12 items-center">
							<div class="flex flex-row">No {{ selectedRoutineFilterType() | enumDescription:routineFilterTypeDescriptions}}</div>
						</div>
					}
				</div>
			</div>
		}
	</ng-container>
</sidebar-view>
