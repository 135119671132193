import { Component, input, inject } from '@angular/core';

import { ElapsedTimePipe } from '@abp/pipes/date-time/elapsed-time.pipe';

import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { WorkoutResistanceExerciseViewModel } from '@fitness-central/shared/components/training/workout/view-models/workout-resistance-exercise.view-model';

import { FuseCardComponent } from '@abp/components/card';

@Component
(
	{
		selector: 'training-history-cards-exercise',
		templateUrl: './exercise.component.html',
		imports: [
			FuseCardComponent,
			ElapsedTimePipe
		]
	}
)

export class TrainingHistoryExerciseCardComponent
{
	private readonly _sessionStorageHelper = inject(SessionStorageHelper);

	public resistanceExercise = input<WorkoutResistanceExerciseViewModel>();

	public measurementSystem: number = 0;

	constructor
	()
	{
		const _sessionStorageHelper = this._sessionStorageHelper;

		this.measurementSystem = _sessionStorageHelper.measurementSystem();
	}
}
