<fuse-card class="flex flex-col min-w-70 m-2 sm:mx-4 w-100-p">
	<ng-container content>
		<div class="flex flex-row p-4">
			<div class="flex flex-col pr-4 justify-center">
				@switch (workout().status) {
					@case (trainingStatus.InProgress) {
						<mat-icon class="icon-size-10 text-blue-500" [matTooltip]="'Workout In Progress'"> schedule </mat-icon>
					}
					@case (trainingStatus.Completed) {
						<mat-icon class="icon-size-10 text-green-500" [matTooltip]="'Completed Workout'"> check_circle </mat-icon>
					}
					@case (trainingStatus.Cancelled) {
						<mat-icon class="icon-size-10 text-red-500" [matTooltip]="'Cancelled Workout'"> cancel </mat-icon>
					}
					@case (trainingStatus.Abandoned) {
						<mat-icon class="icon-size-10 text-gray-500" [matTooltip]="'Abandoned Workout'"> cancel </mat-icon>
					}
				}
			</div>

			<div class="flex flex-col w-11/12">
				<div class="font-medium">
					{{ workout().name }}
					@if (workout().version) {
						<span> - {{ workout().version }} </span>
					}
				</div>
				<div class="flex flex-col sm:flex-row sm:items-center -ml-0.5 mt-2 sm:mt-1 space-y-1 sm:space-y-0 sm:space-x-3">
					<ng-container>
						<div class="flex items-center">
							<mat-icon class="icon-size-5 text-hint"> event </mat-icon>
							<div class="ml-1.5 text-md text-secondary">
								{{ workout().createdDate | date }}
							</div>
						</div>
					</ng-container>
				</div>
			</div>

			<div class="flex flex-col justify-center w-1/12">
				<div class="flex flex-row justify-end pr-2 mt-1">
					<button mat-icon-button (click)="toggleActionBarVisibility()">
						<mat-icon class="text-gray-300"> more_vert </mat-icon>
					</button>
				</div>
			</div>
		</div>
		@if (isActionBarVisible()) {
			<div class="flex flex-col items text-md font-medium bg-accent text-on-accent">
				<div class="flex flex-row justify-end">
					<div class="flex flex-col justify-center">
						<div class="flex flex-row space-x-4 p-2">
							<button mat-icon-button (click)="routeToDetail(workout())">
								<mat-icon class="text-blue-400" [matTooltip]="'Workout Detail'"> library_books </mat-icon>
							</button>

							@if (workout().status === trainingStatus.InProgress && workout().routineTypeId === routineType.Template) {
								<button mat-icon-button (click)="continueWorkout(workout())">
									<mat-icon class="text-green-400" [matTooltip]="'Continue Workout'"> play_circle </mat-icon>
								</button>
							}
						</div>
					</div>
				</div>
			</div>
		}
	</ng-container>
</fuse-card>
