import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { ClientDetailTrainingExerciseDetailComponent } from './exercise/detail/detail.component';
import { ClientDetailTrainingRoutineDetailComponent } from './routine/detail/detail.component';
import { ClientDetailTrainingRoutineListComponent } from './routine/list/list.component';

export const clientRoutineTemplateRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'routine/list'
	},
	{
		path: 'routine/list',
		canActivate: [AuthGuard],
		component: ClientDetailTrainingRoutineListComponent,
	},
	{
		path: 'routine/:routineId/detail',
		canActivate: [AuthGuard],
		component: ClientDetailTrainingRoutineDetailComponent,
		data:
		{
			isClientRoutine: true
		},
	},
	{
		path: 'exercise/:resistanceExerciseId/detail',
		canActivate: [AuthGuard],
		component: ClientDetailTrainingExerciseDetailComponent,
		data:
		{
			isClientExercise: true
		},
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
