import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { employeeSettingsRoutes } from './settings/settings.routes';
import { employeeTrainingRoutes } from './training/training.routes';

export const employeeRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'locations'
	},
	{
		path: 'locations',
		loadComponent: () => import('./locations/locations.component').then(c => c.EmployeeLocationsComponent),
		canActivate: [AuthGuard]
	},
	{
		path: 'settings',
		children:
		[
			...employeeSettingsRoutes
		],
		canActivate: [AuthGuard]
	},
	{
		path: 'training',
		canActivate: [AuthGuard],
		children:
		[
			...employeeTrainingRoutes
		],
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
