/* eslint-disable @typescript-eslint/no-explicit-any */
import { CdkDrag, CdkDragEnter, CdkDragHandle, CdkDropList, CdkDropListGroup, DragRef, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgStyle, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, input, output, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component
(
	{
		selector: 'drag-drop-card-container',
		templateUrl: './drag-drop-card-container.component.html',
		styleUrls: ['./drag-drop-card-container.component.scss'],
		changeDetection: ChangeDetectionStrategy.OnPush,
		imports: [CdkDropListGroup, NgStyle, CdkDropList, CdkDrag, MatIconModule, CdkDragHandle, NgTemplateOutlet]
	}
)

export class DragDropCardContainerComponent implements AfterViewInit
{
	readonly cardTemplate = input<any>(undefined);
	readonly cards = input<any[]>([]);
	readonly isDragDropEnabled = input<boolean>(true);
	readonly dragHandleTop = input<string>('0px');
	readonly dragHandleLeft = input<string>('0px');

	readonly onCardReorder = output();

	readonly placeholder = viewChild(CdkDropList);

	private target: CdkDropList = null;
	private targetIndex: number;
	private source: CdkDropList = null;
	private sourceIndex: number;
	private dragRef: DragRef = null;

	public ngAfterViewInit()
	{
		const placeholderElement = this.placeholder().element.nativeElement;

		placeholderElement.style.display = 'none';
		placeholderElement.parentNode.removeChild(placeholderElement);
	}

	public onDropListDropped()
	{
		if (!this.target)
		{
			return;
		}

		const placeholderElement: HTMLElement = this.placeholder().element.nativeElement;
		const placeholderParentElement: HTMLElement = placeholderElement.parentElement;

		placeholderElement.style.display = 'none';

		placeholderParentElement.removeChild(placeholderElement);
		placeholderParentElement.appendChild(placeholderElement);
		placeholderParentElement.insertBefore
		(
			this.source.element.nativeElement,
			placeholderParentElement.children[this.sourceIndex]
		);

		const placeholder = this.placeholder();
		if (placeholder._dropListRef.isDragging())
		{
			placeholder._dropListRef.exit(this.dragRef);
		}

		this.target = null;
		this.source = null;
		this.dragRef = null;

		if (this.sourceIndex !== this.targetIndex)
		{
			const cards = this.cards();
			moveItemInArray(cards, this.sourceIndex, this.targetIndex);

			cards
				.forEach
				(
					(card) =>
					{
						card.order = this.cards().indexOf(card) + 1;
					}
				);

			this.onCardReorder.emit();
		}
	}

	public onDropListEntered({ item, container }: CdkDragEnter)
	{
		const placeholder = this.placeholder();
		if (container === placeholder)
		{
			return;
		}

		const placeholderElement: HTMLElement = placeholder.element.nativeElement;
		const sourceElement: HTMLElement = item.dropContainer.element.nativeElement;
		const dropElement: HTMLElement = container.element.nativeElement;

		const dragIndex: number = Array.prototype.indexOf.call
		(
			dropElement.parentElement.children,
			this.source ? placeholderElement : sourceElement
		);

		const dropIndex: number = Array.prototype.indexOf.call
		(
			dropElement.parentElement.children,
			dropElement
		);

		if (!this.source)
		{
			this.sourceIndex = dragIndex;
			this.source = item.dropContainer;

			sourceElement.parentElement.removeChild(sourceElement);
		}

		this.targetIndex = dropIndex;
		this.target = container;
		this.dragRef = item._dragRef;

		placeholderElement.style.display = '';

		dropElement.parentElement.insertBefore
		(
			placeholderElement,
			dropIndex > dragIndex ? dropElement.nextSibling : dropElement
		);

		placeholder._dropListRef.enter
		(
			item._dragRef,
			item.element.nativeElement.offsetLeft,
			item.element.nativeElement.offsetTop
		);
	}
}
