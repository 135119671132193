<sidebar-view #sidebarView>
	<ng-container header>
		<ui-page-header [title]="'Training History'" (onToggleNavigation)="sidebarView.toggleNavigation()"></ui-page-header>
	</ng-container>

	<ng-container navigation>
		<member-training-history-navigation [workouts]="workouts"></member-training-history-navigation>
	</ng-container>

	<ng-container content>
		<div class="flex flex-col m-2">
			@for (workout of workouts(); track workout)
			{
				<training-history-cards-workout [workout]="workout" (onContinue)="continueWorkout($event)" (onSelect)="routeToDetail($event)"></training-history-cards-workout>
			}
			@empty
			{
			<div class="flex flex-col m-12 items-center">
				<div class="flex flex-row">No Workouts found for Date Range</div>
			</div>
			}
		</div>
	</ng-container>
</sidebar-view>
