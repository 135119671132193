<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
	class="dark bg-gray-900 print:hidden"
	[mode]="isScreenSmall ? 'over' : 'side'"
	[name]="'mainNavigation'"
	[navigation]="navigation.default"
	[opened]="!isScreenSmall">
	<!-- Navigation header hook -->
	<ng-container fuseVerticalNavigationContentHeader>
		<div class="flex w-full items-center p-4 pl-6">
			<!-- Logo -->
			<div class="flex items-center justify-center">
				<img class="w-40" src="images/logo/logo-white-text.svg" alt="Fitness Central Logo" />
			</div>
			<!-- Components -->
			<div class="ml-auto flex items-center">
				<button mat-icon-button (click)="toggleProfileSidebar()">
					<span class="relative">
						<div class="h-8 w-8 rounded-full">
							<default-profile-image [profileUrl]="profileImageUrl()" [displayName]="displayName()"></default-profile-image>
						</div>
					</span>
				</button>
			</div>
		</div>
		<!-- User -->
		<div class="flex w-full flex-col items-center p-4">
			<div class="relative h-24 w-24">
				<default-profile-image [profileUrl]="profileImageUrl()" [displayName]="displayName()"></default-profile-image>
			</div>
			<div class="mt-6 flex w-full flex-col items-center justify-center">
				<div class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-center font-medium leading-normal">{{ displayName() }}</div>
				<div class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-center font-medium leading-normal">{{ handle }}</div>
				<div class="text-secondary mt-0.5 w-full overflow-hidden text-ellipsis whitespace-nowrap text-center text-md font-medium leading-normal">{{ userName }}</div>
			</div>
		</div>
	</ng-container>

	<!-- Navigation footer hook -->
	<ng-container fuseVerticalNavigationContentFooter>
		<div class="mb-4 mt-2 flex h-16 flex-0 items-center justify-center pl-2 pr-6 opacity-12">
			<img class="max-w-36" src="images/logo/logo-white-text.svg" alt="Fitness Central Logo Dark" />
		</div>
	</ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
	<!-- Header -->
	<div class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden">
		<!-- Navigation toggle button -->
		<button mat-icon-button (click)="toggleNavigation('mainNavigation')">
			<mat-icon>menu</mat-icon>
		</button>

		<!-- Components -->
		<div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
			<fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
			<button mat-icon-button class="text-on-accent" (click)="toggleMessageSidebar()">
				@if (!isNew) {
					@if (unreadMessageCount() === 0) {
						<ng-container>
							<mat-icon>mail</mat-icon>
						</ng-container>
					}
				} @else {
					@if (unreadMessageCount() > 0) {
						<ng-container>
							<mat-icon class="text-primary">mail</mat-icon>
							<mat-icon class="fixed text-red-600 icon-size-3 ml-4 mb-4">circle</mat-icon>
						</ng-container>
					}
				}
			</button>
		</div>
	</div>

	<mat-drawer-container class="flex-auto h-full w-full bg-gray-200">
		<mat-drawer #sidebar class="w-full md:w-128 bg-default" [autoFocus]="false" [mode]="'over'" [opened]="false" [position]="'end'" [disableClose]="false">
			@if (sidebarAction==='Messages') {
				<ng-container>
					<layout-message-sidebar [unreadMessageCount]="unreadMessageCount" (onCancel)="closeSidebar()"></layout-message-sidebar>
				</ng-container>
			}

			@if (sidebarAction==='Profile') {
				<ng-container>
					<layout-user-sidebar (onCancel)="closeSidebar()"></layout-user-sidebar>
				</ng-container>
			}
		</mat-drawer>

		<mat-drawer-content class="flex flex-col center">
			<!-- Content -->
			<div class="flex flex-auto justify-center w-full sm:p-6 md:p-8">
				<div class="flex flex-col flex-auto w-full sm:max-w-360 sm:shadow-lg sm:rounded-lg sm:overflow-hidden bg-default">
					<!-- @if(true) hack is required here for router-outlet to work correctly. Otherwise, layout changes won't be registered and the view won't be updated! -->
					@if (true) {
						<router-outlet></router-outlet>
					}
				</div>
			</div>

			<!-- Footer -->
			<div class="relative hidden sm:flex flex-0 justify-center w-full px-6 md:px-8 z-49 border-t bg-card print:hidden">
				<div class="flex flex-col sm:flex-row items-center w-full m-2">
					<div class="font-medium text-secondary">Fitness Central &copy; 2021 - {{currentYear}}</div>
					<div class="font-medium text-secondary hidden sm:flex mx-2">-</div>
					<div class="font-medium text-secondary">
						<a href="https://www.alphabusinesspartners.com">Powered by Alpha Business Partners</a>
					</div>
				</div>
			</div>
		</mat-drawer-content>
	</mat-drawer-container>
</div>
