import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const memberSettingsRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'account'
	},
	{
		path: 'account',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/settings/account/account.component').then(c => c.MemberSettingsAccountComponent),
	},
	{
		path: 'community-profile',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/settings/community-profile/community-profile.component').then(c => c.MemberSettingsCommunityProfileComponent),
	},
	{
		path: 'finished',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/settings/finished/finished.component').then(c => c.MemberSettingsFinishedComponent),
	},
	{
		path: 'preferences',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/settings/preferences/preferences.component').then(c => c.MemberSettingsPreferencesComponent),
	},
	{
		path: 'welcome',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/settings/welcome/welcome.component').then(c => c.MemberSettingsWelcomeComponent),
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
