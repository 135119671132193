export enum StatusType
{
	Inactive = 0,
	Active = 1
}

export const StatusTypeDescriptions: Record<StatusType, string> = 
{
	[StatusType.Inactive]: 'Inactive',
	[StatusType.Active]: 'Active'
};
