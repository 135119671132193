import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClientBodyWeightResponseModel } from '@fitness-central/api/client/body-composition/body-weight/body-weight-response.model';
import { ClientBodyWeightService } from '@fitness-central/api/client/body-composition/body-weight/body-weight.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'client-detail-body-composition-body-weight-sidebar-delete',
    templateUrl: './delete.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatButtonModule, MatIconModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatTooltipModule, NgIf, MatProgressSpinnerModule]
})

export class ClientDetailBodyCompositionBodyWeightSidebarDeleteComponent implements OnChanges, OnDestroy
{
	@Input() bodyWeightMeasurement : ClientBodyWeightResponseModel;

	@Output() onDeleted = new EventEmitter();
	@Output() onCancel = new EventEmitter();

	@ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

	public deleteForm : FormGroup;

	private _unsubscribeAll: Subject<any>;

	public constructor
	(
		private _formBuilder : FormBuilder,
		private _bodyWeightMeasurementService : ClientBodyWeightService
	)
	{
		this._unsubscribeAll = new Subject<any>();
	}

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	private buildForm()
	{
		this.deleteForm = this._formBuilder.group
		(
			{
				bodyWeightMeasurementId: [this.bodyWeightMeasurement.bodyWeightMeasurementId],
				value: [{value: this.bodyWeightMeasurement.value, disabled: true}],
				measurementDate: [{value: this.bodyWeightMeasurement.measurementDate, disabled: true}]
			}
		);
	}

	public delete()
	{
		this.deleteForm.disable();

		this._bodyWeightMeasurementService
			.delete(this.bodyWeightMeasurement.bodyWeightMeasurementId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						setTimeout
						(
							() =>
							{
								this.buildForm();
								this.formGroupDirective.resetForm();
							}
						);

						this.onDeleted.emit(this.bodyWeightMeasurement);
					}
				}
			);
	}

	public cancel()
	{
		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective.resetForm();
			}
		);

		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
