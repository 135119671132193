import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Translation, TranslocoLoader } from '@ngneat/transloco';

import { Observable } from 'rxjs';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class TranslocoHttpLoader implements TranslocoLoader
{
	public constructor
	(
		private _httpClient: HttpClient)
	{
	}

	public getTranslation(lang: string): Observable<Translation>
	{
		return this.
			_httpClient
			.get<Translation>(`./i18n/${lang}.json`);
	}
}
