import { TextFieldModule } from '@angular/cdk/text-field';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SecurityContext, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field'; import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer } from '@angular/platform-browser';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';
import { ImageGridComponent } from '@abp/components/image-grid/image-grid.component';
import { AbpFileDragAndDropDirective } from '@abp/directives/file-drag-and-drop/file-drag-and-drop.directive';

import { PostImageRequest } from '@fitness-central/api/community/post-image/post-image.request-model';
import { PostImageResponse } from '@fitness-central/api/community/post-image/post-image.response-model';
import { PostImageService } from '@fitness-central/api/community/post-image/post-image.service';
import { PostResponse } from '@fitness-central/api/community/post/post.response-model';
import { PostService } from '@fitness-central/api/community/post/post.service';

import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';

import { PostType } from '../../_enums/profile-post-type.enum';
import { PostSidebarViewModel } from './post-sidebar.view-model';

@Component
(
	{
		selector: 'profile-post-sidebar',
		templateUrl: './post-sidebar.component.html',
		standalone: true,
		imports:
		[
			MatButtonModule,
			MatIconModule,
			DefaultProfileImageComponent,
			FormsModule,
			ReactiveFormsModule,
			MatFormFieldModule,
			MatInputModule,
			TextFieldModule,
			NgIf,
			NgSwitch,
			NgSwitchCase,
			AbpFileDragAndDropDirective,
			ImageGridComponent,
			MatProgressBarModule,
			MatProgressSpinnerModule
		]
	}
)

export class CommunityPostSidebarComponent implements OnChanges
{
	@Input() postSidebarViewModel: PostSidebarViewModel = new PostSidebarViewModel();
	@Output() readonly closeSidebarOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() readonly postCreatedOutput: EventEmitter<PostResponse> = new EventEmitter<PostResponse>();

	@ViewChild("fileDrop", { static: false }) fileDropElement: ElementRef;

	public fromPage: string;
	public fromDialog: string;

	public files: File[] = [];
	public fileUrls: any[] = [];

	public postForm: FormGroup;
	public selectedPostType: PostType;
	public postType: typeof PostType = PostType;

	public post: PostResponse;

	public postSubmitted: boolean = false;

	private postImageTasks: Observable<PostImageResponse>[] = [];

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
	(
		private _sanitizer: DomSanitizer,
		private _formBuilder: FormBuilder,
		private _postService: PostService,
		private _postImageService: PostImageService
	)
	{
	}

	public ngOnChanges(changes: SimpleChanges): void
	{
		this.files = [];
		this.fileUrls = [];
		this.selectedPostType = this.postSidebarViewModel.postType;
		this.buildPostForm();
	}

	public createPost()
	{
		this.postSubmitted = true;

		this.postForm.value['content'] = this._sanitizer.sanitize(SecurityContext.HTML, this.postForm.value['content']);

		this._postService
			.create(this.postForm.value)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (post) =>
					{
						this.post = post;

						switch (this.selectedPostType)
						{
							case PostType.Comment:
								this.postCreatedOutput.emit(this.post);
								break;
							case PostType.Image:
								this.addPostImages(post);
								break;

							case PostType.Video:
								break;

							case PostType.Tag:
								break;

							case PostType.Emoji:
								break;
						}

						this.postSubmitted = false;
					}
				}
			)
	}

	private addPostImages(post: PostResponse)
	{
		this.files
			.forEach
			(
				postImage =>
				{
					const request = new PostImageRequest();

					request.postId = post.postId;
					request.imageFile = postImage;

					this.postImageTasks
						.push
						(
							this._postImageService.create(request)
						);
				}
			);

		forkJoin(this.postImageTasks)
			.subscribe
			(
				{
					complete: () =>
					{
						this.postCreatedOutput.emit(this.post);
					}
				}
			);
	}

	public showImageContext()
	{
		this.selectedPostType = PostType.Image;
	}

	public closeSidebar()
	{
		this.closeSidebarOutput.emit(true);
	}

	public onFilesDropped($event)
	{
		this.buildFileList($event);
	}

	public onFilesSelected($event)
	{
		this.buildFileList($event.target.files);
	}

	public clearFiles()
	{
		this.fileUrls = [];
		this.files = [];
	}

	private buildPostForm()
	{
		this.postForm = this._formBuilder.group
		(
			{
				profileId: [this.postSidebarViewModel.profileId],
				ownerProfileId: [this.postSidebarViewModel.ownerProfileId],
				title: [''],
				content: ['', [Validators.required]],
				isActive: [true]
			}
		);
	}

	private buildFileList(fileList: FileList)
	{
		this.files = Array.from(fileList);

		this.files
			.forEach
			(
				file =>
				{
					const reader = new FileReader();

					reader.readAsDataURL(file);
					reader.onload = (_event) =>
					{
						var url = window.URL.createObjectURL(file);
						let safeImageFile = this._sanitizer.bypassSecurityTrustUrl(url);
						this.fileUrls.push(safeImageFile);
					}
				}
			)
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
