import { Route, Routes } from '@angular/router';
import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';
import { MemberProfileGuard } from '@fitness-central/core/authentication/guard/personal-profile-guard';
import { EmployeeProfileGuard } from '@fitness-central/core/authentication/guard/professional-profile-guard';
import { LayoutComponent } from '@fitness-central/layout/layout.component';

import { authenticationRoutes } from './authentication/authentication.routes';
import { communicationRoutes } from './communication/communication.routes';
import { communityRoutes } from './community/community.routes';
import { clientRoutes } from './employee/clients/clients.routes';
import { employeeRoutes } from './employee/employee.routes';
import { errorRoutes } from './error/error.routes';
import { memberRoutes } from './member/member.routes';
import { trainersRoutes } from './trainers/trainers.routes';

export const appRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'authentication/sign-in'
	},

	// Redirect signed-in user to the '/community'
	//
	// After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect' path. Below is another redirection for that path to redirect the user to the desired location. This is a small convenience to keep all main routes together here on this file.
	{
		path: 'signed-in-redirect',
		pathMatch: 'full',
		redirectTo: 'community'
	},
	{
		path: 'authentication',
		component: LayoutComponent,
		data:
		{
			layout: 'empty'
		},
		children:
		[
			...authenticationRoutes
		]
	},

	// Admin routes
	{
		path: '',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: LayoutComponent,
		children:
		[
			{
				path: 'community',
				children:
				[
					...communityRoutes
				]
			},
			{
				path: 'communication',
				children:
				[
					...communicationRoutes
				]
			},
			{
				path: 'client',
				children:
				[
					...clientRoutes
				]
			},
			{
				path: 'employee',
				canActivate: [EmployeeProfileGuard],
				children:
				[
					...employeeRoutes
				]
			},
			{
				path: 'member',
				canActivate: [MemberProfileGuard],
				children:
				[
					...memberRoutes
				]
			},
			{
				path: 'trainers',
				canActivate: [MemberProfileGuard],
				children:
				[
					...trainersRoutes
				]
			},
		]
	},
	{
		path: 'error',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: LayoutComponent,
		children:
		[
			...errorRoutes
		]
	},
	{
		path: '**',
		redirectTo: 'authentication/sign-in'
	}
] as Routes;
