import { appRoutes } from 'app/app.routes';

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, inject, provideEnvironmentInitializer } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withComponentInputBinding, withInMemoryScrolling, withPreloading } from '@angular/router';
import { ErrorInterceptor } from '@fitness-central/core/authentication/interceptor/error.interceptor';
import { JwtInterceptor } from '@fitness-central/core/authentication/interceptor/jwt.interceptor';
import { TokenRefreshService } from '@fitness-central/core/authentication/token-refresh.service';
import { IconsService } from '@fitness-central/core/icons/icons.service';
import { provideFuse } from '@fuse';

export const appConfig: ApplicationConfig =
{
	providers:
	[
		provideAnimations(),
		provideHttpClient
		(
			withInterceptors([ErrorInterceptor, JwtInterceptor])
		),
		provideRouter
		(
			appRoutes,
			withPreloading(PreloadAllModules),
			withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
			withComponentInputBinding()
		),

		// Material Date Adapter
		{
			provide: DateAdapter,
			useClass: LuxonDateAdapter,
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue:
			{
				parse:
				{
					dateInput: 'MM/dd/yyyy',
				},
				display:
				{
					dateInput: 'MM/dd/yyyy',
					monthYearLabel: 'LLL yyyy',
					dateA11yLabel: 'MM/dd/yyyy',
					monthYearA11yLabel: 'LLLL yyyy',
				},
			},
		},

		// Fuse
		provideEnvironmentInitializer(() => inject(IconsService)),
		provideFuse
		(
			{
				fuse:
				{
					layout: 'classy',
					scheme: 'light',
					screens:
					{
						sm: '600px',
						md: '960px',
						lg: '1280px',
						xl: '1440px',
					},
					theme: 'theme-default',
					themes:
					[
						{
							id: 'theme-default',
							name: 'Default',
						},
					],
				},
			}
		),
		TokenRefreshService
	],
};
