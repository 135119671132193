import { Location } from '@angular/common';
import { Component, input, OnDestroy, OnInit, Signal, signal, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';

import { DragDropCardContainerComponent } from '@abp/components/drag-drop-card-container/drag-drop-card-container.component';
import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { EnumDescriptionPipe } from '@abp/pipes/enum/enum-description.pipe';
import { ClientDetailNavigationMenuItemType } from 'app/employee/clients/detail/_components/navigation/models/menu-item-types.enum';
import { ClientDetailMessageService } from 'app/employee/clients/detail/_message-services/detail.message-service';

import { ClientResponseModel } from '@fitness-central/api/client/client/client-response.model';
import { ClientRoutineResistanceExerciseSetService } from '@fitness-central/api/client/training/routine-resistance-exercise-set/routine-resistance-exercise-set.service';
import { ClientRoutineResistanceExerciseResponseModel } from '@fitness-central/api/client/training/routine-resistance-exercise/routine-resistance-exercise.response-model';
import { ClientRoutineResistanceExerciseService } from '@fitness-central/api/client/training/routine-resistance-exercise/routine-resistance-exercise.service';
import { ClientRoutineService } from '@fitness-central/api/client/training/routine/routine.service';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { TrainingRoutineResistanceExerciseCardComponent } from '@fitness-central/shared/components/training/routine-templates/cards/exercise/card.component';
import { TrainingRoutineResistanceExerciseCreateSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/exercise/create/create.component';
import { TrainingRoutineResistanceExerciseEditSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/exercise/edit/edit.component';
import { TrainingRoutineEditSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/routine/edit/edit.component';
import { ContentHeaderComponent } from '@fitness-central/shared/components/ui/content-header/content-header.component';
import { PageHeaderComponent } from '@fitness-central/shared/components/ui/page-header/page-header.component';
import { StatusType, StatusTypeDescriptions } from '@fitness-central/shared/enum/status-type.enum';

import { concatMap, from, map, mergeMap, Subject, takeUntil } from 'rxjs';

import { RoutineTemplateResistanceExerciseViewModel } from '../../_view-models/routine-exercise.view-model';
import { RoutineTemplateViewModel } from '../../_view-models/routine.view-model';
import { ClientDetailTrainingRoutineDetailNavigationComponent } from './_components/navigation/navigation.component';
import { RoutineTemplateEditSidebarActionType } from './_enums/edit-sidebar-action-type.enum';

@Component({
	selector: 'client-detail-training-routine-detail-component',
	templateUrl: './detail.component.html',
	standalone: true,
	imports:
		[
			ContentHeaderComponent,
			DragDropCardContainerComponent,
			EnumDescriptionPipe,
			MatIconModule,
			MatButtonModule,
			MatProgressBarModule,
			MatTooltipModule,
			ClientDetailTrainingRoutineDetailNavigationComponent,
			PageHeaderComponent,
			SidebarViewComponent,
			TrainingRoutineEditSidebarComponent,
			TrainingRoutineResistanceExerciseCardComponent,
			TrainingRoutineResistanceExerciseCreateSidebarComponent,
			TrainingRoutineResistanceExerciseEditSidebarComponent,
		]
})

export class ClientDetailTrainingRoutineDetailComponent implements OnInit, OnDestroy
{
	public routineId = input<string>();

	public client: Signal<ClientResponseModel>;

	private sidebarView = viewChild(SidebarViewComponent);
	public routine = signal<RoutineTemplateViewModel>(new RoutineTemplateViewModel());

	public allExercises = signal<RoutineTemplateResistanceExerciseViewModel[]>([]);
	public filteredExercises = signal<RoutineTemplateResistanceExerciseViewModel[]>([]);

	public activeExercises: RoutineTemplateResistanceExerciseViewModel[] = [];
	public inactiveExercises: RoutineTemplateResistanceExerciseViewModel[] = [];

	public selectedExercise = signal<RoutineTemplateResistanceExerciseViewModel>(new RoutineTemplateResistanceExerciseViewModel());

	public exerciseFilterType = signal<StatusType>(StatusType.Active);
	public statusTypeDescriptions = StatusTypeDescriptions;

	public editSidebarAction: RoutineTemplateEditSidebarActionType;
	public editSidebarActionType = RoutineTemplateEditSidebarActionType;

	public measurementSystem: number;

	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
		(
			private readonly _clientDetailMessageService: ClientDetailMessageService,
			private readonly _clientRoutineResistanceExerciseService: ClientRoutineResistanceExerciseService,
			private readonly _clientRoutineResistanceExerciseSetService: ClientRoutineResistanceExerciseSetService,
			private readonly _clientRoutineService: ClientRoutineService,
			private readonly _sessionStorageHelper: SessionStorageHelper,
			private readonly _location: Location,
			private readonly _router: Router
		)
	{
		this._clientDetailMessageService.selectedNavigationItemType.set(ClientDetailNavigationMenuItemType.Training);
		this.client = this._clientDetailMessageService.client;

		this.subscribeToNavigationChange();
	}

	public ngOnInit(): void
	{
		this.measurementSystem = this._sessionStorageHelper.measurementSystem();

		this.getClientRoutine();
	}

	public showCreateSidebar(): void
	{
		this.sidebarView().openCreateSidebar();
	}

	public showRoutineEditSidebar(): void
	{
		this.editSidebarAction = this.editSidebarActionType.EditRoutine;

		this.sidebarView().openEditSidebar();
	}

	public showExerciseEditSidebar(routineResistanceExercise: RoutineTemplateResistanceExerciseViewModel): void
	{
		this.selectedExercise.set(routineResistanceExercise);
		this.editSidebarAction = this.editSidebarActionType.EditResistanceExercise;

		this.sidebarView().openEditSidebar();
	}

	public closeSidebar(): void
	{
		this.sidebarView().closeSidebar();
	}

	public closeNavigation(): void
	{
		this.sidebarView().closeNavigation();
	}

	public updateCardOrder(): void
	{
		this.updateRoutineResistanceExercises();
	}

	public startWorkout()
	{
		this.closeNavigation();

		this._router.navigate([`client/${this.client().clientId}/workout/routine/${this.routine().routineId}`]);
	}

	public showActiveExercises(): void
	{
		this.closeNavigation();

		this.exerciseFilterType.set(StatusType.Active);
		this.filterExercises();
	}

	public showInactiveExercises(): void
	{
		this.closeNavigation();

		this.exerciseFilterType.set(StatusType.Inactive)
		this.filterExercises();
	}

	public changeActive(): void
	{
		this.filterExercises();

		this.activeExercises.forEach((routine, index) =>
		{
			routine.order = index + 1;
		});

		this.inactiveExercises.forEach((routine, index) =>
		{
			routine.order = index + 1;
		});

		this.updateRoutineResistanceExercises();
	}

	public selectExercise(exercise: RoutineTemplateResistanceExerciseViewModel): void
	{
		this._router.navigateByUrl(`client/${this.client().clientId}/training/routine-templates/exercise/${exercise.routineResistanceExerciseId}/detail`);
	}

	public routeToBack(): void
	{
		this._location.back();
	}

	public createExercise(routineResistanceExercise: ClientRoutineResistanceExerciseResponseModel): void
	{
		routineResistanceExercise.order = this.activeExercises.length + 1;

		this._clientRoutineResistanceExerciseService
			.create(routineResistanceExercise)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routineResistanceExercise =>
					{
						this.isLoading.set(false);

						this.closeSidebar();

						this._router.navigateByUrl(`/client/${this.client().clientId}/training/routine-templates/exercise/${routineResistanceExercise.routineResistanceExerciseId}/detail`);
					}
				}
			);
	}

	public updateRoutine(routine: RoutineTemplateViewModel): void
	{
		routine.clientId = this.client().clientId;

		this._clientRoutineService
			.update(routine)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routine =>
					{
						this.routine.set(routine);

						this.closeSidebar();
					}
				}
			);
	}

	public updateExercise(routineResistanceExercise: RoutineTemplateResistanceExerciseViewModel): void
	{
		this._clientRoutineResistanceExerciseService
			.update(routineResistanceExercise)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routineResistanceExercise =>
					{

						this.allExercises.update
							(
								exercises => exercises.map(item =>
								{
									const updatedItem = item.routineResistanceExerciseId === routineResistanceExercise.routineResistanceExerciseId
										? { ...routineResistanceExercise, routineTemplateResistanceExerciseSets: item.routineTemplateResistanceExerciseSets }
										: item;

									return updatedItem;
								})
							);

						this.filterExercises();

						this.isLoading.set(false);

						this.closeSidebar();
					}
				}
			);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	private subscribeToNavigationChange()
	{
		this._clientDetailMessageService
			.navigationOpenAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						this.sidebarView().toggleNavigation();
					}
				}
			)
	}

	private filterExercises(): void
	{
		this.activeExercises = this.allExercises()
			.filter(exercise => exercise.isActive)
			.sort((first, second) => first.order - second.order);

		this.inactiveExercises = this.allExercises()
			.filter(exercise => !exercise.isActive)
			.sort((first, second) => first.order - second.order);

		if (this.exerciseFilterType() === StatusType.Active)
		{
			this.filteredExercises.set(this.activeExercises);
		}
		else
		{
			this.filteredExercises.set(this.inactiveExercises);
		}
	}

	private getMemberRoutine(): void
	{
		this._clientRoutineService
			.get(this.routineId())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routine =>
					{
						this.routine.set(routine);

						this.getMemberRoutineResistanceExercisesWithSets();
					}
				}
			);
	}

	private getMemberRoutineResistanceExercisesWithSets(): void
	{
		this.allExercises.set([]);

		this._clientRoutineResistanceExerciseService
			.getByRoutineId(this.routineId())
			.pipe
			(
				takeUntil(this._unsubscribeAll),
				concatMap(routineResistanceExercises => from(routineResistanceExercises)),
				mergeMap
					(
						routineResistanceExercise => this._clientRoutineResistanceExerciseSetService.getByRoutineResistanceExerciseId(routineResistanceExercise.routineResistanceExerciseId)
							.pipe
							(
								map(routineResistanceExerciseSets =>
								{
									let filteredRoutineResistanceExerciseSets = routineResistanceExerciseSets
										.filter(routineResistanceExerciseSet => routineResistanceExerciseSet.isActive)
										.sort((first, second) => first.order - second.order);

									let routineWithExercisetSet: RoutineTemplateResistanceExerciseViewModel =
									{
										...routineResistanceExercise,
										routineTemplateResistanceExerciseSets: filteredRoutineResistanceExerciseSets
									};

									return routineWithExercisetSet;
								})
							)
					)
			)
			.subscribe
			(
				{
					next: routineResistanceExerciseWithSets =>
					{
						this.allExercises.update(exercises => [...exercises, routineResistanceExerciseWithSets])
					},
					complete: () =>
					{
						this.filterExercises();
						this.isLoading.set(false);
					}
				}
			);
	}

	private getClientRoutine(): void
	{
		this._clientRoutineService
			.get(this.routineId())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routine =>
					{
						this.routine.set(routine);

						this.getClientRoutineResistanceExercisesWithSets();
					}
				}
			);
	}


	private getClientRoutineResistanceExercisesWithSets(): void
	{
		this.allExercises.set([]);

		this._clientRoutineResistanceExerciseService
			.getByRoutineId(this.routineId())
			.pipe
			(
				takeUntil(this._unsubscribeAll),
				concatMap(routineResistanceExercises => from(routineResistanceExercises)),
				mergeMap
					(
						routineResistanceExercise => this._clientRoutineResistanceExerciseSetService.getByRoutineResistanceExerciseId(routineResistanceExercise.routineResistanceExerciseId)
							.pipe
							(
								map(routineResistanceExerciseSets =>
								{
									let filteredRoutineResistanceExerciseSets = routineResistanceExerciseSets
										.filter(routineResistanceExerciseSet => routineResistanceExerciseSet.isActive)
										.sort((first, second) => first.order - second.order);

									let routineWithExercisetSet = new RoutineTemplateResistanceExerciseViewModel();

									routineWithExercisetSet =
									{
										...routineResistanceExercise,
										routineTemplateResistanceExerciseSets: filteredRoutineResistanceExerciseSets
									};

									return routineWithExercisetSet;
								})
							)
					)
			)
			.subscribe
			(
				{
					next: routineResistanceExerciseWithSets =>
					{
						this.allExercises.update(exercises => [...exercises, routineResistanceExerciseWithSets])
					},
					complete: () =>
					{
						this.filterExercises();
						this.isLoading.set(false);
					}
				}
			);
	}

	private updateRoutineResistanceExercises(): void
	{
		this._clientRoutineResistanceExerciseService
			.updateRange(this.allExercises())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: routineResistanceExercises =>
					{
						this.allExercises.update
							(
								exercises => exercises.map
									(
										exercise =>
										{
											const updatedItem = routineResistanceExercises.find(updatedExercise => updatedExercise.routineResistanceExerciseId === exercise.routineResistanceExerciseId);

											return updatedItem ? { ...exercise, ...updatedItem } : exercise;
										}
									)
							);

						this.filterExercises();
					}
				}
			)
	}
}
