import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { OrganizationRoutineResistanceExerciseSetRequestModel } from './routine-resistance-exercise-set.request-model';
import { OrganizationRoutineResistanceExerciseSetResponseModel } from './routine-resistance-exercise-set.response-model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class OrganizationRoutineResistanceExerciseSetService
{
	private http = inject(HttpClient);


	public get(routineResistanceExerciseSetId: string): Observable<OrganizationRoutineResistanceExerciseSetResponseModel>
	{
		const url = `${ environment.baseUrl  }/organization/training/routine-resistance-exercise-sets/${ routineResistanceExerciseSetId }`;

		return this.http.get<OrganizationRoutineResistanceExerciseSetResponseModel>(url);
	}

	public getByRoutineResistanceExerciseId(routineResistanceExerciseId: string): Observable<OrganizationRoutineResistanceExerciseSetResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/organization/training/routine-resistance-exercise-sets/routine-resistance-exercise/${ routineResistanceExerciseId }`;

		return this.http.get<OrganizationRoutineResistanceExerciseSetResponseModel[]>(url);
	}

	public create(request: OrganizationRoutineResistanceExerciseSetRequestModel) : Observable<OrganizationRoutineResistanceExerciseSetResponseModel>
	{
		const url = `${ environment.baseUrl  }/organization/training/routine-resistance-exercise-set`;

		return this.http.post<OrganizationRoutineResistanceExerciseSetResponseModel>(url, request);
	}

	public update(request: OrganizationRoutineResistanceExerciseSetRequestModel) : Observable<OrganizationRoutineResistanceExerciseSetResponseModel>
	{
		const url = `${ environment.baseUrl  }/organization/training/routine-resistance-exercise-set/${ request.routineResistanceExerciseSetId }`;

		return this.http.put<OrganizationRoutineResistanceExerciseSetResponseModel>(url, request);
	}

	public updateRange(request: OrganizationRoutineResistanceExerciseSetRequestModel[]) : Observable<OrganizationRoutineResistanceExerciseSetResponseModel[]>
	{
		const url = `${ environment.baseUrl  }/organization/training/routine-resistance-exercise-sets`

		return this.http.put<OrganizationRoutineResistanceExerciseSetResponseModel[]>(url, request);
	}
}
