import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@abp/services/confirmation/confirmation.types';
import { FuseConfirmationDialogComponent } from '@abp/services/confirmation/dialog/dialog.component';
import { merge } from 'lodash-es';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class FuseConfirmationService
{
	private _matDialog: MatDialog = inject(MatDialog);
	private _defaultConfig: FuseConfirmationConfig =
		{
			title: 'Confirm action',
			message: 'Are you sure you want to confirm this action?',
			icon:
		{
			show: true,
			name: 'heroicons_outline:exclamation-triangle',
			color: 'warn',
		},
			actions:
		{
			confirm:
			{
				show: true,
				label: 'Confirm',
				color: 'warn',
			},
			cancel:
			{
				show: true,
				label: 'Cancel',
			},
		},
			dismissible: false,
		};

	publicopen(config: FuseConfirmationConfig = {}): MatDialogRef<FuseConfirmationDialogComponent>
	{
		// Merge the user config with the default config
		const userConfig = merge({}, this._defaultConfig, config);

		// Open the dialog
		return this._matDialog
			.open
			(
				FuseConfirmationDialogComponent,
				{
					autoFocus: false,
					disableClose: !userConfig.dismissible,
					data: userConfig,
					panelClass: 'fuse-confirmation-dialog-panel',
				}
			);
	}
}
