import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class TrainingWorkoutHistoryMessageService
{
	private displayActionBarSource = new Subject<string>();
	public displayActionBarAction$ = this.displayActionBarSource.asObservable();

	private readonly dateFilterChangedSource: BehaviorSubject<Date[]>;
	public readonly dateFilterChangedAction$: Observable<Date[]>;

	public constructor()
	{
		const defaultNumberOfDays: number = 30;

		const dateFilterEndDate: Date = new Date(Date.now());

		const date: Date = new Date(dateFilterEndDate.getFullYear(), dateFilterEndDate.getMonth(), dateFilterEndDate.getDate());

		const dateFilterStartDate = date;
		dateFilterStartDate.setDate(date.getDate() - defaultNumberOfDays);

		this.dateFilterChangedSource = new BehaviorSubject<Date[]>([dateFilterStartDate, dateFilterEndDate]);
		this.dateFilterChangedAction$ = this.dateFilterChangedSource.asObservable();
	}

	public displayActionbar(workoutId: string)
	{
		this.displayActionBarSource.next(workoutId);
	}

	public changeDateFilter(dateRange: Date[])
	{
		this.dateFilterChangedSource.next(dateRange);
	}
}
