import { FuseVerticalNavigationComponent } from '@abp/components/navigation';
import { FuseNavigationItem } from '@abp/components/navigation/navigation.types';
import { Component, OnChanges, output, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'client-detail-home-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['navigation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	imports: [FuseVerticalNavigationComponent]
})

export class ClientDetailHomeNavigationComponent implements OnChanges
{
	readonly onDetailEdit = output();
	readonly onContactCreate = output();

	public menuData: FuseNavigationItem[];

	public constructor()
	{
		this.buildNavigation();
	}

	public ngOnChanges()
	{
		this.buildNavigation();
	}

	private buildNavigation()
	{
		this.menuData =
			[
				{
					title: 'Actions',
					type: 'group',
					children:
						[
							{
								title: 'Edit Client Detail',
								type: 'basic',
								icon: 'edit',
								function: () =>
								{
									this.onDetailEdit.emit();
								}
							},
							{
								title: 'Add Contact Information',
								type: 'basic',
								icon: 'add_circle',
								function: () =>
								{
									this.onContactCreate.emit();
								}
							}
						]
				}
			];
	}
}
