import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { FuseCardComponent } from '@abp/components/card';

@Component
(
	{
		selector: 'global-user-sidebar-list-card',
		templateUrl: './list-card.component.html',
		standalone: true,
		imports:
		[
			FuseCardComponent,
			MatButtonModule,
			MatIconModule
		]
	}
)

export class GlobalUserSidebarListCardComponent
{
	@Input() icon: string;
	@Input() name: string;
	@Input() description: string;
	@Input() link: string;

	public constructor() { }
}
