import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { FuseLoadingBarComponent } from '@abp/components/loading-bar';

import { Subject } from 'rxjs';

@Component
(
	{
		selector: 'empty-layout',
		templateUrl: './empty.component.html',
		encapsulation: ViewEncapsulation.None,
		standalone: true,
		imports: [FuseLoadingBarComponent, RouterOutlet],
	}
)

export class EmptyLayoutComponent implements OnDestroy
{
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor()
	{
	}

	public ngOnDestroy(): void
	{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
