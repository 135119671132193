import { Component, inject, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';

import { DragDropCardContainerComponent } from '@abp/components/drag-drop-card-container/drag-drop-card-container.component';
import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { Guid } from '@abp/guid';
import { EnumDescriptionPipe } from '@abp/pipes/enum/enum-description.pipe';

import { ClientRoutineResponseModel } from '@fitness-central/api/client/training/routine/routine.response-model';
import { ClientRoutineService } from '@fitness-central/api/client/training/routine/routine.service';
import { MemberRoutineResponseModel } from '@fitness-central/api/member/training/routine/routine.response-model';
import { MemberRoutineService } from '@fitness-central/api/member/training/routine/routine.service';
import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { TrainingRoutineTemplateCardComponent } from '@fitness-central/shared/components/training/routine-templates/cards/routine/card.component';
import { TrainingRoutineCreateSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/routine/create/create.component';
import { TrainingRoutineEditSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/routine/edit/edit.component';
import { PageHeaderComponent } from '@fitness-central/shared/components/ui/page-header/page-header.component';
import { RoutineType } from '@fitness-central/shared/enum/routine-type.enum';

import { forkJoin, Subject, takeUntil } from 'rxjs';

import { MemberRoutineFilterTypeDescriptions, MemberRoutineTemplateFilterType } from '../../_enums/routine-filter-type.enum';
import { RoutineTemplateViewModel } from '../../_view-models/routine.view-model';
import { MemberTrainingRoutineNavigationComponent } from './_components/navigation/navigation.component';

@Component
(
	{
		selector: 'training-routine-list',
		templateUrl: './list.component.html',
		imports: [
			DragDropCardContainerComponent,
			EnumDescriptionPipe,
			MatIconModule,
			MatButtonModule,
			MatProgressBarModule,
			MatTooltipModule,
			MemberTrainingRoutineNavigationComponent,
			PageHeaderComponent,
			SidebarViewComponent,
			TrainingRoutineTemplateCardComponent,
			TrainingRoutineCreateSidebarComponent,
			TrainingRoutineEditSidebarComponent
		]
	}
)

export class TrainingMemberRoutineListComponent implements OnInit, OnDestroy
{
	private _clientRoutineService = inject(ClientRoutineService);
	private _jwtHelper = inject(JwtHelper);
	private _memberRoutineService = inject(MemberRoutineService);
	private _router = inject(Router);

	private sidebarView = viewChild(SidebarViewComponent)

	public filteredRoutines = signal<RoutineTemplateViewModel[]>([]);

	public allRoutines = signal<RoutineTemplateViewModel[]>([]);

	public allMemberRoutines: RoutineTemplateViewModel[] = [];
	public activeMemberRoutines: RoutineTemplateViewModel[] = [];
	public inactiveMemberRoutines: RoutineTemplateViewModel[] = [];

	public allClientRoutines: RoutineTemplateViewModel[] = [];
	public activeClientRoutines: RoutineTemplateViewModel[] = [];
	public inactiveClientRoutines: RoutineTemplateViewModel[] = [];

	public selectedRoutine = signal<RoutineTemplateViewModel>(new RoutineTemplateViewModel());

	public selectedRoutineFilterType = signal<MemberRoutineTemplateFilterType>(MemberRoutineTemplateFilterType.MyRoutines);

	public routineFilterTypes: typeof MemberRoutineTemplateFilterType = MemberRoutineTemplateFilterType;
	public routineFilterTypeDescriptions = MemberRoutineFilterTypeDescriptions;

	public activeMemberId: string = Guid.empty;

	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<void>  = new Subject<void>();

	public constructor
	()
	{
		this.activeMemberId = this._jwtHelper.memberId();
	}

	public ngOnInit(): void
	{
		this.getRoutines()
	}

	public showCreateSidebar(): void
	{
		this.sidebarView().openCreateSidebar();
	}

	public showEditSidebar(routine: RoutineTemplateViewModel): void
	{
		this.selectedRoutine.set(routine);
		this.sidebarView().openEditSidebar();
	}

	public closeSidebar(): void
	{
		this.sidebarView().closeSidebar();
	}

	public closeNavigation(): void
	{
		this.sidebarView().closeNavigation();
	}

	public toggleNavigation(): void
	{
		this.sidebarView().toggleNavigation();
	}

	public changeRoutineFilter(routineFilterType: MemberRoutineTemplateFilterType): void
	{
		this.closeNavigation();

		this.selectedRoutineFilterType.set(routineFilterType);
		this.filterRoutines();
	}

	public changeActive(): void
	{
		this.filterRoutines();

		this.activeMemberRoutines.forEach
		(
			(routine, index) =>
			{
				routine.order = index + 1;
			}
		);

		this.inactiveMemberRoutines.forEach
		(
			(routine, index) =>
			{
				routine.order = index + 1;
			}
		);

		this.updateRoutines();
	}

	public selectRoutine(routine: RoutineTemplateViewModel): void
	{
		if (routine.isMemberRoutine)
		{
			this._router.navigateByUrl(`/member/training/routine-templates/routine/${ routine.routineId }/detail`);
		}
		else
		{
			this._router.navigateByUrl(`/member/training/routine-templates/trainer-routine/${ routine.routineId }/detail`);
		}
	}

	public startWorkout(routine: RoutineTemplateViewModel): void
	{
		if (routine.isMemberRoutine)
		{
			this._router.navigateByUrl(`/member/training/workout/routine/${ routine.routineId }`);
		}
		else
		{
			this._router.navigateByUrl(`/client/${ routine.clientId }/training/workout/${ routine.routineId }`);
		}
	}


	public createRoutine(routine: RoutineTemplateViewModel): void
	{
		routine.order = this.activeMemberRoutines.length + 1;

		this._memberRoutineService
			.create(routine)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (routine) =>
					{
						this.isLoading.set(false);

						this.closeSidebar();

						this._router.navigateByUrl(`/member/training/routine-templates/routine/${ routine.routineId }/detail`);
					}
				}
			)
	}

	public updateRoutine(routine: RoutineTemplateViewModel): void
	{
		this._memberRoutineService
			.update(routine)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (routine) =>
					{
						const memberRoutine: RoutineTemplateViewModel =
						{
							...routine,
							isMemberRoutine: true,
							clientId: Guid.empty
						}

						this.allMemberRoutines = this.allMemberRoutines.map(item => item.routineId === memberRoutine.routineId ? { ...memberRoutine } : item);

						this.filterRoutines();

						this.closeSidebar();
					}
				}
			)
	}

	public updateRoutines(): void
	{
		this._memberRoutineService
			.updateRange(this.allMemberRoutines)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (memberRoutines) =>
					{
						this.allRoutines
							.update
							(
								routines => routines.map
								(
									(routine) =>
									{
										const updatedItem = memberRoutines.find(updatedRoutine => updatedRoutine.routineId === routine.routineId);

										return updatedItem ? { ...routine, ...updatedItem } : routine;
									}
								)
							);


						this.filterRoutines();
					}
				}
			)
	}

	private getRoutines(): void
	{
		const clientRoutines = this._clientRoutineService.getAll();
		const memberRoutines = this._memberRoutineService.getAll(RoutineType.Template);

		forkJoin
		(
			[
				clientRoutines,
				memberRoutines
			]
		)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next:
				(
					[
						clientRoutines,
						memberRoutines
					]
				) =>
				{
					this.buildClientRoutineTemplateViewModels(clientRoutines);
					this.buildMemberRoutineTemplateViewModels(memberRoutines);

					this.filterRoutines();
					this.isLoading.set(false);
				}
				}
			)
	}

	private buildClientRoutineTemplateViewModels(clientRoutines: ClientRoutineResponseModel[]): void
	{
		const routines: RoutineTemplateViewModel[] = [];

		clientRoutines.map
		(
			(clientRoutine) =>
			{
				const routine: RoutineTemplateViewModel =
				{
					...clientRoutine,
					isMemberRoutine: false,
					memberId: Guid.empty
				}

				routines.push(routine);
			}
		);

		this.allClientRoutines = routines;
		this.allRoutines.update(routines => [...routines, ...this.allClientRoutines])
	}

	private buildMemberRoutineTemplateViewModels(memberRoutines: MemberRoutineResponseModel[]): void
	{
		const routines: RoutineTemplateViewModel[] = [];

		memberRoutines.map
		(
			(memberRoutine) =>
			{
				const routine: RoutineTemplateViewModel =
				{
					...memberRoutine,
					isMemberRoutine: true,
					clientId: Guid.empty
				}

				routines.push(routine);
			}
		);

		this.allMemberRoutines = routines;
		this.allRoutines.update(routines => [...routines, ...this.allMemberRoutines])
	}

	private filterRoutines(): void
	{
		this.activeMemberRoutines = this.allMemberRoutines
			.filter(routine => routine.isActive)
			.sort((a, b) => a.order - b.order);

		this.activeClientRoutines = this.allClientRoutines
			.filter(routine => routine.isActive)
			.sort((a, b) => a.order - b.order);

		this.inactiveMemberRoutines = this.allMemberRoutines
			.filter(routine => !routine.isActive)
			.sort((a, b) => a.order - b.order);

		this.inactiveClientRoutines = this.allClientRoutines
			.filter(routine => !routine.isActive)
			.sort((a, b) => a.order - b.order);

		switch (this.selectedRoutineFilterType())
		{
		case MemberRoutineTemplateFilterType.MyRoutines:
		{
			this.filteredRoutines.set(this.activeMemberRoutines);

			break;
		}
		case MemberRoutineTemplateFilterType.TrainerRoutines:
		{
			this.filteredRoutines.set(this.activeClientRoutines);

			break;
		}
		case MemberRoutineTemplateFilterType.InactiveRoutines:
		{
			const inactiveRoutines = [];

			inactiveRoutines.push
			(
				...this.inactiveMemberRoutines,
				...this.inactiveClientRoutines
			)

			this.filteredRoutines.set(inactiveRoutines);

			break;
		}
		}
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
