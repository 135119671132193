import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field'; import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';

@Component
(
	{
		selector: 'profile-search',
		templateUrl: './search.component.html',
		standalone: true,
		imports:
		[
			FormsModule,
			ReactiveFormsModule,
			MatFormFieldModule,
			MatIconModule,
			MatInputModule,
			MatButtonModule
		]
	}
)

export class ProfileSearchComponent implements OnInit
{
	public memberSearchForm: FormGroup;

	public constructor
	(
		private _formBuilder: FormBuilder,
		private _router: Router
	)
	{
	}

	public ngOnInit()
	{
		this.buildMemberSearchForm();
	}

	public searchMembers()
	{
		let searchText = this.memberSearchForm.get('searchText').value;
		this._router.navigateByUrl(`/community/search/member?searchText=${searchText}`);
	}

	private buildMemberSearchForm()
	{
		this.memberSearchForm = this._formBuilder.group
		(
			{
				searchText: ['', [Validators.required]]
			}
		);
	}
}
