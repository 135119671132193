import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const employeeSettingsRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'account'
	},
	{
		path: 'account',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/employee/settings/account/account.component').then(c => c.EmployeeSettingsAccountComponent),
	},
	{
		path: 'community-profile',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/employee/settings/community-profile/community-profile.component').then(c => c.EmployeeSettingsCommunityProfileComponent),
	},
	{
		path: 'disciplines',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/employee/settings/discipline/discipline.component').then(c => c.EmployeeSettingsDisciplinesComponent),
	},
	{
		path: 'education',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/employee/settings/education/education.component').then(c => c.EmployeeSettingsEducationComponent),
	},
	{
		path: 'experience',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/employee/settings/experience/experience.component').then(c => c.EmployeeSettingsExperienceComponent),
	},
	{
		path: 'finished',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/employee/settings/finished/finished.component').then(c => c.EmployeeSettingsFinishedComponent),
	},
	{
		path: 'preferences',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/employee/settings/preferences/preferences.component').then(c => c.EmployeeSettingsPreferencesComponent),
	},
	{
		path: 'subscription',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/employee/settings/subscription/subscription.component').then(c => c.EmployeeSettingsSubscriptionComponent),
	},
	{
		path: 'welcome',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/employee/settings/welcome/welcome.component').then(c => c.EmployeeSettingsWelcomeComponent),
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
