import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';

import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { UserType } from '@fitness-central/shared/enum/user-type.enum';

export const MemberProfileGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
	const router: Router = inject(Router);
	const jwtHelper: JwtHelper = inject(JwtHelper);

	let IsMemberProfile: boolean;
	IsMemberProfile = jwtHelper.userType() == UserType.Member;

	if (IsMemberProfile === false)
	{
		router.navigateByUrl("error/404");
	}

	return true;
};
