import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BodyFatCalculationType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-calculation-type.enum';
import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';
import { ClientBodyFatMeasurementUpdateRequestModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-update-request.model';
import { ClientBodyFatSiteMeasurementRequestModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-site-measurement/body-fat-site-measurement-request.model';
import { ClientBodyFatSiteMeasurementType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-site-measurement/body-fat-site-measurement-type.enum';
import { GenderType } from '@fitness-central/api/general/gender-type/gender-type.enum';

import { Subject } from 'rxjs';

import { ClientBodyFatMeasurementViewModel } from '../../../../../_view-models/body-fat-measurement.view-model';

@Component({
	selector: 'client-detail-body-composition-body-fat-measurement-sidebar-edit-jackson-pollock-7-site',
	templateUrl: './jackson-pollock-7-site.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, NgSwitch, NgSwitchCase, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatButtonModule, MatTooltipModule, NgIf, MatProgressSpinnerModule]
})

export class ClientDetailBodyCompositionBodyFatMeasurementSidebarEditJacksonPollock7SiteComponent implements OnChanges, OnDestroy
{
	@Input() bodyFatCalculationTypeList: { [key: number]: string }
	@Input() bodyFatMeasurement: ClientBodyFatMeasurementViewModel;
	@Input() clientGender: GenderType;

	@Output() onUpdated = new EventEmitter<ClientBodyFatMeasurementUpdateRequestModel>();
	@Output() onCancel = new EventEmitter();

	@ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

	public selectedBodyFatCalculationType: BodyFatCalculationType = BodyFatCalculationType.Manual;
	public bodyFatCalculationTypes: typeof BodyFatCalculationType = BodyFatCalculationType;

	public showAlert: boolean = false;

	public bodyWeightMeasurement: ClientBodyFatMeasurementResponseModel = new ClientBodyFatMeasurementResponseModel();
	public bodyFatMeasurementForm: FormGroup;

	public genderTypes: typeof GenderType = GenderType;

	private _unsubscribeAll: Subject<any>;

	public constructor
		(
			private readonly _formBuilder: FormBuilder,
		)
	{
		this._unsubscribeAll = new Subject<any>();
	}

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public edit()
	{
		let bodyFatMeasurement = new ClientBodyFatMeasurementUpdateRequestModel();

		bodyFatMeasurement =
		{
			...this.bodyFatMeasurement,
			age: 0,
			gender: this.clientGender,
			bodyFatSiteMeasurements: []
		};

		bodyFatMeasurement.bodyFatCalculationTypeId = BodyFatCalculationType.JacksonPollock7Site;
		bodyFatMeasurement.measurementDate = this.bodyFatMeasurementForm.get("measurementDate").value;

		let chest = new ClientBodyFatSiteMeasurementRequestModel();
		chest.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Chest;
		chest.value = this.bodyFatMeasurementForm.get("chest").value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(chest);

		let abdomen = new ClientBodyFatSiteMeasurementRequestModel();
		abdomen.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Abdomen;
		abdomen.value = this.bodyFatMeasurementForm.get("abdomen").value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(abdomen);

		let thigh = new ClientBodyFatSiteMeasurementRequestModel();
		thigh.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Thigh;
		thigh.value = this.bodyFatMeasurementForm.get("thigh").value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(thigh);

		let tricep = new ClientBodyFatSiteMeasurementRequestModel();
		tricep.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Triceps;
		tricep.value = this.bodyFatMeasurementForm.get("tricep").value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(tricep);

		let axilla = new ClientBodyFatSiteMeasurementRequestModel();
		axilla.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Axilla;
		axilla.value = this.bodyFatMeasurementForm.get("axilla").value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(axilla);

		let subscapula = new ClientBodyFatSiteMeasurementRequestModel();
		subscapula.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Subscapula;
		subscapula.value = this.bodyFatMeasurementForm.get("subscapula").value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(subscapula);

		let suprailiac = new ClientBodyFatSiteMeasurementRequestModel();
		suprailiac.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Suprailiac;
		suprailiac.value = this.bodyFatMeasurementForm.get("suprailiac").value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(suprailiac);

		this.resetForm();
		this.onUpdated.emit(bodyFatMeasurement);
	}

	public cancel()
	{
		this.resetForm();
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	private buildForm()
	{
		let chest = this.bodyFatMeasurement.bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId == ClientBodyFatSiteMeasurementType.Chest)[0].value;
		let abdomen = this.bodyFatMeasurement.bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId == ClientBodyFatSiteMeasurementType.Abdomen)[0].value;
		let thigh = this.bodyFatMeasurement.bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId == ClientBodyFatSiteMeasurementType.Thigh)[0].value;
		let tricep = this.bodyFatMeasurement.bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId == ClientBodyFatSiteMeasurementType.Triceps)[0].value;
		let axilla = this.bodyFatMeasurement.bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId == ClientBodyFatSiteMeasurementType.Axilla)[0].value;
		let subscapula = this.bodyFatMeasurement.bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId == ClientBodyFatSiteMeasurementType.Subscapula)[0].value;
		let suprailiac = this.bodyFatMeasurement.bodyFatSiteMeasurements.filter(siteMeasuremnt => siteMeasuremnt.bodyFatSiteMeasurementTypeId == ClientBodyFatSiteMeasurementType.Suprailiac)[0].value;

		this.bodyFatMeasurementForm = this._formBuilder.group
			(
				{
					chest: [chest, [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					abdomen: [abdomen, [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					thigh: [thigh, [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					tricep: [tricep, [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					axilla: [axilla, [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					subscapula: [subscapula, [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					suprailiac: [suprailiac, [Validators.required, Validators.pattern(/^[\d]{1,3}([\.|\,]\d{0,2})?$/)]],
					measurementDate: [new Date()]
				}
			);
	}

	private resetForm()
	{
		setTimeout
			(
				() =>
				{
					this.buildForm();
					this.formGroupDirective.resetForm();
				}
			);
	}
}
