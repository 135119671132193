import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { profilePrivateRoutes } from './profile/private/private.routes';
import { profilePublicRoutes } from './profile/public/public.routes';
import { memberSearchRoutes } from './search/search.routes';

export const communityRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'profile'
	},
	{
		path: 'search',
		canActivate: [AuthGuard],
		children:
		[
			...memberSearchRoutes
		],
	},
	{
		path: 'profile/public/:handle',
		canActivate: [AuthGuard],
		children:
		[
			...profilePublicRoutes
		],
	},
	{
		path: 'profile',
		canActivate: [AuthGuard],
		children:
		[
			...profilePrivateRoutes
		],
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
