
import { Component, OnChanges, OnDestroy, ViewEncapsulation, inject, input, output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ClientBodyWeightResponseModel } from '@fitness-central/api/client/body-composition/body-weight/body-weight-response.model';
import { ClientBodyWeightService } from '@fitness-central/api/client/body-composition/body-weight/body-weight.service';
import { FuseAlertType } from '@abp/components/alert';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NumberValidators } from '@abp/validators/number-validators';

@Component
(
	{
		selector: 'client-detail-body-composition-body-weight-sidebar-edit',
		templateUrl: './edit.component.html',
		encapsulation: ViewEncapsulation.None,
		imports: [
			MatButtonModule,
			MatIconModule,
			FormsModule,
			ReactiveFormsModule,
			MatFormFieldModule,
			MatInputModule,
			MatDatepickerModule,
			MatTooltipModule,
			MatProgressSpinnerModule
		]
	}
)

export class ClientDetailBodyCompositionBodyWeightSidebarEditComponent implements OnChanges, OnDestroy
{
	private _formBuilder = inject(FormBuilder);
	private _bodyWeightMeasurementService = inject(ClientBodyWeightService);

	readonly bodyWeightMeasurement = input<ClientBodyWeightResponseModel>(undefined);

	readonly onUpdated = output<ClientBodyWeightResponseModel>();
	readonly onCancel = output();

	public alertBodyWeight:
		{
			type: FuseAlertType,
			message: string
		} =
			{
				type: 'success',
				message: ''
			};

	public editForm: FormGroup;

	public showAlert: boolean = false;

	private _unsubscribeAll: Subject<void>  = new Subject<void>();

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public buildForm()
	{
		this.editForm = this._formBuilder.group
		(
			{
				bodyWeightMeasurementId: [this.bodyWeightMeasurement().bodyWeightMeasurementId, [Validators.required]],
				value: [this.bodyWeightMeasurement().value, [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
				measurementDate: [this.bodyWeightMeasurement().measurementDate, [Validators.required]]
			}
		);
	}

	public update()
	{
		if (this.editForm.valid)
		{
			this.editForm.disable();

			this._bodyWeightMeasurementService
				.update(this.editForm.value)
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe
				(
					{
						next: (bodyWeightMeasurement) =>
						{
							this.onUpdated.emit(bodyWeightMeasurement);
						},
						error: (error) =>
						{
							// Set the alert
							this.alertBodyWeight =
							{
								type: 'error',
								message: 'There was a problem. Please try again later.'
							};

							// Show the alert
							this.showAlert = true;
							console.error(error);
						}
					}
				);
		}
	}

	public cancel()
	{
		this.buildForm();
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
