import { Location } from '@angular/common';
import { Component, inject, input, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';

import { DragDropCardContainerComponent } from '@abp/components/drag-drop-card-container/drag-drop-card-container.component';
import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { Guid } from '@abp/guid';
import { EnumDescriptionPipe } from '@abp/pipes/enum/enum-description.pipe';

import { ClientRoutineResistanceExerciseSetService } from '@fitness-central/api/client/training/routine-resistance-exercise-set/routine-resistance-exercise-set.service';
import { ClientRoutineResistanceExerciseService } from '@fitness-central/api/client/training/routine-resistance-exercise/routine-resistance-exercise.service';
import { ClientRoutineService } from '@fitness-central/api/client/training/routine/routine.service';
import { MemberRoutineResistanceExerciseSetService } from '@fitness-central/api/member/training/routine-resistance-exercise-set/routine-resistance-exercise-set.service';
import { MemberRoutineResistanceExerciseRequestModel } from '@fitness-central/api/member/training/routine-resistance-exercise/routine-resistance-exercise.request-model';
import { MemberRoutineResistanceExerciseService } from '@fitness-central/api/member/training/routine-resistance-exercise/routine-resistance-exercise.service';
import { MemberRoutineService } from '@fitness-central/api/member/training/routine/routine.service';
import { ResistanceExerciseResponse } from '@fitness-central/api/training/resistance-exercise/resistance-exercise.response-model';
import { ResistanceExerciseService } from '@fitness-central/api/training/resistance-exercise/resistance-exercise.service';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { TrainingRoutineResistanceExerciseCardComponent } from '@fitness-central/shared/components/training/routine-templates/cards/exercise/card.component';
import { TrainingRoutineResistanceExerciseCreateSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/exercise/create/create.component';
import { TrainingRoutineResistanceExerciseEditSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/exercise/edit/edit.component';
import { TrainingRoutineEditSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/routine/edit/edit.component';
import { PageHeaderComponent } from '@fitness-central/shared/components/ui/page-header/page-header.component';
import { StatusType, StatusTypeDescriptions } from '@fitness-central/shared/enum/status-type.enum';

import { concatMap, from, map, mergeMap, Subject, takeUntil } from 'rxjs';

import { RoutineTemplateResistanceExerciseViewModel } from '../../_view-models/routine-exercise.view-model';
import { RoutineTemplateViewModel } from '../../_view-models/routine.view-model';
import { MemberTrainingRoutineDetailNavigationComponent } from './_components/navigation/navigation.component';
import { RoutineTemplateEditSidebarActionType } from './_enums/edit-sidebar-action-type.enum';

@Component({
	selector: 'training-routine-detail-component',
	templateUrl: './detail.component.html',
	imports: [
		DragDropCardContainerComponent,
		EnumDescriptionPipe,
		MatIconModule,
		MatButtonModule,
		MatProgressBarModule,
		MatTooltipModule,
		MemberTrainingRoutineDetailNavigationComponent,
		PageHeaderComponent,
		SidebarViewComponent,
		TrainingRoutineEditSidebarComponent,
		TrainingRoutineResistanceExerciseCardComponent,
		TrainingRoutineResistanceExerciseCreateSidebarComponent,
		TrainingRoutineResistanceExerciseEditSidebarComponent,
	]
})

export class TrainingMemberRoutineDetailComponent implements OnInit, OnDestroy
{
	private readonly _clientRoutineResistanceExerciseService = inject(ClientRoutineResistanceExerciseService);
	private readonly _clientRoutineResistanceExerciseSetService = inject(ClientRoutineResistanceExerciseSetService);
	private readonly _clientRoutineService = inject(ClientRoutineService);
	private readonly _exerciseService = inject(ResistanceExerciseService);
	private readonly _sessionStorageHelper = inject(SessionStorageHelper);
	private readonly _location = inject(Location);
	private readonly _memberRoutineResistanceExerciseService = inject(MemberRoutineResistanceExerciseService);
	private readonly _memberRoutineResistanceExerciseSetService = inject(MemberRoutineResistanceExerciseSetService);
	private readonly _memberRoutineService = inject(MemberRoutineService);
	private readonly _router = inject(Router);

	public routineId = input<string>();
	public isMemberRoutine = input<boolean>();

	private sidebarView = viewChild(SidebarViewComponent);
	public routine = signal<RoutineTemplateViewModel>(new RoutineTemplateViewModel());

	public allExercises = signal<RoutineTemplateResistanceExerciseViewModel[]>([]);
	public filteredExercises = signal<RoutineTemplateResistanceExerciseViewModel[]>([]);

	public activeExercises: RoutineTemplateResistanceExerciseViewModel[] = [];
	public inactiveExercises: RoutineTemplateResistanceExerciseViewModel[] = [];

	public selectedExercise: RoutineTemplateResistanceExerciseViewModel = new RoutineTemplateResistanceExerciseViewModel();

	public exerciseFilterType = signal<StatusType>(StatusType.Active);
	public statusTypeDescriptions = StatusTypeDescriptions;

	public editSidebarAction: RoutineTemplateEditSidebarActionType;
	public editSidebarActionType = RoutineTemplateEditSidebarActionType;

	public exercises: ResistanceExerciseResponse[] = [];

	public measurementSystem: number;

	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<void>  = new Subject<void>();

	public ngOnInit(): void
	{
		this.measurementSystem = this._sessionStorageHelper.measurementSystem();

		this.getExercises();

		if (this.isMemberRoutine())
		{
			this.getMemberRoutine();
		}
		else
		{
			this.getClientRoutine();
		}
	}

	public showCreateSidebar(): void
	{
		this.sidebarView().openCreateSidebar();
	}

	public showRoutineEditSidebar(): void
	{
		this.editSidebarAction = this.editSidebarActionType.EditRoutine;

		this.sidebarView().openEditSidebar();
	}

	public showExerciseEditSidebar(routineResistanceExercise: RoutineTemplateResistanceExerciseViewModel): void
	{
		this.selectedExercise = routineResistanceExercise;
		this.editSidebarAction = this.editSidebarActionType.EditResistanceExercise;

		this.sidebarView().openEditSidebar();
	}

	public closeSidebar(): void
	{
		this.sidebarView().closeSidebar();
	}

	public closeNavigation(): void
	{
		this.sidebarView().closeNavigation();
	}

	public toggleNavigation(): void
	{
		this.sidebarView().toggleNavigation();
	}

	public updateCardOrder(): void
	{
		this.updateRoutineResistanceExercises();
	}

	public startWorkout()
	{
		this.closeNavigation();

		if (this.isMemberRoutine)
		{
			this._router.navigate([`./member/training/workout/routine/${ this.routine().routineId }`]);
		}
		else
		{
			this._router.navigate([`/client/${ this.routine().clientId }/training/workout/${ this.routine().routineId }`]);
		}
	}

	public showActiveExercises(): void
	{
		this.closeNavigation();

		this.exerciseFilterType.set(StatusType.Active);
		this.filterExercises();
	}

	public showInactiveExercises(): void
	{
		this.closeNavigation();

		this.exerciseFilterType.set(StatusType.Inactive)
		this.filterExercises();
	}

	public changeActive(): void
	{
		this.filterExercises();

		this.activeExercises.forEach((routine, index) =>
		{
			routine.order = index + 1;
		});

		this.inactiveExercises.forEach((routine, index) =>
		{
			routine.order = index + 1;
		});

		this.updateRoutineResistanceExercises();
	}

	public selectExercise(exercise: RoutineTemplateResistanceExerciseViewModel): void
	{
		if (this.isMemberRoutine())
		{
			this._router.navigate([`/member/training/routine-templates/exercise/${ exercise.routineResistanceExerciseId }/detail`])
		}
		else
		{
			this._router.navigate([`/member/training/routine-templates/trainer-exercise/${ exercise.routineResistanceExerciseId }/detail`])
		}
	}

	public routeToBack(): void
	{
		this._location.back();
	}

	public getExercises()
	{
		this._exerciseService
			.getAll()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (exercises) =>
					{
						this.exercises = exercises;
					}
				}
			)
	}

	public createExercise(resistanceExercise: ResistanceExerciseResponse): void
	{
		const routineResistanceExercise = new MemberRoutineResistanceExerciseRequestModel();
		routineResistanceExercise.routineId = this.routineId();
		routineResistanceExercise.resistanceExerciseId = resistanceExercise.resistanceExerciseId;
		routineResistanceExercise.order = this.activeExercises.length + 1;

		this._memberRoutineResistanceExerciseService
			.create(routineResistanceExercise)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (routineResistanceExercise) =>
					{
						this.isLoading.set(false);

						this.closeSidebar();
						this._router.navigateByUrl(`/member/training/routine-templates/exercise/${ routineResistanceExercise.routineResistanceExerciseId }/detail`);
					}
				}
			);
	}

	public updateRoutine(routine: RoutineTemplateViewModel): void
	{
		this._memberRoutineService
			.update(routine)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (routine) =>
					{
						this.routine.set
						({
							...routine,
							isMemberRoutine: true,
							clientId: Guid.empty
						});

						this.closeSidebar();
					}
				}
			);
	}

	public updateExercise(resistanceExercise: ResistanceExerciseResponse): void
	{
		const routineResistanceExercise: MemberRoutineResistanceExerciseRequestModel = this.selectedExercise;
		routineResistanceExercise.resistanceExerciseId = resistanceExercise.resistanceExerciseId;

		this._memberRoutineResistanceExerciseService
			.update(routineResistanceExercise)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (routineResistanceExercise) =>
					{
						this.allExercises.update
						(
							exercises => exercises.map((item) =>
							{
								const updatedItem = item.routineResistanceExerciseId === routineResistanceExercise.routineResistanceExerciseId
									? { ...routineResistanceExercise, routineTemplateResistanceExerciseSets: item.routineTemplateResistanceExerciseSets }
									: item;

								return updatedItem;
							})
						);

						this.filterExercises();

						this.isLoading.set(false);

						this.closeSidebar();
					}
				}
			);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private filterExercises(): void
	{
		this.activeExercises = this.allExercises()
			.filter(exercise => exercise.isActive)
			.sort((a, b) => a.order - b.order);

		this.inactiveExercises = this.allExercises()
			.filter(exercise => !exercise.isActive)
			.sort((a, b) => a.order - b.order);

		if (this.exerciseFilterType() === StatusType.Active)
		{
			this.filteredExercises.set(this.activeExercises);
		}
		else
		{
			this.filteredExercises.set(this.inactiveExercises);
		}
	}

	private getMemberRoutine(): void
	{
		this._memberRoutineService
			.get(this.routineId())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (routine) =>
					{
						this.routine.set
						({
							...routine,
							clientId: Guid.empty,
							isMemberRoutine: true
						});

						this.getMemberRoutineResistanceExercisesWithSets();
					}
				}
			);
	}

	private getMemberRoutineResistanceExercisesWithSets(): void
	{
		this.allExercises.set([]);

		this._memberRoutineResistanceExerciseService
			.getByRoutineId(this.routineId())
			.pipe
			(
				takeUntil(this._unsubscribeAll),
				concatMap(routineResistanceExercises => from(routineResistanceExercises)),
				mergeMap
				(
					routineResistanceExercise => this._memberRoutineResistanceExerciseSetService.getByRoutineResistanceExerciseId(routineResistanceExercise.routineResistanceExerciseId)
						.pipe
						(
							map((routineResistanceExerciseSets) =>
							{
								const filteredRoutineResistanceExerciseSets = routineResistanceExerciseSets
									.filter(routineResistanceExerciseSet => routineResistanceExerciseSet.isActive)
									.sort((a, b) => a.order - b.order);

								const routineWithExercisetSet: RoutineTemplateResistanceExerciseViewModel =
									{
										...routineResistanceExercise,
										routineTemplateResistanceExerciseSets: filteredRoutineResistanceExerciseSets
									};

								return routineWithExercisetSet;
							})
						)
				)
			)
			.subscribe
			(
				{
					next: (routineResistanceExerciseWithSets) =>
					{
						this.allExercises.update(exercises => [...exercises, routineResistanceExerciseWithSets])
					},
					complete: () =>
					{
						this.filterExercises();
						this.isLoading.set(false);
					}
				}
			);
	}

	private getClientRoutine(): void
	{
		this._clientRoutineService
			.get(this.routineId())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (routine) =>
					{
						this.routine.set
						({
							...routine,
							memberId: Guid.empty,
							isMemberRoutine: false
						});

						this.getClientRoutineResistanceExercisesWithSets();
					}
				}
			);
	}


	private getClientRoutineResistanceExercisesWithSets(): void
	{
		this.allExercises.set([]);

		this._clientRoutineResistanceExerciseService
			.getByRoutineId(this.routineId())
			.pipe
			(
				takeUntil(this._unsubscribeAll),
				concatMap(routineResistanceExercises => from(routineResistanceExercises)),
				mergeMap
				(
					routineResistanceExercise => this._clientRoutineResistanceExerciseSetService.getByRoutineResistanceExerciseId(routineResistanceExercise.routineResistanceExerciseId)
						.pipe
						(
							map((routineResistanceExerciseSets) =>
							{
								const filteredRoutineResistanceExerciseSets = routineResistanceExerciseSets
									.filter(routineResistanceExerciseSet => routineResistanceExerciseSet.isActive)
									.sort((a, b) => a.order - b.order);

								let routineWithExercisetSet = new RoutineTemplateResistanceExerciseViewModel();

								routineWithExercisetSet =
									{
										...routineResistanceExercise,
										routineTemplateResistanceExerciseSets: filteredRoutineResistanceExerciseSets
									};

								return routineWithExercisetSet;
							})
						)
				)
			)
			.subscribe
			(
				{
					next: (routineResistanceExerciseWithSets) =>
					{
						this.allExercises.update(exercises => [...exercises, routineResistanceExerciseWithSets])
					},
					complete: () =>
					{
						this.filterExercises();
						this.isLoading.set(false);
					}
				}
			);
	}

	private updateRoutineResistanceExercises(): void
	{
		this._memberRoutineResistanceExerciseService
			.updateRange(this.allExercises())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (routineResistanceExercises) =>
					{
						this.allExercises.update
						(
							exercises => exercises.map
							(
								(exercise) =>
								{
									const updatedItem = routineResistanceExercises.find(updatedExercise => updatedExercise.routineResistanceExerciseId === exercise.routineResistanceExerciseId);

									return updatedItem ? { ...exercise, ...updatedItem } : exercise;
								}
							)
						);

						this.filterExercises();
					}
				}
			)
	}
}
