import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, signal, ViewChild, ViewEncapsulation, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';

import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';

import { FuseFullscreenComponent } from '@abp/components/fullscreen';
import { FuseLoadingBarComponent } from '@abp/components/loading-bar';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@abp/components/navigation';
import { FuseMediaWatcherService } from '@abp/services/media-watcher';

import { Subject, takeUntil } from 'rxjs';

import { Navigation } from './navigation/navigation.types';
import { NavigationService } from './navigation/navigation.service';

import { LayoutMessageSidebarComponent } from './sidebars/message-sidebar/message-sidebar.component';
import { LayoutUserSidebarComponent } from './sidebars/user-sidebar/user-sidebar.component';

@Component
(
	{
		selector: 'classy-layout',
		templateUrl: './classy.component.html',
		encapsulation: ViewEncapsulation.None,
		standalone: true,
		imports:
		[
			FuseLoadingBarComponent,
			FuseVerticalNavigationComponent,
			MatIconModule,
			MatButtonModule,
			MatSidenavModule,
			FuseFullscreenComponent,
			RouterOutlet,
			NgIf,
			DefaultProfileImageComponent,
			LayoutMessageSidebarComponent,
			LayoutUserSidebarComponent
		]
	}
)

export class ClassyLayoutComponent implements OnInit, OnDestroy
{
	public isScreenSmall: boolean = false;
	public navigation: Navigation = new Navigation();

	@ViewChild('sidebar', { static: true }) private sidebar: MatDrawer;

	public displayName: WritableSignal<string>;
	public handle: string;
	public userName: string;
	public profileImageUrl: WritableSignal<string>;

	public isNew: boolean;

	public sidebarAction: "Profile" | "Messages" = "Messages";
	public sidebarOpen: boolean = false;

	public unreadMessageCount: WritableSignal<number> = signal(0);

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
	(
		private readonly _navigationService: NavigationService,
		private readonly _jwtHelper: JwtHelper,
		private readonly _fuseMediaWatcherService: FuseMediaWatcherService,
		private readonly _fuseNavigationService: FuseNavigationService,
		private readonly _sessionStorageHelper: SessionStorageHelper
	)
	{
		this.isNew = this._jwtHelper.isNew();
	}

	public get currentYear(): number
	{
		return new Date().getFullYear();
	}

	public ngOnInit(): void
	{
		this.navigation.default = this._navigationService.get();

		// Subscribe to media changes
		this._fuseMediaWatcherService
			.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				({ matchingAliases }) =>
				{
					// Check if the screen is small
					this.isScreenSmall = !matchingAliases.includes('md');
				}
			);

		this.displayName = this._sessionStorageHelper.displayName;
		this.handle = this._jwtHelper.handle();
		this.userName = this._jwtHelper.userName();
		this.profileImageUrl = this._sessionStorageHelper.profileImageUrl;
	}

	public toggleMessageSidebar()
	{
		if (!this.sidebar.opened)
		{
			this.sidebar.open();
		}
		else if (this.sidebarAction == "Messages")
		{
			this.sidebar.close();
		}

		this.sidebarAction = "Messages";
	}

	public toggleProfileSidebar()
	{
		if (!this.sidebar.opened)
		{
			this.sidebar.open();
		}
		else if (this.sidebarAction == "Profile")
		{
			this.sidebar.close();
		}

		this.sidebarAction = "Profile";
	}

	public toggleNavigation(name: string): void
	{
		// Get the navigation
		const navigation = this._fuseNavigationService
			.getComponent<FuseVerticalNavigationComponent>(name);

		if (navigation)
		{
			// Toggle the opened status
			navigation.toggle();
		}
	}

	public closeSidebar()
	{
		this.sidebar.close();
	}

	public ngOnDestroy(): void
	{
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
