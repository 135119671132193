@if (isLoading()) {
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

<sidebar-view #sidebarView>
	<ng-container header> </ng-container>

	<ng-container navigation>
		<client-detail-training-routine-detail-navigation
			[routine]="routine()"
			[allExercises]="allExercises()"
			(onStartWorkout)="startWorkout()"
			(onAddExercise)="showCreateSidebar()"
			(onEditRoutine)="showRoutineEditSidebar()"
			(onShowActiveResistanceExercises)="showActiveExercises()"
			(onShowInactiveResistanceExercises)="showInactiveExercises()">
		</client-detail-training-routine-detail-navigation>
	</ng-container>

	<ng-container create-sidebar>
		<training-exercise-create-sidebar [routineId]="routineId()" (onCreate)="createExercise($event)" (onCancel)="closeSidebar()"> </training-exercise-create-sidebar>
	</ng-container>

	<ng-container edit-sidebar>
		@if (editSidebarAction === editSidebarActionType.EditRoutine) {
			<training-routine-edit-sidebar [routine]="routine()" (onUpdate)="updateRoutine($event)" (onCancel)="closeSidebar()"> </training-routine-edit-sidebar>
		}

		@if (editSidebarAction === editSidebarActionType.EditResistanceExercise) {
			<training-exercise-edit-sidebar [exercise]="selectedExercise()" (onUpdate)="updateExercise($event)" (onCancel)="closeSidebar()"> </training-exercise-edit-sidebar>
		}
	</ng-container>

	<ng-container content>
		@if (!isLoading()) {
			<div class="flex flex-row items-center w-full">
				<div class="w-full bg-default">
					<ui-content-header
						[title]="routine().name"
						[subtitle]="routine().version"
						(onToggleNavigation)="sidebarView.toggleNavigation()"
						(onRouteToBack)="routeToBack()">
					</ui-content-header>
				</div>
			</div>

			<div class="flex flex-row items-center w-full">
				<div class="flex flex-col text-xl leading-none items-start justify-start w-full">
					<span class="flex lg:hidden p-2 text-lg font-normal ">
						<button mat-icon-button class="bg-accent text-on-accent " (click)="routeToBack()">
							<mat-icon class="text-white">arrow_back</mat-icon>
						</button>
					</span>
				</div>
			</div>
			<div>
				<drag-drop-card-container
					#dragDropCards
					[cardTemplate]="exercise"
					[cards]="filteredExercises()"
					[dragHandleTop]="'28px'"
					[dragHandleLeft]="'16px'"
					(onCardReorder)="updateCardOrder()">
					<ng-template let-card #exercise>
						<training-routine-resistance-exercise-card
							[exercise]="card"
							(onActiveChanged)="changeActive()"
							(onEdit)="showExerciseEditSidebar($event)"
							(onSelect)="selectExercise($event)">
						</training-routine-resistance-exercise-card>
					</ng-template>
				</drag-drop-card-container>

				<div class="flex flex-col m-12 items-center">
					@if (!filteredExercises().length) {
						<div class="flex flex-col text-lg font-medium bg-default text-on-default items-center">
							No {{ exerciseFilterType() | enumDescription:statusTypeDescriptions }} Exercises
						</div>
					}
				</div>
			</div>
		}
	</ng-container>
</sidebar-view>
