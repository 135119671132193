import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { UserType } from '@fitness-central/shared/enum/user-type.enum';

export const EmployeeProfileGuard: CanActivateFn | CanActivateChildFn = () =>
{
	const router: Router = inject(Router);
	const jwtHelper: JwtHelper = inject(JwtHelper);

	const isEmployeeProfile: boolean = jwtHelper.userType() == UserType.Employee;

	if (isEmployeeProfile === false)
	{
		router.navigateByUrl('error/404');
	}

	return true;
}