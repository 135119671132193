import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@fitness-central/api/base.service';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ClientBodyFatSiteMeasurementResponseModel } from './body-fat-site-measurement-response.model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ClientBodyFatSiteMeasurementService extends BaseService
{
	public constructor
	(
		private http: HttpClient
	)
	{
		super();
	}

	public getByBodyFatMeasurement(bodyFatMeasurementId: string) : Observable<ClientBodyFatSiteMeasurementResponseModel[]>
	{
		const url = environment.baseUrl + '/client/body-fat-site-measurements/body-fat-measurement/' + bodyFatMeasurementId;

		return this.http.get<ClientBodyFatSiteMeasurementResponseModel[]>(url);
	}
}
