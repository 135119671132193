import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { EnvironmentProviders, Provider, importProvidersFrom, inject, provideEnvironmentInitializer } from '@angular/core';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { FuseMediaWatcherService } from '@abp/services/media-watcher';

import { FuseConfig } from '@abp/services/config';
import { FUSE_CONFIG } from '@abp/services/config/config.constants';
import { FuseConfirmationService } from '@abp/services/confirmation';
import { FuseLoadingService, fuseLoadingInterceptor, } from '@abp/services/loading';
import { FuseSplashScreenService } from '@abp/services/splash-screen';
import { FuseUtilsService } from '@abp/services/utils';

export type FuseProviderConfig =
{
	mockApi?:
	{
		delay?: number;
		services?: any[];
	};
	fuse?: FuseConfig;
};

export const provideFuse = (config: FuseProviderConfig): Array<Provider | EnvironmentProviders> =>
{
	// Base providers
	const providers: Array<Provider | EnvironmentProviders> =
	[
		{
			// Disable 'theme' sanity check
			provide: MATERIAL_SANITY_CHECKS,
			useValue:
			{
				doctype: true,
				theme: false,
				version: true,
			},
		},
		{
			// Use the 'fill' appearance on Angular Material form fields by default
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: { appearance: 'fill', },
		},
		{
			provide: FUSE_CONFIG,
			useValue: config?.fuse ?? {},
		},

		importProvidersFrom(MatDialogModule),
		provideEnvironmentInitializer(() => inject(FuseConfirmationService)),

		provideHttpClient(withInterceptors([fuseLoadingInterceptor])),
		provideEnvironmentInitializer(() => inject(FuseLoadingService)),

		provideEnvironmentInitializer(() => inject(FuseMediaWatcherService)),
		provideEnvironmentInitializer(() => inject(FuseSplashScreenService)),
		provideEnvironmentInitializer(() => inject(FuseUtilsService)),
	];

	// Return the providers
	return providers;
};
