import { DatePipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ClientBodyWeightResponseModel } from '@fitness-central/api/client/body-composition/body-weight/body-weight-response.model';
import { FuseCardComponent } from '@abp/components/card';

import { Subject, takeUntil } from 'rxjs';

import { ClientDetailBodyCompositionBodyWeightMessageService } from '../../body-weight.message-service';

@Component({
	selector: 'client-detail-body-composition-body-weight-card',
	templateUrl: './card.component.html',
	standalone: true,
	imports: [FuseCardComponent, MatIconModule, MatButtonModule, NgIf, MatTooltipModule, DatePipe]
})

export class ClientDetailBodyCompositionBodyWeightCardComponent implements OnInit
{
	@Input() bodyWeightMeasurement: ClientBodyWeightResponseModel;

	@Output() onBodyWeightEdit: EventEmitter<ClientBodyWeightResponseModel> = new EventEmitter<ClientBodyWeightResponseModel>();
	@Output() onBodyWeightDelete: EventEmitter<ClientBodyWeightResponseModel> = new EventEmitter<ClientBodyWeightResponseModel>();

	public isActionBarVisible: boolean = false;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
		(
			private readonly _bodyCompositionBodyWeightMessageService: ClientDetailBodyCompositionBodyWeightMessageService
		)
	{ }

	public ngOnInit()
	{
		this.subscribeToClientBodyCompositionBodyWeightDetailMessageService();
	}

	public subscribeToClientBodyCompositionBodyWeightDetailMessageService()
	{
		this._bodyCompositionBodyWeightMessageService
			.displayActionBarAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: bodyWeightMeasurementId =>
					{
						if (this.bodyWeightMeasurement.bodyWeightMeasurementId != bodyWeightMeasurementId)
						{
							this.isActionBarVisible = false;
						}
					}
				}
			)
	}

	public toggleActionBarVisibility()
	{
		this.isActionBarVisible = !this.isActionBarVisible;
		this._bodyCompositionBodyWeightMessageService.displayActionbar(this.bodyWeightMeasurement.bodyWeightMeasurementId);
	}

	public editBodyWeight()
	{
		this.onBodyWeightEdit.emit(this.bodyWeightMeasurement);
	}

	public deleteBodyWeight()
	{
		this.onBodyWeightDelete.emit(this.bodyWeightMeasurement);
	}
}
