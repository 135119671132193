import { FuseNavigationItem } from '@abp/components/navigation';

const memberCommunityNavigationItem: FuseNavigationItem =
{
	id: 'community',
	title: 'Community',
	type: 'basic',
	icon: 'home',
	link: '/community/profile'
};

export const memberCommunityNavigation: FuseNavigationItem[] =
[
	memberCommunityNavigationItem,
];
