import { Component, OnChanges, OnDestroy, ViewEncapsulation, inject, input, output, viewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClientBodyWeightResponseModel } from '@fitness-central/api/client/body-composition/body-weight/body-weight-response.model';
import { ClientBodyWeightService } from '@fitness-central/api/client/body-composition/body-weight/body-weight.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component
(
	{
		selector: 'client-detail-body-composition-body-weight-sidebar-delete',
		templateUrl: './delete.component.html',
		encapsulation: ViewEncapsulation.None,
		imports: [
			MatButtonModule,
			MatIconModule,
			FormsModule,
			ReactiveFormsModule,
			MatFormFieldModule,
			MatInputModule,
			MatDatepickerModule,
			MatTooltipModule,
			MatProgressSpinnerModule
		]
	}
)

export class ClientDetailBodyCompositionBodyWeightSidebarDeleteComponent implements OnChanges, OnDestroy
{
	private _formBuilder = inject(FormBuilder);
	private _bodyWeightMeasurementService = inject(ClientBodyWeightService);

	readonly bodyWeightMeasurement = input<ClientBodyWeightResponseModel>(undefined);

	readonly onDeleted = output();
	readonly onCancel = output();

	readonly formGroupDirective = viewChild(FormGroupDirective);

	public deleteForm : FormGroup;

	private _unsubscribeAll: Subject<void>  = new Subject<void>();
	
	public ngOnChanges(): void
	{
		this.buildForm();
	}

	private buildForm()
	{
		this.deleteForm = this._formBuilder.group
		(
			{
				bodyWeightMeasurementId: [this.bodyWeightMeasurement().bodyWeightMeasurementId],
				value: [{value: this.bodyWeightMeasurement().value, disabled: true}],
				measurementDate: [{value: this.bodyWeightMeasurement().measurementDate, disabled: true}]
			}
		);
	}

	public delete()
	{
		this.deleteForm.disable();

		this._bodyWeightMeasurementService
			.delete(this.bodyWeightMeasurement().bodyWeightMeasurementId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						setTimeout
						(
							() =>
							{
								this.buildForm();
								this.formGroupDirective().resetForm();
							}
						);

						this.onDeleted.emit();
					}
				}
			);
	}

	public cancel()
	{
		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective().resetForm();
			}
		);

		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
