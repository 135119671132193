import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { ClientTrainingHistoryDetailComponent } from './detail/detail.component';
import { ClientTrainingHistoryComponent } from './history.component';

export const clientTrainingHistoryRoutes: Route[] =
[
	{
		path: '',
		canActivate: [AuthGuard],
		component: ClientTrainingHistoryComponent,
	},
	{
		path: 'detail/:workoutId',
		canActivate: [AuthGuard],
		component: ClientTrainingHistoryDetailComponent,
	}
] as Routes;
