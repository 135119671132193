
import { Component, OnChanges, OnDestroy, ViewEncapsulation, inject, input, output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ClientContactResponseModel } from '@fitness-central/api/client/contact/contact-response.model';
import { ClientContactService } from '@fitness-central/api/client/contact/contact.service';
import { ContactType } from '@fitness-central/api/general/contact-type/contact-type.model';
import { ContactTypeService } from '@fitness-central/api/general/contact-type/contact-type.service';
import { FuseAlertType } from '@abp/components/alert';
import { FuseAlertComponent } from '@abp/components/alert';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'client-details-home-edit-contact-sidebar',
	templateUrl: './client-contact-edit.component.html',
	encapsulation: ViewEncapsulation.None,
	imports: [MatButtonModule, MatIconModule, FormsModule, ReactiveFormsModule, FuseAlertComponent, MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, MatTooltipModule]
})

export class ClientDetailHomeClientContactSidebarEditComponent implements OnChanges, OnDestroy
{
	private _formBuilder = inject(FormBuilder);
	private clientContactService = inject(ClientContactService);
	private contactTypeService = inject(ContactTypeService);

	readonly clientContact = input<ClientContactResponseModel>(undefined);
	readonly onUpdated = output<ClientContactResponseModel>();
	readonly onCancel = output();

	public alertClientContact:
		{
			type: FuseAlertType,
			message: string
		} =
			{
				type: 'success',
				message: ''
			};

	public showAlert: boolean = false;

	public clientContactForm: FormGroup;

	public contactTypes: ContactType[] = [];

	private _unsubscribeAll: Subject<void>  = new Subject<void>();

	public constructor()
	{
		this.getContactType();
	}

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	private buildForm()
	{
		this.clientContactForm = this._formBuilder.group
		(
			{
				contactId: [this.clientContact().contactId],
				contactTypeId: [this.clientContact().contactTypeId, [Validators.required, Validators.pattern('^((?!0).)*$')]],
				value: [this.clientContact().value, Validators.required],
				isOptOut: [this.clientContact().isOptOut, Validators.required]
			}
		);
	}

	private getContactType()
	{
		this.contactTypeService
			.get()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (result) =>
					{
						this.contactTypes = result;
					},
					error: (error) =>
					{
						console.error(error);
					}
				}
			);
	}

	public update()
	{
		if (this.clientContactForm.valid)
		{
			this.clientContactService
				.update(this.clientContactForm.value)
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe
				(
					{
						next: (clientContact) =>
						{
							this.onUpdated.emit(clientContact);
						},
						error: (error) =>
						{
							// Set the alert
							this.alertClientContact =
							{
								type: 'error',
								message: 'There was a problem. Please try again later.'
							};

							// Show the alert
							this.showAlert = true;
							console.error(error);
						}
					}
				);
		}
	}

	public cancel()
	{
		this.buildForm();
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
