import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const memberVitalsRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'blood-glucose'
	},
	{
		path: 'blood-glucose',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/vitals/blood-glucose/blood-glucose.component').then(c => c.MemberVitalsBloodGlucoseComponent),
	},
	{
		path: 'blood-oxygen',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/vitals/blood-oxygen/blood-oxygen.component').then(c => c.MemberVitalsBloodOxygenComponent),
	},
	{
		path: 'blood-pressure',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/vitals/blood-pressure/blood-pressure.component').then(c => c.MemberVitalsBloodPressureComponent),
	},
	{
		path: 'body-temperature',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/vitals/body-temperature/body-temperature.component').then(c => c.MemberVitalsBodyTemperatureComponent),
	},
	{
		path: 'breathing-rate',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/vitals/breathing-rate/breathing-rate.component').then(c => c.MemberVitalsBreathingRateComponent),
	},
	{
		path: 'heart-rate',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/vitals/heart-rate/heart-rate.component').then(c => c.MemberVitalsHeartRateComponent),
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
