import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field'; import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';

@Component
(
	{
		selector: 'profile-search',
		templateUrl: './search.component.html',
		imports: [
			FormsModule,
			ReactiveFormsModule,
			MatFormFieldModule,
			MatIconModule,
			MatInputModule,
			MatButtonModule
		]
	}
)

export class ProfileSearchComponent implements OnInit
{
	private _formBuilder = inject(FormBuilder);
	private _router = inject(Router);

	public memberSearchForm: FormGroup;

	public ngOnInit()
	{
		this.buildMemberSearchForm();
	}

	public searchMembers()
	{
		const searchText = this.memberSearchForm.get('searchText').value;
		this._router.navigateByUrl(`/community/search/member?searchText=${ searchText }`);
	}

	private buildMemberSearchForm()
	{
		this.memberSearchForm = this._formBuilder.group
		(
			{
				searchText: ['', [Validators.required]]
			}
		);
	}
}
