import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MessageViewModel } from 'app/communication/message-center/_view-models/message.view-model';

import { FuseCardComponent } from '@abp/components/card';

@Component
(
	{
		selector: 'message-list-card',
		templateUrl: './list-card.component.html',
		standalone: true,
		imports:
		[
			FuseCardComponent,
			NgIf,
			MatIconModule,
			MatTooltipModule,
			MatButtonModule
		]
	}
)

export class MessageListCardComponent implements OnInit
{
	@Input() message: MessageViewModel;

	@Output() onMessageSelected = new EventEmitter<MessageViewModel>();
	@Output() onMessageDeleted = new EventEmitter<MessageViewModel>();

	public isActionBarVisible: boolean = false;

	public constructor() { }

	public ngOnInit()
	{
	}

	public toggleActionBarVisibility()
	{
		this.isActionBarVisible = !this.isActionBarVisible;
	}

	public selectMessage()
	{
		this.onMessageSelected.emit(this.message);
	}

	public deleteMessage()
	{
		this.onMessageDeleted.emit(this.message);
	}
}
