import { Component, input, output } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';

@Component
(
	{
		selector: 'image-grid',
		templateUrl: './image-grid.component.html',
		imports:
		[
			MatGridListModule
		]
	}
)

export class ImageGridComponent
{
	public readonly imageUrls = input<string[]>([]);
	public readonly imageSelected = output<number>();

	public onImageSelected(index: number)
	{
		this.imageSelected.emit(index);
	}
}
