import { FuseNavigationItem } from '@abp/components/navigation';

const employeeOrganizationNavigationItem: FuseNavigationItem =
{
	id: 'organization',
	title: 'Organization',
	type: 'collapsable',
	icon: 'mat_solid:reduce_capacity',
	children:
	[
		{
			id: 'organization.locations',
			title: 'Locations',
			type: 'basic',
			icon: 'mat_solid:location_on',
			link: 'organization/locations'
		}
	]
};

export const employeeOrganizationNavigation: FuseNavigationItem[] =
[
	employeeOrganizationNavigationItem
];
