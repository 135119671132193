import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ENVIRONMENT_INITIALIZER, inject } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withComponentInputBinding, withInMemoryScrolling, withPreloading } from '@angular/router';

import { appRoutes } from 'app/app.routes';

import { ErrorInterceptor } from '@fitness-central/core/authentication/interceptor/error.interceptor';
import { JwtInterceptor } from '@fitness-central/core/authentication/interceptor/jwt.interceptor';
import { TokenRefreshService } from '@fitness-central/core/authentication/token-refresh.service';
import { IconsService } from '@fitness-central/core/icons/icons.service';
import { TranslocoHttpLoader } from '@fitness-central/core/transloco/transloco.http-loader';
import { provideFuse } from '@fuse';
import { provideTransloco, TranslocoService } from '@ngneat/transloco';

import { firstValueFrom } from 'rxjs';

export const appConfig: ApplicationConfig =
{
	providers:
	[
		provideAnimations(),
		provideHttpClient
		(
			withInterceptors([ErrorInterceptor, JwtInterceptor])
		),
		provideRouter
		(
			appRoutes,
			withPreloading(PreloadAllModules),
			withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
			withComponentInputBinding()
		),

		// Material Date Adapter
		{
			provide: DateAdapter,
			useClass: LuxonDateAdapter,
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue:
			{
				parse:
				{
					dateInput: 'MM/dd/yyyy',
				},
				display:
				{
					dateInput: 'MM/dd/yyyy',
					monthYearLabel: 'LLL yyyy',
					dateA11yLabel: 'MM/DD/YYYY',
					monthYearA11yLabel: 'LLLL yyyy',
				},
			},
		},

		// Transloco Config
		provideTransloco
		(
			{
				config:
				{
					availableLangs:
					[
						{
							id: 'en',
							label: 'English',
						},
						{
							id: 'tr',
							label: 'Turkish',
						},
					],
					defaultLang: 'en',
					fallbackLang: 'en',
					reRenderOnLangChange: true,
					prodMode: true,
				},
				loader: TranslocoHttpLoader,
			}
		),
		{
			// Preload the default language before the app starts to prevent empty/jumping content
			provide: APP_INITIALIZER,
			useFactory: () =>
			{
				const translocoService = inject(TranslocoService);
				const defaultLang = translocoService.getDefaultLang();
				translocoService.setActiveLang(defaultLang);

				return () => firstValueFrom(translocoService.load(defaultLang));
			},
			multi: true,
		},

		// Fuse
		{
			provide: ENVIRONMENT_INITIALIZER,
			useValue: () => inject(IconsService),
			multi: true,
		},
		provideFuse
		(
			{
				fuse:
				{
					layout: 'classy',
					scheme: 'light',
					screens:
					{
						sm: '600px',
						md: '960px',
						lg: '1280px',
						xl: '1440px',
					},
					theme: 'theme-default',
					themes:
					[
						{
							id: 'theme-default',
							name: 'Default',
						},
					],
				},
			}
		),
		TokenRefreshService
	],
};
