import { Component, effect, inject, OnDestroy, Signal, signal, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';

import { DragDropCardContainerComponent } from '@abp/components/drag-drop-card-container/drag-drop-card-container.component';
import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { Guid } from '@abp/guid';
import { EnumDescriptionPipe } from '@abp/pipes/enum/enum-description.pipe';
import { ClientDetailNavigationMenuItemType } from 'app/employee/clients/detail/_components/navigation/models/menu-item-types.enum';
import { ClientDetailMessageService } from 'app/employee/clients/detail/_message-services/detail.message-service';

import { ClientResponseModel } from '@fitness-central/api/client/client/client-response.model';
import { ClientRoutineResponseModel } from '@fitness-central/api/client/training/routine/routine.response-model';
import { ClientRoutineService } from '@fitness-central/api/client/training/routine/routine.service';
import { TrainingRoutineTemplateCardComponent } from '@fitness-central/shared/components/training/routine-templates/cards/routine/card.component';
import { TrainingRoutineCreateSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/routine/create/create.component';
import { TrainingRoutineEditSidebarComponent } from '@fitness-central/shared/components/training/routine-templates/sidebars/routine/edit/edit.component';
import { ContentHeaderComponent } from '@fitness-central/shared/components/ui/content-header/content-header.component';

import { Subject, takeUntil } from 'rxjs';

import { ClientRoutineFilterTypeDescriptions, ClientRoutineTemplateFilterType } from '../../_enums/routine-filter-type.enum';
import { RoutineTemplateViewModel } from '../../_view-models/routine.view-model';
import { ClientDetailTrainingRoutineNavigationComponent } from './_components/navigation/navigation.component';

@Component
(
	{
		selector: 'client-detail-training-routine-list',
		templateUrl: './list.component.html',
		imports:
		[
			ContentHeaderComponent,
			DragDropCardContainerComponent,
			EnumDescriptionPipe,
			MatIconModule,
			MatButtonModule,
			MatProgressBarModule,
			MatTooltipModule,
			ClientDetailTrainingRoutineNavigationComponent,
			SidebarViewComponent,
			TrainingRoutineCreateSidebarComponent,
			TrainingRoutineEditSidebarComponent,
			TrainingRoutineTemplateCardComponent,
		]
	}
)

export class ClientDetailTrainingRoutineListComponent implements OnDestroy
{
	private readonly _clientDetailMessageService = inject(ClientDetailMessageService);
	private _clientRoutineService = inject(ClientRoutineService);
	private _router = inject(Router);

	public client: Signal<ClientResponseModel>;

	private sidebarView = viewChild(SidebarViewComponent)

	public filteredRoutines = signal<RoutineTemplateViewModel[]>([]);

	public allRoutines = signal<RoutineTemplateViewModel[]>([]);
	public activeClientRoutines: RoutineTemplateViewModel[] = [];
	public inactiveClientRoutines: RoutineTemplateViewModel[] = [];

	public selectedRoutine = signal<RoutineTemplateViewModel>(new RoutineTemplateViewModel());

	public selectedRoutineFilterType = signal<ClientRoutineTemplateFilterType>(ClientRoutineTemplateFilterType.ActiveRoutines);

	public routineFilterTypes: typeof ClientRoutineTemplateFilterType = ClientRoutineTemplateFilterType;
	public routineFilterTypeDescriptions = ClientRoutineFilterTypeDescriptions;

	public activeMemberId: string = Guid.empty;

	public isLoading = signal<boolean>(true);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public constructor
	()
	{
		this._clientDetailMessageService.selectedNavigationItemType.set(ClientDetailNavigationMenuItemType.Training);
		this.client = this._clientDetailMessageService.client;

		effect(() => this.getRoutines(this.client().clientId));

		this.subscribeToNavigationChange();
	}

	public showCreateSidebar(): void
	{
		this.sidebarView().openCreateSidebar();
	}

	public showEditSidebar(routine: RoutineTemplateViewModel): void
	{
		this.selectedRoutine.set(routine);
		this.sidebarView().openEditSidebar();
	}

	public closeSidebar(): void
	{
		this.sidebarView().closeSidebar();
	}

	public closeNavigation(): void
	{
		this.sidebarView().closeNavigation();
	}

	public changeRoutineFilter(routineFilterType: ClientRoutineTemplateFilterType): void
	{
		this.closeNavigation();

		this.selectedRoutineFilterType.set(routineFilterType);
		this.filterRoutines();
	}

	public changeActive(): void
	{
		this.activeClientRoutines.forEach((routine, index) =>
		{
			routine.order = index + 1;
		});

		this.inactiveClientRoutines.forEach((routine, index) =>
		{
			routine.order = index + 1;
		});

		this.updateRoutines();
	}

	public selectRoutine(routine: RoutineTemplateViewModel): void
	{
		this._router.navigate([`client/${ this.client().clientId }/training/routine-templates/routine/${ routine.routineId }/detail`]);
	}

	public startWorkout(routine: RoutineTemplateViewModel): void
	{
		this._router.navigate([`client/${ this.client().clientId }/training/workout/${ routine.routineId }`]);
	}

	public createRoutine(routine: RoutineTemplateViewModel): void
	{
		routine.order = this.activeClientRoutines.length + 1;
		routine.clientId = this.client().clientId;

		this._clientRoutineService
			.create(routine)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (routine) =>
					{
						this.isLoading.set(false);

						this.closeSidebar();

						this._router.navigate([`client/${ this.client().clientId }/training/routine-templates/routine/${ routine.routineId }/detail`]);
					}
				}
			);
	}

	public updateRoutine(routine: RoutineTemplateViewModel): void
	{
		routine.clientId = this.client().clientId;

		this._clientRoutineService
			.update(routine)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (routine) =>
					{
						const memberRoutine: RoutineTemplateViewModel =
						{
							...routine,
						}

						this.allRoutines.update(routines => routines.map(item => item.routineId === memberRoutine.routineId ? { ...memberRoutine } : item));

						this.filterRoutines();

						this.closeSidebar();
					}
				}
			)
	}

	public updateRoutines(): void
	{
		this._clientRoutineService
			.updateRange(this.allRoutines())
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (clientRoutines) =>
					{
						this.allRoutines.set(clientRoutines);
						this.filterRoutines();
					}
				}
			)
	}

	private subscribeToNavigationChange()
	{
		this._clientDetailMessageService
			.navigationOpenAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: () =>
					{
						this.sidebarView().toggleNavigation();
					}
				}
			)
	}

	private getRoutines(clientId: string): void
	{
		this._clientRoutineService
			.getByClientId(clientId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (clientRoutines) =>
					{
						this.buildClientRoutineTemplateViewModels(clientRoutines);

						this.filterRoutines();
						this.isLoading.set(false);
					}
				}
			);
	}

	private buildClientRoutineTemplateViewModels(clientRoutines: ClientRoutineResponseModel[]): void
	{
		const routines: RoutineTemplateViewModel[] = [];

		clientRoutines.map
		(
			(clientRoutine) =>
			{
				const routine: RoutineTemplateViewModel =
					{
						...clientRoutine,
					}

				routines.push(routine);
			}
		);

		this.allRoutines.set(routines);
	}

	private filterRoutines(): void
	{
		this.activeClientRoutines = this.allRoutines()
			.filter(routine => routine.isActive)
			.sort((a, b) => a.order - b.order);

		this.inactiveClientRoutines = this.allRoutines()
			.filter(routine => !routine.isActive)
			.sort((a, b) => a.order - b.order);

		if (this.selectedRoutineFilterType() === this.routineFilterTypes.ActiveRoutines)
		{
			this.filteredRoutines.set(this.activeClientRoutines);
		}
		else
		{
			this.filteredRoutines.set(this.inactiveClientRoutines);
		}
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
