import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { MatMenu, MatMenuItem, MatMenuModule } from '@angular/material/menu';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';
import { SidebarViewComponent } from '@abp/components/sidebar-view/sidebar-view.component';
import { Guid } from '@abp/guid';
import { FuseCardComponent } from '@abp/components/card';

import { PostResponse } from '@fitness-central/api/community/post/post.response-model';
import { PostService } from '@fitness-central/api/community/post/post.service';
import { ProfileResponseModel } from '@fitness-central/api/community/profile/profile.response-model';
import { ProfileService } from '@fitness-central/api/community/profile/profile.service';
import { GenderType } from '@fitness-central/api/general/gender-type/gender-type.enum';
import { JwtHelper } from '@fitness-central/core/helper/jwt.helper';
import { UserType } from '@fitness-central/shared/enum/user-type.enum';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProfileFollowerComponent } from '../_components/follower/follower.component';
import { ProfileFollowingComponent } from '../_components/following/following.component';
import { ProfileImageGalleryComponent } from '../_components/image-gallery/image-gallery.component';
import { CommunityPostSidebarComponent } from '../_components/post-sidebar/post-sidebar.component';
import { PostSidebarViewModel } from '../_components/post-sidebar/post-sidebar.view-model';
import { CommunityPostComponent } from '../_components/post/post.component';
import { ProfileSearchComponent } from '../_components/search/search.component';
import { PostType } from '../_enums/profile-post-type.enum';

@Component
(
	{
		selector: 'profile-private',
		templateUrl: './private.component.html',
		styleUrls: ['./private.component.scss'],
		encapsulation: ViewEncapsulation.None,
		standalone: true,
		imports:
		[
			NgIf,
			MatProgressBarModule,
			SidebarViewComponent,
			CommunityPostSidebarComponent,
			ProfileSearchComponent,
			DefaultProfileImageComponent,
			MatButtonModule,
			MatMenuItem,
			MatMenu,
			FuseCardComponent,
			MatIconModule,
			MatIconModule,
			ProfileFollowerComponent,
			ProfileFollowingComponent,
			ProfileImageGalleryComponent,
			NgFor,
			CommunityPostComponent,
			DatePipe,
			MatMenuModule
		]
	}
)

export class ProfilePrivateComponent implements OnInit, OnDestroy
{
	@ViewChild('sidebarView', { static: true }) sidebarView: SidebarViewComponent;

	public profile: ProfileResponseModel = new ProfileResponseModel();

	public followerCount: number = 0;
	public followedCount: number = 0;

	public posts: PostResponse[] = [];
	public previousPosts: boolean = false;
	public postForm: FormGroup;
	public postType: typeof PostType = PostType;
	public postSidebarViewModel: PostSidebarViewModel;

	public gender: typeof GenderType = GenderType;
	public userType: typeof UserType = UserType;

	public isLoading: boolean = true;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public constructor
	(
		private readonly _jwtHelper: JwtHelper,
		private readonly _profileService: ProfileService,
		private readonly _postService: PostService,
		private readonly _router: Router,
		public postDialog: MatDialog
	)
	{
	}

	public ngOnInit(): void
	{
		this.getProfile();
	}

	public editProfileSettings()
	{
		let userType = this._jwtHelper.userType();

		switch (userType)
		{
			case UserType.Member:
				this._router.navigateByUrl(`member/settings/account`);
				break;

			case UserType.Employee:
				this._router.navigateByUrl(`employee/settings/account`);
				break;
		}
	}

	public createPost(postType: PostType)
	{
		this.postSidebarViewModel = new PostSidebarViewModel();
		this.postSidebarViewModel.profileId = this.profile.profileId;
		this.postSidebarViewModel.ownerProfileId = this.profile.profileId;
		this.postSidebarViewModel.ownerDisplayName = this.profile.displayName;
		this.postSidebarViewModel.ownerHandle = this.profile.handle;
		this.postSidebarViewModel.ownerProfileImageUrl = this.profile.profileImageUrl;
		this.postSidebarViewModel.postType = postType;

		this.sidebarView.openCreateSidebar();
	}

	public closeSidebar()
	{
		this.sidebarView.closeSidebar();
	}

	public showPreviousPosts()
	{
		let lastPostId = this.posts.slice(-1)[0].postId;
		this.getPosts(lastPostId);
	}

	public routeToFollowers()
	{
		this._router.navigateByUrl(`/community/profile/followers`);
	}

	public routeToFollowing()
	{
		this._router.navigateByUrl(`/community/profile/following`);
	}

	public onPostCreated(post: PostResponse)
	{
		this.posts.unshift(post);
		this.closeSidebar();
	}

	public onPostRemoved(removedPost: PostResponse)
	{
		let postIndex = this.posts.findIndex(post => post.postId == removedPost.postId);
		this.posts.splice(postIndex, 1);
	}

	private getProfile()
	{
		this._profileService
			.get()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: profile =>
					{
						this.profile = { ...profile };

						this.getPosts(Guid.empty);
						this.getFollowerCount();
						this.getFollowedCount();

						this.isLoading = false;
					}
				}
			)
	}

	private getPosts(lastPostId: string)
	{
		this._postService
			.getAll(this.profile.profileId, lastPostId, 10)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: postList =>
					{
						if (postList.posts.length)
						{
							this.posts.push(...postList.posts);
							this.previousPosts = postList.previousPosts;
						}
					}
				}
			)
	}

	private getFollowerCount()
	{
		this._profileService
			.getFollowerCount(this.profile.profileId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: followerCount =>
					{
						this.followerCount = followerCount;
					}
				}
			)
	}

	private getFollowedCount()
	{
		this._profileService
			.getFollowedCount(this.profile.profileId)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: followedCount =>
					{
						this.followedCount = followedCount;
					}
				}
			)
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
