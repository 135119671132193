import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { employeeRoutineTemplateRoutes } from './routine-templates/routine-template.routes';

export const employeeTrainingRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'routine-templates'
	},
	{
		path: 'routine-templates',
		canActivate: [AuthGuard],
		children:
		[
			...employeeRoutineTemplateRoutes
		],
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
