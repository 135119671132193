import { FuseNavigationItem } from '@abp/components/navigation';

const employeeTrainingNavigationItem: FuseNavigationItem =
{
	id: 'training',
	title: 'Training',
	type: 'collapsable',
	icon: 'iconsmind:dumbbell',
	children:
	[
		{
			id: 'training.routines',
			title: 'Routine Templates',
			type: 'basic',
			icon: 'list_alt',
			link: 'employee/training/routine-templates/routine/list'
		},
		{
			id: 'training.locations',
			title: 'Locations',
			type: 'basic',
			icon: 'mat_solid:location_on',
			link: 'employee/locations'
		}
	]
};

export const employeeTrainingNavigation: FuseNavigationItem[] =
[
	employeeTrainingNavigationItem
];
