
import { Subject } from 'rxjs';

import { NumberValidators } from '@abp/validators/number-validators';
import { Component, inject, input, OnChanges, OnDestroy, OnInit, output, signal, viewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { SidebarComponent } from '@fitness-central/shared/components/ui/sidebar/sidebar.component';

import { ResistanceExerciseSetSidebarViewModel } from '../../view-models/reistance-exercise-set-sidebar.view-model';

@Component
(
	{
		selector: 'training-exercise-set-edit-sidebar',
		templateUrl: './edit.component.html',
		imports: [
			MatButtonModule,
			MatIconModule,
			FormsModule,
			ReactiveFormsModule,
			MatFormFieldModule,
			MatInputModule,
			MatTooltipModule,
			MatProgressSpinnerModule,
			MatProgressBarModule,
			SidebarComponent
		]
	}
)

export class TrainingRoutineResistanceExerciseSetEditSidebarComponent implements OnInit, OnChanges, OnDestroy
{
	private _formBuilder = inject(FormBuilder);
	private _sessionStorageHelper = inject(SessionStorageHelper);

	public exerciseSet = input<ResistanceExerciseSetSidebarViewModel>();

	readonly onUpdate = output<ResistanceExerciseSetSidebarViewModel>();
	readonly onCancel = output();

	public formGroupDirective = viewChild(FormGroupDirective);

	public editForm: FormGroup;

	public measurementSystem: number;

	public isLoading = signal<boolean>(false);

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit(): void
	{
		this.measurementSystem = +this._sessionStorageHelper.measurementSystem();
		this.buildForm();
	}

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public update()
	{
		this.editForm.disable();

		const resistanceExerciseSetSidebarViewModel: ResistanceExerciseSetSidebarViewModel = this.editForm.value;
		this.isLoading.set(false);

		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective().resetForm();
			}
		);

		this.onUpdate.emit(resistanceExerciseSetSidebarViewModel);
	}

	public cancel()
	{
		this.onCancel.emit();
	}

	private buildForm()
	{
		this.editForm = this._formBuilder.group
		(
			{
				routineResistanceExerciseSetId: this.exerciseSet().routineResistanceExerciseSetId,
				routineResistanceExerciseId: [this.exerciseSet().routineResistanceExerciseId],
				description: [this.exerciseSet().description, [Validators.maxLength(256)]],
				weight: [this.exerciseSet().weight, [Validators.required, NumberValidators.decimal(1, 4, 0, 2)]],
				weightMeasurement: [''],
				duration: [this.exerciseSet().duration],
				repRangeStart: [this.exerciseSet().repRangeStart, [Validators.required]],
				repRangeEnd: [this.exerciseSet().repRangeEnd, [Validators.required]],
				rest: [this.exerciseSet().rest, [Validators.required]],
				timeMeasurement: ['1'],
				order: [this.exerciseSet().order],
				concentricDuration: [this.exerciseSet().concentricDuration, [Validators.required]],
				concentricPause: [this.exerciseSet().concentricPause, [Validators.required]],
				eccentricDuration: [this.exerciseSet().eccentricDuration, [Validators.required]],
				eccentricPause: [this.exerciseSet().eccentricPause, [Validators.required]],
				isActive: [this.exerciseSet().isActive]
			}
		);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
