import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { TrainingMemberExerciseDetailComponent } from './exercise/detail/detail.component';
import { TrainingMemberRoutineDetailComponent } from './routine/detail/detail.component';
import { TrainingMemberRoutineListComponent } from './routine/list/list.component';

export const memberRoutineTemplateRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'routine/list'
	},
	{
		path: 'routine/list',
		canActivate: [AuthGuard],
		component: TrainingMemberRoutineListComponent,
	},
	{
		path: 'routine/:routineId/detail',
		canActivate: [AuthGuard],
		data:
		{
			isMemberRoutine: true
		},
		component: TrainingMemberRoutineDetailComponent,
	},
	{
		path: 'trainer-routine/:routineId/detail',
		canActivate: [AuthGuard],
		data:
		{
			isMemberRoutine: false
		},
		component: TrainingMemberRoutineDetailComponent,
	},
	{
		path: 'exercise/:resistanceExerciseId/detail',
		canActivate: [AuthGuard],
		data:
		{
			isMemberExercise: true
		},
		component: TrainingMemberExerciseDetailComponent,
	},
	{
		path: 'trainer-exercise/:resistanceExerciseId/detail',
		canActivate: [AuthGuard],
		data:
		{
			isMemberExercise: false
		},
		component: TrainingMemberExerciseDetailComponent,
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
