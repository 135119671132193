import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable
(
	{ providedIn: 'root' }
)

export class IconsService
{
	public constructor ()
	{
		const matIconRegistry = inject(MatIconRegistry);

		matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
	}
}
