import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';

export const JwtInterceptor: HttpInterceptorFn = (request, next) =>
{
	const sessionStorageHelper: SessionStorageHelper = inject(SessionStorageHelper);

	const baseHttpHeader = request.headers;
	const accessToken = sessionStorageHelper.accessToken();

	if (accessToken != '')
	{
		const authHttpHeader = baseHttpHeader.append('Authorization', `Bearer ${ accessToken }`);

		const updatedRequest = request.clone({ headers: authHttpHeader });

		return next(updatedRequest);
	}
	else
	{
		const updatedRequest = request.clone({ headers: baseHttpHeader });

		return next(updatedRequest);
	}
};
