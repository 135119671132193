import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const memberBodyCompositionBodyMeasurementRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'biceps'
	},
	{
		path: 'biceps',
		canActivate: [AuthGuard],
		loadComponent: () => import('./biceps/biceps.component').then(c => c.MemberBodyCompositionBodyMeasurementBicepsComponent),
	},
	{
		path: 'butt',
		canActivate: [AuthGuard],
		loadComponent: () => import('./butt/butt.component').then(c => c.MemberBodyCompositionBodyMeasurementButtComponent),
	},
	{
		path: 'calves',
		canActivate: [AuthGuard],
		loadComponent: () => import('./calves/calves.component').then(c => c.MemberBodyCompositionBodyMeasurementCalvesComponent),
	},
	{
		path: 'chest',
		canActivate: [AuthGuard],
		loadComponent: () => import('./chest/chest.component').then(c => c.MemberBodyCompositionBodyMeasurementChestComponent),
	},
	{
		path: 'forearms',
		canActivate: [AuthGuard],
		loadComponent: () => import('./forearms/forearms.component').then(c => c.MemberBodyCompositionBodyMeasurementForearmsComponent),
	},
	{
		path: 'hips',
		canActivate: [AuthGuard],
		loadComponent: () => import('./hips/hips.component').then(c => c.MemberBodyCompositionBodyMeasurementHipComponent),
	},
	{
		path: 'neck',
		canActivate: [AuthGuard],
		loadComponent: () => import('./neck/neck.component').then(c => c.MemberBodyCompositionBodyMeasurementNeckComponent),
	},
	{
		path: 'shoulders',
		canActivate: [AuthGuard],
		loadComponent: () => import('./shoulders/shoulders.component').then(c => c.MemberBodyCompositionBodyMeasurementShoulderComponent),
	},
	{
		path: 'thighs',
		canActivate: [AuthGuard],
		loadComponent: () => import('./thighs/thighs.component').then(c => c.MemberBodyCompositionBodyMeasurementThighsComponent),
	},
	{
		path: 'waist',
		canActivate: [AuthGuard],
		loadComponent: () => import('./waist/waist.component').then(c => c.MemberBodyCompositionBodyMeasurementWaistComponent),
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
