import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { clientBodyFatMeasurementRoutes } from './body-fat-measurement/body-fat-measurement.routes';
import { clientBodyMeasurementRoutes } from './body-measurement/body-measurement.routes';
import { clientBodyWeightMeasurementRoutes } from './body-weight/body-weight.routes';

export const clientBodyCompositionRoutes: Route[] =
[
	{
		path: '',
		canActivate: [AuthGuard],
		loadComponent: () => import('./overview/overview.component').then(c => c.ClientDetailBodyCompositionOverviewComponent),
	},
	{
		path: 'body-fat-measurement',
		canActivate: [AuthGuard],
		children:
		[
			...clientBodyFatMeasurementRoutes
		],
	},
	{
		path: 'body-measurement',
		canActivate: [AuthGuard],
		children:
		[
			...clientBodyMeasurementRoutes
		],
	},
	{
		path: 'body-weight',
		canActivate: [AuthGuard],
		children:
		[
			...clientBodyWeightMeasurementRoutes
		],
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
