
import { Component, EventEmitter, input, OnChanges, OnDestroy, OnInit, Output, signal, SimpleChanges, viewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { SessionStorageHelper } from '@fitness-central/core/helper/session-storage.helper';
import { SidebarComponent } from '@fitness-central/shared/components/ui/sidebar/sidebar.component';

import { Subject } from 'rxjs';

import { ResistanceExerciseSetSidebarViewModel } from '../../view-models/reistance-exercise-set-sidebar.view-model';

@Component
(
	{
		selector: 'training-exercise-set-edit-sidebar',
		templateUrl: './edit.component.html',
		standalone: true,
		imports: [
			MatButtonModule,
			MatIconModule,
			FormsModule,
			ReactiveFormsModule,
			MatFormFieldModule,
			MatInputModule,
			MatTooltipModule,
			MatProgressSpinnerModule,
			MatProgressBarModule,
			SidebarComponent
		],
	}
)

export class TrainingRoutineResistanceExerciseSetEditSidebarComponent implements OnInit, OnChanges, OnDestroy
{
	public exerciseSet = input<ResistanceExerciseSetSidebarViewModel>();

	@Output() onUpdate = new EventEmitter<ResistanceExerciseSetSidebarViewModel>();
	@Output() onCancel = new EventEmitter();

	public formGroupDirective = viewChild(FormGroupDirective);

	public editForm: FormGroup;

	public measurementSystem: number;

	public isLoading = signal<boolean>(false);

	private _unsubscribeAll: Subject<any>;

	public constructor
	(
		private _formBuilder: FormBuilder,
		private _sessionStorageHelper: SessionStorageHelper
	)
	{
		this._unsubscribeAll = new Subject<any>();
	}

	public ngOnInit(): void
	{
		this.measurementSystem = +this._sessionStorageHelper.measurementSystem();
		this.buildForm();
	}

	public ngOnChanges(changes: SimpleChanges): void
	{
		this.buildForm();
	}

	public update()
	{
		this.editForm.disable();

		const resistanceExerciseSetSidebarViewModel: ResistanceExerciseSetSidebarViewModel = this.editForm.value;
		this.isLoading.set(false);

		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective().resetForm();
			}
		);

		this.onUpdate.emit(resistanceExerciseSetSidebarViewModel);
	}

	public cancel()
	{
		this.onCancel.emit();
	}

	private buildForm()
	{
		this.editForm = this._formBuilder.group
		(
			{
				routineResistanceExerciseSetId: this.exerciseSet().routineResistanceExerciseSetId,
				routineResistanceExerciseId: [this.exerciseSet().routineResistanceExerciseId],
				description: [this.exerciseSet().description, [Validators.maxLength(256)]],
				weight: [this.exerciseSet().weight, [Validators.required, Validators.pattern(/^[\d]{1,4}([\.|\,]\d{0,2})?$/)]],
				weightMeasurement: [''],
				duration: [this.exerciseSet().duration],
				repRangeStart: [this.exerciseSet().repRangeStart, [Validators.required]],
				repRangeEnd: [this.exerciseSet().repRangeEnd, [Validators.required]],
				rest: [this.exerciseSet().rest, [Validators.required]],
				timeMeasurement: ['1'],
				order: [this.exerciseSet().order],
				concentricDuration: [this.exerciseSet().concentricDuration, [Validators.required]],
				concentricPause: [this.exerciseSet().concentricPause, [Validators.required]],
				eccentricDuration: [this.exerciseSet().eccentricDuration, [Validators.required]],
				eccentricPause: [this.exerciseSet().eccentricPause, [Validators.required]],
				isActive: [this.exerciseSet().isActive]
			}
		);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
