export class ProfileFollowerViewModel
{
	public profileId: string;
	public displayName: string;
	public handle: string;
	public sasUri: string;

	public constructor()
	{
		this.profileId = '00000000-0000-0000-0000-000000000000';
		this.displayName = '';
		this.handle = '';
		this.sasUri = '';
	}
}
