import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DefaultProfileImageComponent } from '@abp/components/default-profile-image/default-profile-image.component';
import { TimeAgoPipe } from '@abp/pipes/date-time/time-ago.pipe';
import { FuseMediaWatcherService } from '@abp/services/media-watcher';

import { AfterContentInit, Component, CUSTOM_ELEMENTS_SCHEMA, inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { PostImageResponse } from '@fitness-central/api/community/post-image/post-image.response-model';
import { PostImageService } from '@fitness-central/api/community/post-image/post-image.service';

@Component
(
	{
		selector: 'profile-image-dialog',
		templateUrl: './image-dialog.component.html',
		styleUrls: ['./image-dialog.component.scss'],
		encapsulation: ViewEncapsulation.None,
		schemas: [CUSTOM_ELEMENTS_SCHEMA],
		imports: [
			DefaultProfileImageComponent,
			MatButtonModule,
			MatIconModule,
			TimeAgoPipe
		]
	}
)

export class CommunityPostImageDialogComponent implements OnInit, OnDestroy, AfterContentInit
{
	profileImagesData = inject(MAT_DIALOG_DATA);
	private dialog = inject<MatDialogRef<CommunityPostImageDialogComponent>>(MatDialogRef);
	private _fuseMediaWatcherService = inject(FuseMediaWatcherService);
	private _postImageService = inject(PostImageService);

	public postImageIds: string[] = [];
	public postImages: PostImageResponse[];
	public selectedIndex: number = 0;
	public showNavigation: boolean = false;

	public displayName: string = '';
	public postDate: string = '';
	public ownerProfileImageUrl: string = '';

	private postImageTasks: Observable<PostImageResponse>[] = [];

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngAfterContentInit(): void
	{
		this.postImageIds = this.profileImagesData['postImageIds'];
		this.selectedIndex = this.profileImagesData['selectedIndex'];
		this.displayName = this.profileImagesData['displayName'];
		this.postDate = this.profileImagesData['postDate'];
		this.ownerProfileImageUrl = this.profileImagesData['ownerProfileImageUrl'];

		this.getPostImages();
	}

	public ngOnInit()
	{
		this.subscribeToMediaChanges();
	}

	public closeDialog()
	{
		this.dialog
			.close
			(
				{
					event: 'close'
				}
			);
	}

	private getPostImages()
	{
		const reducedImageSize = false;

		this.postImageIds
			.forEach
			(
				(postImageId) =>
				{
					this.postImageTasks
						.push
						(
							this._postImageService.getByPostImageId(postImageId, reducedImageSize)
						);
				}
			);

		forkJoin(this.postImageTasks)
			.subscribe
			(
				{
					next: (postImages: PostImageResponse[]) =>
					{
						this.postImages = postImages.sort((a, b) => 0 - (a.createdDate > b.createdDate ? -1 : 1));
					}
				}
			)
	}

	private subscribeToMediaChanges()
	{
		this._fuseMediaWatcherService.onMediaChange$
			.pipe
			(
				takeUntil
				(
					this._unsubscribeAll
				)
			)
			.subscribe
			(
				({ matchingAliases }) =>
				{
					if (matchingAliases.includes('sm'))
					{

						if (this.postImageIds.length > 1)
						{
							this.showNavigation = true;
						}

						this.dialog.updateSize('100vw', '100%');
					}
					else
					{
						this.showNavigation = false;

						this.dialog.updateSize('100vw', '100%');
					}
				}
			);
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}