<sidebar-view #sidebarView [showHeaderInContent]="true">
	<ng-container header>
		<ui-content-header [title]="'Body Weight Measurements'" [subtitle]="'Body Composition'" (onRouteToBack)="routeToBack()"> </ui-content-header>
	</ng-container>

	<ng-container navigation>
		<client-detail-body-composition-body-weight-navigation (onCreate)="create()"> </client-detail-body-composition-body-weight-navigation>
	</ng-container>

	<ng-container create-sidebar>
		<client-detail-body-composition-body-weight-sidebar-create [clientId]="client().clientId" (onCreated)="created($event)" (onCancel)="cancel()">
		</client-detail-body-composition-body-weight-sidebar-create>
	</ng-container>

	<ng-container edit-sidebar>
		<client-detail-body-composition-body-weight-sidebar-edit [bodyWeightMeasurement]="selectedBodyWeight" (onUpdated)="updated($event)" (onCancel)="cancel()">
		</client-detail-body-composition-body-weight-sidebar-edit>
	</ng-container>

	<ng-container delete-sidebar>
		<client-detail-body-composition-body-weight-sidebar-delete [bodyWeightMeasurement]="selectedBodyWeight" (onDeleted)="deleted($event)" (onCancel)="cancel()">
		</client-detail-body-composition-body-weight-sidebar-delete>
	</ng-container>

	<ng-container content>
		@if (!bodyWeightMeasurements.length) {
			<div class="flex flex-col m-12 items-center">
				<div class="flex flex-row">No Body Weight entries for Date Range selected</div>
			</div>
		}
		@if (bodyWeightMeasurements) {
			<div class="flex flex-col m-2">
				@for (bodyWeightMeasurement of bodyWeightMeasurements; track bodyWeightMeasurement) {
					<client-detail-body-composition-body-weight-card
						[bodyWeightMeasurement]="bodyWeightMeasurement"
						(onBodyWeightEdit)="edit($event)"
						(onBodyWeightDelete)="delete($event)"
						(onCancel)="cancel()">
					</client-detail-body-composition-body-weight-card>
				}
			</div>
		}
	</ng-container>
</sidebar-view>
