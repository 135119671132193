import { DatePipe } from '@angular/common';
import { Component, OnInit, inject, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ClientBodyWeightResponseModel } from '@fitness-central/api/client/body-composition/body-weight/body-weight-response.model';
import { FuseCardComponent } from '@abp/components/card';

import { Subject, takeUntil } from 'rxjs';

import { ClientDetailBodyCompositionBodyWeightMessageService } from '../../body-weight.message-service';

@Component({
	selector: 'client-detail-body-composition-body-weight-card',
	templateUrl: './card.component.html',
	imports: [FuseCardComponent, MatIconModule, MatButtonModule, MatTooltipModule, DatePipe]
})

export class ClientDetailBodyCompositionBodyWeightCardComponent implements OnInit
{
	private readonly _bodyCompositionBodyWeightMessageService = inject(ClientDetailBodyCompositionBodyWeightMessageService);

	readonly bodyWeightMeasurement = input<ClientBodyWeightResponseModel>(undefined);

	readonly onBodyWeightEdit = output<ClientBodyWeightResponseModel>();
	readonly onBodyWeightDelete = output<ClientBodyWeightResponseModel>();

	public isActionBarVisible: boolean = false;

	private _unsubscribeAll: Subject<void> = new Subject<void>();

	public ngOnInit()
	{
		this.subscribeToClientBodyCompositionBodyWeightDetailMessageService();
	}

	public subscribeToClientBodyCompositionBodyWeightDetailMessageService()
	{
		this._bodyCompositionBodyWeightMessageService
			.displayActionBarAction$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (bodyWeightMeasurementId) =>
					{
						if (this.bodyWeightMeasurement().bodyWeightMeasurementId != bodyWeightMeasurementId)
						{
							this.isActionBarVisible = false;
						}
					}
				}
			)
	}

	public toggleActionBarVisibility()
	{
		this.isActionBarVisible = !this.isActionBarVisible;
		this._bodyCompositionBodyWeightMessageService.displayActionbar(this.bodyWeightMeasurement().bodyWeightMeasurementId);
	}

	public editBodyWeight()
	{
		this.onBodyWeightEdit.emit(this.bodyWeightMeasurement());
	}

	public deleteBodyWeight()
	{
		this.onBodyWeightDelete.emit(this.bodyWeightMeasurement());
	}
}
