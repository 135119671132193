import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';
import { EmployeeProfileGuard } from '@fitness-central/core/authentication/guard/professional-profile-guard';

import { clientsDetailRoutes } from './detail/detail.routes';
import { clientTrainingHistoryRoutes } from './detail/tabs/training/history/history.routes';

export const clientRoutes: Route[] =
[
	{
		path: '',
		canActivate: [AuthGuard, EmployeeProfileGuard],
		loadComponent: () => import('./list/list.component').then(c => c.ClientListComponent),
	},
	{
		path: ':clientId/training/history',
		canActivate: [AuthGuard],
		children:
		[
			...clientTrainingHistoryRoutes
		],
	},
	{
		path: ':clientId/training/workout/:routineId',
		canActivate: [AuthGuard],
		loadComponent: () => import('./detail/tabs/training/workout/workout.component').then(c => c.ClientTrainingWorkoutComponent),
	},
	{
		path: ':clientId',
		canActivate: [AuthGuard, EmployeeProfileGuard],
		loadComponent: () => import('./detail/detail.component').then(c => c.ClientDetailComponent),
		children:
		[
			...clientsDetailRoutes
		],
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
