import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

import { memberTrainingHistoryRoutes } from './history/history.routes';
import { memberRoutineTemplateRoutes } from './routine-templates/routine-template.routes';

export const memberTrainingRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'history'
	},
	{
		path: 'history',
		canActivate: [AuthGuard],
		children:
		[
			...memberTrainingHistoryRoutes
		],
	},
	{
		path: 'routine-templates',
		canActivate: [AuthGuard],
		children:
		[
			...memberRoutineTemplateRoutes
		],
	},
	{
		path: 'workout/daily',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/training/daily-workout/daily-workout.component').then(c => c.MemberTrainingDailyWorkoutComponent),
	},
	{
		path: 'workout/:workoutId',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/training/workout/workout.component').then(c => c.MemberTrainingWorkoutComponent),
	},
	{
		path: 'workout/routine/:routineId',
		canActivate: [AuthGuard],
		loadComponent: () => import('app/member/training/workout/workout.component').then(c => c.MemberTrainingWorkoutComponent),
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
