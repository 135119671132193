import { Route, Routes } from '@angular/router';

import { AuthGuard } from '@fitness-central/core/authentication/guard/auth-guard';

export const authenticationRoutes: Route[] =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'sign-in'
	},
	{
		path: 'forgot-password',
		loadChildren: () => import('./forgot-password/forgot-password.routes')
	},
	{
		path : 'reset-password',
		loadComponent: () => import('./change-password/change-password.routes')
	},
	{
		path: 'sign-up',
		loadChildren: () => import('./sign-up/sign-up.routes')
	},
	{
		path: 'sign-in',
		loadChildren: () => import('./sign-in/sign-in.routes')
	},
	{
		path : 'sign-out',
		canActivate: [AuthGuard],
		loadChildren: () => import('./sign-out/sign-out.routes')
	},
	{
		path : 'verification',
		loadChildren: () => import('./verification/verification.routes')
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
