import { Routes } from '@angular/router';

export const errorRoutes =
[
	{
		path: '',
		pathMatch: 'full',
		redirectTo: '404'
	},
	{
		path: '404',
		loadComponent: () => import('./error-404/error-404.component').then(c => c.Error404Component)
	},
	{
		path: '500',
		loadComponent: () => import('./error-500/error-500.component').then(c => c.Error500Component)
	},
	{
		path: '**',
		redirectTo: 'error/404'
	}
] as Routes;
